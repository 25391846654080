import React from 'react'

import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import MemberPage from '../../Components/User/MemberPage';

const AdminUser = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={AdminNavItems} />
            <MemberPage user={ADMIN_USER_TYPE} />
            <Footer />
        </div>
    )
}

export default AdminUser;