import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import bkgImage from '../../Assets/Test.png'
import { STUDENT_USER_TYPE } from '../../services/UserService';
import { StudentNavItems } from './StudentNavItems';
import StudentTestPage from '../../Components/Test/StudentTest/StudentTestPage';
import StudentSelectTestPage from '../../Components/Test/StudentTest/StudentSelectTestPage';

const StudentTests = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={StudentNavItems} user={STUDENT_USER_TYPE} />
            <StudentSelectTestPage user={STUDENT_USER_TYPE} pathTab={'Common'} />
            <Footer user={STUDENT_USER_TYPE} />
        </div>
    )
}

export default StudentTests;