import React from "react";
import { default as ReactSelect } from "react-select";
import { useTranslation } from "react-i18next";

const SelectionDropdownMultiple = ({ className = 'pInput', name, itemList, selectedItems, onSelectionToggle, valueField, nameField, required = false, isSearchable = false, isDisabled = false }) => {

    const { t } = useTranslation();
    return (
        <>
            <div className={className}>
                <label htmlFor={name} className="title">{name}</label>
                {required ? (
                    <>
                        <ReactSelect
                            className="selectionDropdownMultiple"
                            name={name}
                            id={name}
                            placeholder={t('selectPlaceholder')}
                            options={itemList.map(item => ({ value: item[valueField], label: t(item[nameField]) }))}
                            isMulti
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            onChange={onSelectionToggle}
                            allowSelectAll={true}
                            isSearchable={isSearchable}
                            value={selectedItems.map(item => ({ value: item[valueField], label: t(item[nameField]) }))}
                            required
                            isDisabled={isDisabled}
                        />
                    </>

                ) : (
                    <ReactSelect
                        className="selectionDropdownMultiple"
                        id={name}
                        name={name}
                        placeholder={t('selectPlaceholder')}
                        options={itemList.map(item => ({ value: item[valueField], label: t(item[nameField]) }))}
                        isMulti
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        onChange={onSelectionToggle}
                        allowSelectAll={true}
                        isSearchable={isSearchable}
                        value={selectedItems.map(item => ({ value: item[valueField], label: t(item[nameField]) }))}
                        isDisabled={isDisabled}
                    />
                )}
            </div>
        </>
    );
};

export default SelectionDropdownMultiple;