import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
import { Chart } from 'chart.js';
import { useTranslation } from "react-i18next";
import '../testReport.css';

Chart.register(ChartDataLabels);

function createAxis(max_num) {
    return Array.from({ length: max_num + 2 }, (_, index) => index - 1);
}

const binData = (data, minScore, maxScore) => {
    const bins = {};
    for (let score = minScore; score <= maxScore; score++) {
        bins[score] = 0;
    }
    data.forEach(value => {
        const bin = Math.floor(value.total_score);
        bins[bin] = (bins[bin] || 0) + 1;
    });
    return bins;
};

const GeneralStatistics = ({ data = null, avg = null, totalMarks = null }) => {

    const binSize = 1; // Adjust bin size as needed

    const studentIds = Object.keys(data);
    const values = Object.values(data);

    // Determine min and max scores
    const minScore = Math.min(...values.map(value => value.total_score));
    const maxScore = Math.max(...values.map(value => value.total_score));

    // Bin the data
    const binnedData = binData(values, minScore, maxScore);
    const sortedBins = Object.keys(binnedData).sort((a, b) => parseInt(a) - parseInt(b));
    const { t } = useTranslation();

    const chartData = {
        labels: sortedBins,
        datasets: [
            {
                label: 'Number of Students',
                data: sortedBins.map(bin => binnedData[bin]),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Calculate the desired chart height based on data
    useEffect(() => {
        const numberOfDataPoints = Object.keys(binnedData).length;
    }, [sortedBins]);

    const chartOptions = {
        plugins: {
            title: {
                display: true,
                text: 'Total Score Distribution of Students',
                padding: {
                    bottom: 25,
                },
            },
            legend: {
                display: false,
            },
            // tooltip: {
            //     callbacks: {
            //         label: (context) => {
            //             return `${context.label}: ${context.raw}`;
            //         },
            //     },
            // },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                offset: 0,
                font: {
                    weight: 'bold',
                    size: 12,
                },
                formatter: (value) => value,
            },
        },
        scales: {
            x: {
                ticks: {
                    autoSkip: false,
                    // maxRotation: 90,
                    // minRotation: 90,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Number of Students',
                },
                ticks: {
                    stepSize: 1,
                    precision: 0,
                },
            },
        },
        maintainAspectRatio: true,
    };

    return (
        <div className="statistics-section flex">
            <div className="statistics-marks flex">
                <h3 className="title">{t('averageMarks')}: {avg ? avg.toFixed(2) : 'N/A'} </h3>
                <h3 className="title">{t('totalMarks')}: {totalMarks} </h3>
            </div>

            <div className="chart-container" id="general-statistics">
                <Bar data={chartData} options={chartOptions} />
            </div>
        </div>
    );
};

export default GeneralStatistics;