import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

import './Syllabus.css'

import SelectionDropdown from '../Common/SelectionDropdown';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { securedCreateTopic, securedFetchSubjects, securedFetchTopicsByFilter } from '../../services/SyllabusService';
import { securedFetchGrades } from '../../services/BatchService';

import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Dropdown from '../Common/Tailwind/SelectMenus/Dropdown';

const TopicCreate = ({ isOpen, onRequestClose, initialSubjects = [], initialGrades = [], initialSubject = {}, initialGrade = {}, onTopicCreate = null, user = ADMIN_USER_TYPE }) => {

    const [topicName, setTopicName] = useState("");
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [mainTopics, setMainTopics] = useState([]);
    const [selectedMainTopic, setSelectedMainTopic] = useState({});
    const navigate = useNavigate();

    const fetchAndSetSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        setSubjects(subjectsResponse.data);
        return subjectsResponse.data;
    };

    const fetchAndSetGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        setGrades(gradesResponse.data.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
        return gradesResponse.data;
    };

    const makeMainTopicFilter = (initSubjects = null, initGrades = null) => {
        const filter = {};
        if (selectedGrade.id) {
            filter['grades'] = [selectedGrade.id];
        } else if (initialGrade.id) {
            filter['grades'] = [initialGrade.id];
        } else {
            if (initialGrades) {
                filter['grades'] = initialGrades.map(grade => grade.id)
            } else {
                filter['grades'] = grades.map(grade => grade.id)
            }
        }

        if (selectedSubject.id) {
            filter['subject_ids'] = [selectedSubject.id];
        } else if (initialSubject.id) {
            filter['subject_ids'] = [initialSubject.id];
        } else {
            if (initSubjects) {
                filter['subject_ids'] = initSubjects.map(subject => subject.id)
            } else {
                filter['subject_ids'] = subjects.map(subject => subject.id)
            }
        }
        filter['parent_ids'] = [-1];
        return filter;
    }

    useEffect(() => {
        (async () => {
            if (!isOpen) {
                return;
            }
            let subjectsResponse, gradesResponse;
            if (!initialSubjects) {
                setSubjects(initialSubjects);
                subjectsResponse = initialSubjects;
            } else {
                subjectsResponse = await fetchAndSetSubjects();
            }
            if (subjectsResponse === null) {
                return;
            }

            if (!initialGrades) {
                setGrades(initialGrades);
                gradesResponse = initialGrades;
            } else {
                gradesResponse = await fetchAndSetGrades();
            }
            if (gradesResponse === null) {
                return;
            }
            setSelectedGrade(initialGrade);
            setSelectedSubject(initialSubject);
            setSelectedMainTopic({});
            const mainTopicFilter = makeMainTopicFilter(subjectsResponse, gradesResponse);
            const mainTopicsResponse = await securedFetchTopicsByFilter(mainTopicFilter, navigateCallbackOptions(navigate));
            if (mainTopicsResponse === null) {
                return;
            }
            setMainTopics(mainTopicsResponse.data);
        })();
    }, [isOpen]);

    const onSelectedMainTopicChange = (selection) => {
        const mainTopicId = selection.value ? selection.value : null;
        if (mainTopicId) {
            setSelectedMainTopic({ id: selection.value, topic_name: selection.label });
        }
    }

    const onSelectedSubjectChange = async (selection) => {
        const subjectId = selection.value ? selection.value : null;
        if (subjectId) {
            setSelectedSubject({ id: selection.value, subject_name: selection.label });
            const mainTopicFilter = makeMainTopicFilter();
            mainTopicFilter['subject_ids'] = [subjectId];
            const mainTopicsResponse = await securedFetchTopicsByFilter(mainTopicFilter, navigateCallbackOptions(navigate));
            if (mainTopicsResponse === null) {
                return;
            }
            setMainTopics(mainTopicsResponse.data);
            setSelectedMainTopic({});
        }
    }

    const onSelectedGradeChange = async (selection) => {
        const gradeId = selection.value ? selection.value : null;
        if (gradeId) {
            setSelectedGrade({ id: selection.value, grade: selection.label });
            const mainTopicFilter = makeMainTopicFilter();
            mainTopicFilter['grades'] = [gradeId];
            const mainTopicsResponse = await securedFetchTopicsByFilter(mainTopicFilter, navigateCallbackOptions(navigate));
            if (mainTopicsResponse === null) {
                return;
            }
            setMainTopics(mainTopicsResponse.data);
            setSelectedMainTopic({});
        }
    }

    const handleSubmit = async () => {
        if (!selectedSubject.id || !selectedGrade.id) {
            alert("Subject and Grade must be chosen!");
        }
        const data = {
            topic_name: topicName,
            grade: parseInt(selectedGrade.id),
        }

        if (selectedMainTopic.id) {
            data['parent_id'] = selectedMainTopic.id;
        }
        const response = await securedCreateTopic(selectedSubject.id, data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        const mainTopicsResponse = await securedFetchTopicsByFilter(makeMainTopicFilter(), navigateCallbackOptions(navigate));
        if (mainTopicsResponse === null) {
            return;
        }
        setMainTopics(mainTopicsResponse.data);
        if (onTopicCreate) {
            await onTopicCreate(response.topic_id);
        }
        onRequestClose();
    }

    return (
        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-20">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacitydata-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen overflow-y-auto">l
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6"
                    >

                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        <div className="mt-3 text-center sm:mt-5">
                            <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                Create New Topic
                            </DialogTitle>

                            <div className="mt-5 flex flex-col items-center justify-center sm:mx-auto w-full">

                                <form className="space-y-4">

                                    <div className="flex items-center justify-start gap-2 w-full">
                                        <label htmlFor="topicname"
                                            className="block text-sm font-medium leading-6 text-gray-900">
                                            Topic Name
                                        </label>
                                        <div className="relative w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                id="topicname"
                                                name="topicname"
                                                type="text"
                                                value={topicName}
                                                onChange={e => setTopicName(e.target.value)}
                                                placeholder="Enter..." required
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="textInput flex">
                                        <label className="title">Topic Name</label>
                                        <input type="text" value={topicName} onChange={e => setTopicName(e.target.value)} placeholder="Enter..." required />
                                    </div> */}

                                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start gap-4 md:gap-6 lg:gap-8 w-full">
                                        <Dropdown
                                            list={subjects}
                                            selected={selectedSubject}
                                            onSelectionChange={onSelectedSubjectChange}
                                            label='Subject'
                                            nameField='subject_name'
                                            valueField='id'
                                            // isSearchable={true}
                                            required={true}
                                            className='flex items-center justify-center gap-2'
                                        />
                                        {/* <SelectionDropdown className="topicSelectionDropdown"
                                            name='Subject'
                                            onSelectionChange={onSelectedSubjectChange}
                                            itemList={subjects}
                                            selectedItem={selectedSubject}
                                            nameField='subject_name'
                                            valueField='id' /> */}
                                        <Dropdown
                                            list={grades}
                                            selected={selectedGrade}
                                            onSelectionChange={onSelectedGradeChange}
                                            label='Grade'
                                            nameField='grade'
                                            valueField='id'
                                            // isSearchable={true}
                                            required={true}
                                            className='flex items-center justify-center gap-2'
                                        />
                                        {/* <SelectionDropdown className="topicSelectionDropdown"
                                            name='Grade'
                                            onSelectionChange={onSelectedGradeChange}
                                            itemList={grades}
                                            selectedItem={selectedGrade}
                                            nameField='grade'
                                            valueField='id' /> */}

                                        <Dropdown
                                            list={mainTopics}
                                            selected={selectedMainTopic}
                                            onSelectionChange={onSelectedMainTopicChange}
                                            label='Main Topic'
                                            nameField='topic_name'
                                            valueField='id'
                                            // isSearchable={true}
                                            required={true}
                                            className='flex items-center justify-center gap-2'
                                        />

                                        {/* <SelectionDropdown className="topicSelectionDropdown"
                                            name='Main Topic'
                                            onSelectionChange={onSelectedMainTopicChange}
                                            itemList={mainTopics}
                                            selectedItem={selectedMainTopic}
                                            nameField='topic_name'
                                            valueField='id'
                                            isSearchable={true} /> */}

                                    </div>
                                </form>

                                <div>
                                    <button className="mt-4 flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                                        id='submit' onClick={handleSubmit}>
                                        <span>SUBMIT</span>
                                        <ArrowUpTrayIcon aria-hidden="true" className="h-4 w-4 text-white" />
                                    </button>
                                </div>

                            </div>
                        </div>

                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default TopicCreate;
