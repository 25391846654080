import React, { useDebugValue, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip'
import moment from 'moment';
import { useTranslation } from "react-i18next";

import './Test.css'

import bkgImg from '../../Assets/Test.png'

import SelectionDropdown from '../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../Common/SelectionDropdownMultiple';
import TestEdit from './TestEdit';
import TestHeader from './TestHeader';
import TestUpsert from './TestCreate/TestCreate';
import { FULL_TEST_TYPES, TEST_CREATE_TYPE_BASIC, hasTestCreateAuthority, hasTestEditAuthority, securedCreateTest, securedFetchAllTestScoringFormats, securedFetchAllTestSeries, securedFetchTestById, securedFetchTestReport, securedFetchTests, securedStartTest } from '../../services/TestService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { MdAddCircleOutline } from "react-icons/md";
import { AiOutlineEdit } from 'react-icons/ai';
import Spinner from '../Common/Tailwind/Spinner';
import i18n from '../../multi-language/i18n';
import PageHeader from '../Common/Tailwind/Page/PageHeader';

const TestPage = ({ user = ADMIN_USER_TYPE }) => {
    const [isTabletView, setIsTabletView] = useState(window.innerWidth > 760);
    const [isTestModalOpen, setTestModalOpen] = useState(false);
    const [selectedTestTypes, setSelectedTestTypes] = useState([]);
    const [allTestSeries, setAllTestSeries] = useState([]);
    const [selectedTestSeriesMultiple, setSelectedTestSeriesMultiple] = useState([]);
    const [tests, setTests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTest, setSelectedTest] = useState({});

    const navigate = useNavigate();
    const { t } = useTranslation();

    const fetchAndSetAllTestSeries = async () => {
        const allTestSeriesResponse = await securedFetchAllTestSeries(navigateCallbackOptions(navigate));
        if (allTestSeriesResponse === null) {
            return null;
        }
        return allTestSeriesResponse.data;
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 760) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        (async () => {
            await refreshTestsWithFilter();
        })();

        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const makeFilter = (externalFilter = null) => {
        let filter = {}
        if (selectedTestTypes.length > 0) {
            filter['test_types'] = selectedTestTypes.map(testType => (testType.id));
        }
        if (selectedTestSeriesMultiple.length > 0) {
            filter['test_series_ids'] = selectedTestSeriesMultiple.map(testSeries => (testSeries.id));
        }
        if (externalFilter) {
            filter = { ...filter, ...externalFilter };
        }
        if (filter['test_types'] && filter['test_types'].length === 0) {
            delete filter['test_types'];
        }
        if (filter['test_series_ids'] && filter['test_series_ids'].length === 0) {
            delete filter['test_series_ids'];
        }
        return filter;
    };

    const fetchAllTests = async (externalFilter = null) => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, makeFilter(externalFilter), navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        if (!externalFilter.test_status) {
            return allTestsResponse.data;
        }
        return allTestsResponse.data.map((response) => ({ ...response, status: getTestStatusName(externalFilter.test_status) }));
    };

    const fetchAndSetAllTests = async (externalFilter = null) => {
        const inProgressTestsResponse = await fetchAllTests({ ...externalFilter, test_status: 'in_progress' });
        if (inProgressTestsResponse === null) {
            return null;
        }
        const finishedTestsResponse = await fetchAllTests({ ...externalFilter, test_status: 'finished' });
        if (finishedTestsResponse === null) {
            return null;
        }
        const notStartedTestsResponse = await fetchAllTests({ ...externalFilter, test_status: 'not_started' });
        if (notStartedTestsResponse === null) {
            return null;
        }
        const response = [...inProgressTestsResponse, ...finishedTestsResponse, ...notStartedTestsResponse]
        if (!response.find(response => response.id === selectedTest.id)) {
            setSelectedTest({});
        }
        setTests(response);
        return response;
    };

    const refreshTestsWithFilter = async (externalFilter = null) => {
        setIsLoading(true);
        const testSeriesList = await fetchAndSetAllTestSeries();
        if (testSeriesList === null) {
            return null;
        }
        setAllTestSeries(testSeriesList);
        const result = await fetchAndSetAllTests(externalFilter);
        setIsLoading(false);
        return result;
    }

    const onTestTypeSelectionChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedOptionsFromSelect = FULL_TEST_TYPES.filter(testType => selectedIds.includes(testType.id));
        setSelectedTestTypes(selectedOptionsFromSelect);
        await refreshTestsWithFilter({ test_types: selectedOptionsFromSelect.map(testType => testType.id) });
    };

    const onTestSeriesSelectionChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedOptionsFromSelect = allTestSeries.filter(testSeries => selectedIds.includes(testSeries.id));
        setSelectedTestSeriesMultiple(selectedOptionsFromSelect);
        await refreshTestsWithFilter({ test_series_ids: selectedOptionsFromSelect.map(testSeries => testSeries.id) });
    };

    const onTestSelectionChange = (testId) => {
        navigate(`${testId}`);
    };

    const convertDurationFormat = (duration) => {

        const hrs = Math.floor(duration / 60);
        const mins = duration % 60;

        const hrs_str = (hrs < 10 ? "0" : "") + hrs.toString();
        const mins_str = (mins < 10 ? "0" : "") + mins.toString();

        return hrs_str + ":" + mins_str;
    }

    const getTestStatusName = (statusCode) => {
        if (statusCode === 'in_progress') {
            return 'STARTED';
        }
        if (statusCode === 'not_started') {
            return 'NOT-STARTED';
        }
        return 'FINISHED';
    }

    const renderTest = () => {
        if (isLoading) {
            return (<div className="tests-glass flex">
                <Spinner /></div>);
        }

        return (
            <div className="tests-glass flex">

                {isTabletView ? (
                    <>
                        <h3 className="title">Please select the type, the series and the name of test.</h3>

                        <div className="testsList flex">
                            <div className="testsTable flex" >

                                <table className="tests-table-style">
                                    <thead>
                                        <tr>
                                            <th>{t('status')}</th>
                                            <th>{t('id')}</th>
                                            <th>{t('name')}</th>
                                            <th>{t('type')}</th>
                                            <th>{t('date')}</th>
                                            <th>{t('time')}</th>
                                            <th>{t('duration')}</th>
                                            {/* <th>Remaining Time</th> */}
                                            {/* <th>...</th> */}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {tests.length > 0 && tests.map(test => (
                                            <tr key={test.id} onClick={() => onTestSelectionChange(test.id)}>
                                                <td>
                                                    {test.status === "NOT-STARTED" ? (
                                                        <p className={'bg-green-100 text-green-700 rounded-md px-1 py-1 text-sm font-medium'}>
                                                            {t('upcomingCapital')}
                                                        </p>
                                                    ) : (test.status === "FINISHED" ?
                                                        <p className={'flex justify-center items-center gap-1 bg-blue-100 text-blue-700 rounded-md px-1 py-1 text-sm font-medium'}>
                                                            {t('finishedCapital')}
                                                        </p>
                                                        :
                                                        <p className={'bg-red-100 text-red-700 rounded-md px-3 py-2 text-sm font-medium'}>
                                                            {t('startedCapital')}
                                                        </p>
                                                    )}
                                                </td>
                                                <td>
                                                    {test.id}
                                                </td>
                                                <td>
                                                    {test.test_name}
                                                </td>
                                                <td>
                                                    {test.test_type.toUpperCase()}
                                                </td>
                                                <td>
                                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('DDslMMslYYYY')) : null}
                                                </td>
                                                <td>
                                                    {test.test_start_time ? moment(test.test_start_time).locale(i18n.language).format(t('hhcommA')) : null}
                                                </td>
                                                <td>
                                                    {test.test_duration_minutes ? convertDurationFormat(test.test_duration_minutes) : null}
                                                </td>
                                                {/* <td>
                                            </td> */}
                                                {/* <td>

                                    </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <h3 className="title">{t('pleaseOpenTestInPCOrTablet')}</h3>
                    </>
                )}

            </div>
        );
    }

    const onTestAdd = async (testId) => {
        const tests = await fetchAndSetAllTests();
        if (tests === null) {
            return;
        }
    }


    return (
        <div className="relative w-full h-full flex flex-col items-center p-4 gap-2 sm:gap-3 md:gap-4  text-[var(--textColor)] animate-fadeUp">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t('simplifyTestManagement')} /> */}

            <div className="tests flex">

                <div className="tests-filter flex">
                    <div className="filters">
                        <SelectionDropdownMultiple className='filterSelect' name={t('types')} itemList={FULL_TEST_TYPES} selectedItems={selectedTestTypes} onSelectionToggle={onTestTypeSelectionChange} nameField='name' valueField='id' />
                        <SelectionDropdownMultiple className='filterSelect' name={t('series')} itemList={allTestSeries} selectedItems={selectedTestSeriesMultiple} onSelectionToggle={onTestSeriesSelectionChange} nameField='test_series_name' valueField='id' isSearchable={true} />
                        {/* <SelectionDropdown className='filterSelect' name="Name" itemList={tests} selectedItem={selectedTest} onSelectionChange={onTestSelectionChange} nameField='test_name' valueField='id' isSearchable={true} /> */}

                        {hasTestCreateAuthority(user) && (
                            <>
                                <button className="create-btn flex"
                                    onClick={() => setTestModalOpen(true)}
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content={t('createNewTest')}
                                    data-tooltip-place="right">
                                    <MdAddCircleOutline className="icon" />
                                </button>

                                <Tooltip id="tooltip" />
                                <TestUpsert isOpen={isTestModalOpen} onRequestClose={() => (setTestModalOpen(false))} onTestUpsert={onTestAdd} />
                            </>
                        )}
                    </div>
                </div>

                {renderTest()}

            </div>

        </div>
    );
}

export default TestPage;
