import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';

import './slotview.css';
import { hasSlotEndAuthority } from '../../services/TestService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { securedUpdateSlotById, securedUpdateSlotByIdClassLectureVideoId, securedUpdateSlotByIdEndTime } from '../../services/TimetableService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { BsRecordCircle, BsUpload } from 'react-icons/bs';
import VideoViewer from '../Files/Video/VideoViewer';
import { securedCreateClassLectureVideo, securedCreateVideo, securedFetchClassLectureVideoById, securedUploadVideoFile } from '../../services/VideoService';
import TailwindTabs from '../Common/Tailwind/Navigation/Tabs';
import LoadingPage from '../Common/LoadingPage';
import { useAuth } from '../Authentication/AuthContext';
import SlotRecordedLectureVideos from './SlotRecordedLectureVideos';
import Spinner from '../Common/Tailwind/Spinner';
import { convertToHumanReadable } from '../../services/DateService';
import VideoUpload from '../Files/Video/VideoUpload';
import VideoPreview from '../Files/Video/VideoPreview';

const SlotLectureVideo = ({ lectureData, user = ADMIN_USER_TYPE }) => {
    const [showRecordingEndSuccess, setShowRecordingEndSuccess] = useState(false);
    const navigate = useNavigate();
    const [videoTabs, setVideoTabs] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [videos, setVideos] = useState([]);
    const [videoFileNames, setVideoFileNames] = useState([]);

    const {
        config
    } = useAuth();


    const onRecordingEnd = async () => {
        const response = await securedUpdateSlotByIdEndTime(lectureData.id, navigateCallbackOptions(navigate));
        if (response !== null) {
            setShowRecordingEndSuccess(true);
        }
    };

    const renderLectureVideo = () => {
        const videoTitle = `Lecture by ${lectureData.teacher.user_data.first_name} of ${lectureData.subject.subject_name} taken on ${convertToHumanReadable(lectureData.start_time)}`
        return (<VideoViewer videoType='class_lecture' videoId={lectureData.class_lecture_video_id} user={user} videoTitle={videoTitle} defaultApproverId={lectureData.teacher_id} />);
    }
    const renderRecordedVideos = () => {

        return (<SlotRecordedLectureVideos lectureData={lectureData} user={user} />);
    }

    const getRenderByTabs = () => {
        const renderbyTabs = {};
        renderbyTabs['Class Lecture'] = renderLectureVideo;
        renderbyTabs['Related Videos'] = renderRecordedVideos;
        return renderbyTabs;
    }


    const onUploadClick = async () => {
        const videoResponse = await securedUploadVideoFile(videos[0], { title: `Class Recording for lecture of ${lectureData.subject.subject_name} on ${convertToHumanReadable(lectureData.start_time)}` }, { onSuccess: onUploadVideoSuccess }, navigateCallbackOptions(navigate));
        alert("You will be notified once the video upload is complete.")
    }

    const onUploadVideoSuccess = async (url) => {
        const data = { url: url, title: `Class Recording for lecture of ${lectureData.subject.subject_name} on ${convertToHumanReadable(lectureData.start_time)}` };
        const videoCreateResponse = await securedCreateVideo(data, navigateCallbackOptions(navigate));
        if (videoCreateResponse === null) {
            return;
        }
        const classLectureData = { video_id: videoCreateResponse.video_id };
        const classLectureCreateResponse = await securedCreateClassLectureVideo(classLectureData, navigateCallbackOptions(navigate));
        const timetableSlotOpenResponse = await securedUpdateSlotByIdClassLectureVideoId(lectureData.id, { class_lecture_video_id: classLectureCreateResponse.class_lecture_video_id }, navigateCallbackOptions(navigate));
        alert('Video uploading completed!');
    }

    useEffect(() => {
        if (!config) {
            return;
        }
        let allowedTabs = [];
        if (config.DISPLAY_LECTURE_VIDEO_TYPES.includes('class_lecture') || user === ADMIN_USER_TYPE) {
            allowedTabs.push('Class Lecture');
        }
        if (config.DISPLAY_LECTURE_VIDEO_TYPES.includes('recorded_lecture') || user === ADMIN_USER_TYPE) {
            allowedTabs.push('Related Videos');
        }
        setVideoTabs(allowedTabs);
        setSelectedTab(allowedTabs.length > 0 ? allowedTabs[0] : null);
    }, [config]);

    if (videoTabs === null) {
        return <Spinner />
    }

    return (
        <div className="slotLectureVideo">

            {lectureData.class_lecture_video_id ? (
                <TailwindTabs tabs={videoTabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} renderByTabs={getRenderByTabs()} />
            ) :
                (
                    <div className="videoPlayer flex">
                        <p className="no-video-msg"> Video is not available yet. </p>

                        {
                            hasSlotEndAuthority(user) && (
                                <>
                                    <button className="btn flex" id="record" onClick={onRecordingEnd} >
                                        End Recording <BsRecordCircle className="icon" />
                                    </button>

                                    <VideoUpload title='Video' videos={videos} setVideos={setVideos} label={`video`} maxSize={1} setVideoFileNames={setVideoFileNames} />
                                    <VideoPreview videos={videos} setVideos={setVideos} videoFileNames={videoFileNames} />
                                    <button className="btn flex" id="record" onClick={onUploadClick} >
                                        Upload Class Lecture Video <BsUpload className="icon" />
                                    </button>
                                    {showRecordingEndSuccess && (
                                        <p className="end-recording-msg"> End Recording time marked!</p>
                                    )}
                                </>
                            )
                        }
                    </div >
                )}
        </div >
    );
};

export default SlotLectureVideo;
