import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import './User.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

import BaseRegisterForm from '../Authentication/BaseRegisterForm';
import { useNavigate } from 'react-router-dom';
import GradeBatchFilter from '../Batches/GradeBatchFilter';
import { BsCloudUpload } from 'react-icons/bs'
import { ADMIN_USER_TYPE, securedRegisterUser } from "../../services/UserService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";

const StudentRegisterForm = ({ onStudentCreate = null, setIsSaving, user = ADMIN_USER_TYPE }) => {

    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [fullName, setFullName] = useState("");
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [batches, setBatches] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState({});
    const navigate = useNavigate();
    const { t } = useTranslation();

    const resetState = () => {
        setEmail("");
        setContactNumber("");
        setFullName("");
    }

    const onSelectedBatchChange = (selected) => {
        setSelectedBatch({ id: selected.value, batch_name: selected.label });
    }

    const handleRegister = async () => {
        setIsSaving(true);

        const data = {
            user_data: {
                first_name: fullName,
                last_name: "",
                email: email,
                phone_number: contactNumber,
                password: 'password'
            },
            batches: [{ batch_id: parseInt(selectedBatch.id) }]
        }
        const response = await securedRegisterUser('student', data, navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onStudentCreate) {
            await onStudentCreate();
        }
        resetState();
        setIsSaving(false);

    };

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    return (

        <div className="register-container flex">

            <h3 data-aos="fade-down" className="registerTitle">
                {t('registerNewStudent')}
            </h3>

            <form className="registerInfoInput flex" onSubmit={(event) => (event.preventDefault())}>

                <BaseRegisterForm email={email} setEmail={setEmail} contactNumber={contactNumber} setContactNumber={setContactNumber} fullName={fullName} setFullName={setFullName} />

                <GradeBatchFilter grades={grades} selectedGrade={selectedGrade} batches={batches} selectedBatch={selectedBatch} setGrades={setGrades} setSelectedGrade={setSelectedGrade} setBatches={setBatches} setSelectedBatch={setSelectedBatch} onSelectedBatchChange={onSelectedBatchChange} required={true} />

            </form>

            <button className='btn flex' onClick={handleRegister}>
                {t('submitCapitalized')} <BsCloudUpload className="icon" />
            </button>

        </div>
    )
};

export default StudentRegisterForm;
