import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone';

import './Image.css';

const ImageUpload = ({ title = 'Images', images, selectImages, label, maxSize = 1, setImageFileNames }) => {

    const [addEnabled, setAddEnabled] = useState(true);

    const onSelectImage = (selectImages) => (event) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file)
        });

        selectImages((previousImageArray) => previousImageArray.concat(imagesArray));
        event.target.value = null;
    };

    useEffect(() => {
        if (maxSize && images.length > maxSize) {
            setAddEnabled(false);
        } else {
            setAddEnabled(true);
        }
    }, [images]);

    const onDrop = useCallback((selectedFiles) => {
        const selectedFilesArray = Array.from(selectedFiles);
        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        });

        selectImages((previousImageArray) => previousImageArray.concat(imagesArray));
        setImageFileNames(selectedFiles);
    }, [selectImages]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg']
        },
        multiple: true,
        maxFiles: 10,
    });

    return (
        <div className="flex items-center justify-start gap-2 w-full">

            <label className="block text-sm font-medium leading-6 text-gray-900">{title}</label>

            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 px-12 py-6 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex gap-4 items-center justify-center">
                {addEnabled && images.length < maxSize && (
                    <div {...getRootProps()} className="block text-sm font-semibold">
                        <input {...getInputProps()} id={`${label.toLowerCase()}-image-upload`} name="images" />
                        {isDragActive ? (
                            <span className="text-[var(--PrimaryColor)]">Drop the files here ...</span>
                        ) : (
                            <span className="text-[var(--PrimaryColor)]">Drag 'n' drop files here <br />
                                or <br />
                                Click to select files
                            </span>
                        )}
                    </div>
                )}

                {images.length > 0 &&
                    <div className="block text-sm font-semibold flex flex-col gap-4 items-center justify-center">
                        {images.length >= maxSize && (
                            <span className="text-[var(--tiger-lilly)]">
                                Can't upload more than {maxSize}  {maxSize > 1 ? 'images' : 'image'}! <br />
                                {images.length > maxSize && <span>Please delete <b>{images.length - maxSize}</b> of them.</span>}
                            </span>
                        )}

                        <span className="text-[var(--SecondaryColor)]">
                            Total {images.length} image{images.length === 1 ? '' : 's'}
                        </span>
                    </div>
                }
            </div>

        </div>
    )
}

export default ImageUpload;
