import React from 'react'

import 'aos/dist/aos.css'

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';
import '../../Components/Batches/batches.css'

import { AdminNavItems } from './AdminNavItems';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { useParams } from 'react-router-dom';
import SlotView from '../../Components/Slots/SlotView';

const AdminSlots = () => {
    const { slotId } = useParams();
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={AdminNavItems} />
            <SlotView slotId={parseInt(slotId)} user={ADMIN_USER_TYPE} />

            <footer className="sticky bottom-0 w-full">
                <Footer />
            </footer>

        </div>
    )
}

export default AdminSlots;
