import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './Timer.css'; // Make sure to create this CSS file
import { hasTestEditAuthority } from '../../services/TestService';

function Timer({ editEnable, status, setTestStatus, setDuration, start, duration, remainingTime, timerType }) {
    const [isActive, setActive] = useState(false);
    const [seconds, setSeconds] = useState(0); // Let's assume a 5-minute timer for example
    const { t } = useTranslation();

    useEffect(() => {
        let interval = null;

        if (status === 'STARTED') {
            setActive(true);
            setSeconds(remainingTime);
            // setInputTime(duration * 60);

        } else {
            setActive(false);
            setSeconds(duration * 60);
        }

        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
                if (seconds === 1) {
                    setTestStatus('FINISHED');
                }
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, remainingTime]);

    const formatTime = () => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor(seconds % 3600 / 60);
        const secondsLeft = seconds % 60;
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`;
    };

    const progress100Percentage = () => {
        const percentage = Math.floor((seconds / duration * 60) * 100);

        if (percentage > 50) return "";
        else {
            if (percentage > 10) return "half";
            else return "little";
        }
    }

    return (
        <>
            {timerType === "NTA" ? (
                <div className={`NTATimer-label ${progress100Percentage()}`}>{formatTime()}</div>
            ) : (
                <div className={`timer-container ${status === 'STARTED' ? "on-top" : ""}`}>
                    <div className="timer">
                        <svg className="progress-ring" width="100%" height="100%" viewBox="0 0 200 200">
                            <circle
                                className={`progress-ring__circle ${progress100Percentage()}`}
                                r="90"
                                cx="100"
                                cy="100"
                                style={{
                                    strokeDasharray: `565.48`,
                                    strokeDashoffset: status === 'STARTED' ? `${565.48 - (565.48 * seconds) / (duration * 60)}` : 0
                                }}
                            />
                        </svg>
                        {(editEnable && status === 'NOT STARTED') ? (
                            <div className="timer-label-input flex">
                                <input name="inputTime"
                                    className="inputTime"
                                    type='number'
                                    placeholder={duration ? `${duration} ${t('minutes')}` : t('minutesCapital')}
                                    value={duration}
                                    onChange={(event) => { event.target.value !== '' ? setDuration(Math.max(0, event.target.value)) : setDuration('') }}
                                />
                                <div className="controls">
                                    <button onClick={start}>{t('startCapital')}</button>
                                </div>
                            </div>
                        ) : (
                            <div className="timer-label">{formatTime()}</div>
                        )}
                    </div>
                </div >
            )
            }
        </>
    );
}

export default Timer;

