import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import '../questionModal.css'

import ImagePreview from '../../../Common/ImagePreview'
import ImageUpload from '../../../Common/ImageUpload'
import RadioAnswerInputList from '../RadioAnswerInputList'
import { QuestionFormContext } from '../QuestionFormContext';
import { mapToSingleChoiceQuestionCreateFormat, securedPostQuestion, QUESTION_DIFFICULTIES, securedUpdateQuestion, makeAnswerData, QUESTION_OPTIONS } from '../../../../services/QuestionService';
import { ADMIN_USER_TYPE } from "../../../../services/UserService";
import { navigateCallbackOptions } from "../../../../services/AuthenticationService";

import LoadingPage from "../../../Common/LoadingPage";
import { replaceImagesInObject } from "../../../../services/CommonService";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";

const QuestionTypeSingleChoice = ({ id, inputQuestionData = { id: null }, isImageBased = false, hasSubmit, topics, tags, overallDifficulty, onRequestClose = null, onAddQuestion = null, isQuestionDataValid, setIsSaving, user = ADMIN_USER_TYPE }) => {

    const { dispatch } = useContext(QuestionFormContext);
    const [questionImages, setQuestionImages] = useState([]);
    const [solutionImages, setSolutionImages] = useState([]);
    const [answerInputs, setAnswerInputs] = useState(makeAnswerData(Array(QUESTION_OPTIONS.length).fill('')));
    const [question, setQuestion] = useState("");
    const [difficulty, setDifficulty] = useState(overallDifficulty ? overallDifficulty : QUESTION_DIFFICULTIES[0]);
    const [selectedAnswer, setSelectedAnswer] = useState(0);
    const [solution, setSolution] = useState("");
    const [questionImageFileNames, setQuestionImageFileNames] = useState([]);
    const [solutionImageFileNames, setSolutionImageFileNames] = useState([]);

    const navigate = useNavigate();

    const resetState = () => {
        setQuestionImages([]);
        setSolutionImages([]);
        setAnswerInputs(makeAnswerData(Array(QUESTION_OPTIONS.length).fill('')));
        setQuestion("");
        setSelectedAnswer(0);
        setSolution("");
    }

    const makeQuestionData = () => {
        return {
            id: inputQuestionData.id,
            question: !isImageBased ? question : '',
            answerInputs: !isImageBased ? answerInputs : answerInputs.map((answer, index) => {
                const answerCopy = { ...answer }
                answerCopy.value = '';
                return answerCopy;
            }),
            selectedAnswer,
            solution: !isImageBased ? solution : '',
            questionImages,
            solutionImages,
            topics,
            tags,
            difficulty: difficulty ? difficulty.id : QUESTION_DIFFICULTIES[0].id
        }
    }

    useEffect(() => {
        if (!difficulty) {
            setDifficulty(QUESTION_DIFFICULTIES[0]);
        }
        dispatch({
            type: 'UPDATE_QUESTION',
            payload: {
                id,
                type: 'single choice',
                data: makeQuestionData()
            }
        });
    }, [question, questionImages, answerInputs, selectedAnswer, solution, solutionImages, topics, tags, difficulty, isImageBased]);

    useEffect(() => {
        if (isImageBased) {
            if (inputQuestionData.id !== null) {
                setAnswerInputs(inputQuestionData.answers.map((answer, index) => ({ question_answer_id: answer.id, id: answer.answer_id, value: String.fromCharCode(index + 97) })));
            } else {
                setAnswerInputs(makeAnswerData([...QUESTION_OPTIONS]));
            }
            return;
        }
        if (inputQuestionData.id !== null) {
            setAnswerInputs(inputQuestionData.answers.map(answer => ({ question_answer_id: answer.id, id: answer.answer_id, value: answer.answer.answer_text })));
            return;
        }
        setAnswerInputs(makeAnswerData(Array(QUESTION_OPTIONS.length).fill('')));
    }, [isImageBased]);

    useEffect(() => {
        if (inputQuestionData.id === null) {
            return;
        }
        setQuestion(inputQuestionData.question_text);
        setAnswerInputs(inputQuestionData.answers.map(answer => ({ question_answer_id: answer.id, id: answer.answer_id, value: answer.answer.answer_text })));
        setSelectedAnswer(inputQuestionData.answers.findIndex(answer => answer.is_correct === true));
        setSolution(inputQuestionData.solution ? inputQuestionData.solution.solution_text : '');
        (async () => {
            setQuestionImages((await replaceImagesInObject(inputQuestionData.images)).map(image => image.image.url), navigate);
            setSolutionImages(inputQuestionData.solution ? (await replaceImagesInObject(inputQuestionData.solution.images, navigate)).map(image => image.image.url) : []);
        })();
        setDifficulty(QUESTION_DIFFICULTIES.find(questionDifficulty => questionDifficulty.id === inputQuestionData.question_difficulty));
    }, [inputQuestionData.id]);

    const onTextChange = (inputText) => (event) => {
        const { value } = event.target;
        inputText(value);
    };

    const handleDifficultyClick = (selection) => {
        setDifficulty({ id: selection.id, name: selection.name });
    }

    const isInternalDataInvalid = () => {
        if (!isImageBased) {
            return !isQuestionDataValid() || question.length === 0 || answerInputs.some(answer => answer.value.trim() === '');
        }
        return !isQuestionDataValid() || questionImages.length === 0;
    }

    const handleSubmit = async () => {
        if (isInternalDataInvalid()) {
            return;
        }
        setIsSaving(true);
        const data = makeQuestionData();
        let response;
        if (inputQuestionData.id === null) {
            response = await securedPostQuestion(data, 'standalone', mapToSingleChoiceQuestionCreateFormat, navigateCallbackOptions(navigate));
        } else {
            response = await securedUpdateQuestion(inputQuestionData.id, data, 'standalone', mapToSingleChoiceQuestionCreateFormat, navigateCallbackOptions(navigate));
        }
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onAddQuestion) {
            await onAddQuestion('standalone', inputQuestionData.id !== null ? inputQuestionData.id : response.question_id);
        }
        resetState();
        setIsSaving(false);
        if (onRequestClose != null) {
            onRequestClose();
        }
    }

    return (
        <div className="flex flex-col items-start sm:items-center justify-start gap-4 w-full">

            {/* Question Input */}
            {!isImageBased && (
                <div className="flex items-center justify-start gap-2 w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900">Question</label>
                    <textarea className="text" placeholder="Enter..." onChange={onTextChange(setQuestion)} value={question} rows="4" required />
                </div>)}

            {/* Question Image Input */}
            <ImageUpload title='Question Image(s)' images={questionImages} selectImages={setQuestionImages} label={`question-${id}`} maxSize={isImageBased ? 1 : null} setImageFileNames={setQuestionImageFileNames} />
            <ImagePreview images={questionImages} selectImages={setQuestionImages} imageFileNames={questionImageFileNames} />

            {/* Difficulty Selection */}
            <div className="flex items-center justify-start gap-2 w-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">Difficulty</label>

                <div className="flex space-x-4">
                    {QUESTION_DIFFICULTIES.map((difficulty_option) => (
                        <button
                            key={difficulty_option}
                            className={`rounded-md px-3 py-2 text-sm font-medium ${difficulty.id === difficulty_option.id ? 'bg-[var(--PrimaryColor)] text-white' : 'text-gray-500 hover:text-gray-700'} flex`}
                            onClick={() => handleDifficultyClick(difficulty_option)}
                        >
                            {difficulty_option.name}
                        </button>
                    ))}
                </div>
            </div>
            {/* <SelectionDropdown className="questionSelectionDropdown" name=" Difficulty" itemList={QUESTION_DIFFICULTIES} selectedItem={difficulty} onSelectionChange={onDifficultyChange} nameField='name' valueField='id' required={true} /> */}

            {/* Answer Input */}
            {/* question_answer_id: answer.id, id: answer.answer_id, value: answer.answer.answer_text */}
            <RadioAnswerInputList className="flex items-center justify-start gap-2 w-full"
                label="Answer"
                namePrefix={id}
                answerInputs={answerInputs}
                setAnswerInputs={setAnswerInputs}
                selectedAnswer={selectedAnswer}
                setSelectedAnswer={setSelectedAnswer}
                required={true}
                editable={!isImageBased} />

            {/* Solution Input */}
            {!isImageBased && (
                <div className="flex items-center justify-start gap-2 w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900">Solution</label>
                    <textarea className="text" placeholder="Enter..." value={solution} onChange={onTextChange(setSolution)} rows="4" />
                </div>)}

            {/* Solution Image Input */}
            <ImageUpload title='Solution Image(s)' images={solutionImages} selectImages={setSolutionImages} label={`solution-${id}`} maxSize={isImageBased ? 1 : null} setImageFileNames={setSolutionImageFileNames} />
            <ImagePreview images={solutionImages} selectImages={setSolutionImages} imageFileNames={solutionImageFileNames} />

            {hasSubmit && (
                <div>
                    <button className="mt-2 flex w-full justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                        id='submit' onClick={handleSubmit}>
                        <span>UPLOAD</span>
                        <ArrowUpTrayIcon aria-hidden="true" className="h-4 w-4 text-white" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default QuestionTypeSingleChoice