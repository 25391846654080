import React from 'react'

import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import Dashboard from '../../Components/Dashboard/Dashboard';

import { TeacherNavItems } from './TeacherNavItems';
import { TEACHER_USER_TYPE } from '../../services/UserService';

const TeacherDashboard = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={TeacherNavItems} user={TEACHER_USER_TYPE} />
            <Dashboard user={TEACHER_USER_TYPE} />
            <Footer user={TEACHER_USER_TYPE} />
        </div>
    )
};

export default TeacherDashboard;