import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";

import './NTA.css'

import { ADMIN_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoMdClose } from "react-icons/io";
import i18n from '../../../multi-language/i18n';

const NTAInstructions = ({ isOpen, onRequestClose, testData, handleInstructionAgree, infoOnly = false, parentId = null, user = ADMIN_USER_TYPE }) => {

    const [isAgreed, setIsAgreed] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const { t } = useTranslation();

    const handleCheckboxChange = (event) => {
        setIsAgreed(event.target.checked);
    };

    const handleProceedtoNext = () => {
        if (isAgreed) {
            handleInstructionAgree();
        } else {
            setShowPopup(true);
        }
    }

    if (testData === null) return;

    const convertDurationFormat = (duration) => {

        const hrs = Math.floor(duration / 60);
        const mins = duration % 60;

        const hrs_str = (hrs === 0) ? "" : ((hrs < 10 ? "0" : "") + hrs.toString() + (hrs > 1 ? " hours" : " hour"));
        const mins_str = (mins === 0) ? "" : ((mins < 10 ? "0" : "") + mins.toString() + (mins > 1 ? " minutes" : "minute"));

        return hrs_str + ((hrs > 0 && mins > 0) ? " and " : "") + mins_str;
    }


    return (
        <Modal overlayClassName="customModal" className="NTAModal flex" isOpen={isOpen} onRequestClose={onRequestClose} parentSelector={() => parentId ? document.getElementById(parentId) : document.body}>
            <div className="NTAInstructions flex">
                <div className="title flex">
                    <h2 className="general roboto-bold">
                        {t("instructions")}
                    </h2>
                </div>

                <div className="main flex">
                    <h2 className="caution roboto-bold">
                        {t("pleaseReadTheInstructionsCarefully")}
                    </h2>

                    <ol className="olInstruction roboto-regular">
                        <h3 className="list-title roboto-bold">
                            {t("generalInstructions")}
                        </h3>

                        <li className="olItem">
                            {t("totalDurationOf")} <strong>{testData.test_name}</strong> {t("is")} <strong>{convertDurationFormat(testData.test_duration_minutes)}</strong>.
                        </li>

                        <li className="olItem">
                            {t("theClockWillBeSetAtTheServer")} {t("theCountdownTimerInTheTopRightCornerOfScreenWillDisplayTheRemainingTimeAvailableForYouToCompleteTheExamination")} {t("whenTheTimerReachesZeroTheExaminationWillEndByItself")} {t("youWillNotBeRequiredToEndOrSubmitYourExamination")}.
                        </li>

                        <li className="olItem">
                            {t("theQuestionsPaletteDisplayedOnTheRightSideOfScreenWillShowTheStatusOfEachQuestionUsingOneOfTheFollowingSymbols")}:

                            <table className="instruction_area">
                                <tbody>
                                    <tr>
                                        <td>
                                            <span className="not_visited" title="Not Visited">1</span>
                                        </td>
                                        <td>
                                            {t("youHaveNotVisitedYet")}.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="not_answered" title="Not Answered">2</span>
                                        </td>
                                        <td>
                                            {t("youHaveNotAnsweredTheQuestion")}.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="answered" title="Answered">3</span>
                                        </td>
                                        <td>
                                            {t("youHaveAnsweredTheQuestion")}.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="review" title="Marked for Review">4</span>
                                        </td>
                                        <td>
                                            {t("youHaveNotAnsweredTheQuestionButHaveMarkedTheQuestionForReview")}.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="review_marked_considered" title="Answered & Marked for Review">5</span>
                                        </td>
                                        <td>
                                            {t("theQuestionsAnsweredAndMarkedForReviewWillBeConsideredForEvalution")}.
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                            {/* <ol className="olInstruction-child">
                                <li className="olItem">
                                    <img src={icon1} alt="Unvisited Question" /> You have not visited the question yet.
                                </li>
                                <li className="olItem">
                                    <img src={icon2} alt="Unanswered Question" /> You have not answered the question.
                                </li>
                                <li className="olItem">
                                    <img src={icon3} alt="Answered Question" /> You have answered the question.
                                </li>
                                <li className="olItem">
                                    <img src={icon4} alt="Marked for Review" /> You have NOT answered the question, but have marked the question for review.
                                </li>
                                <li className="olItem">
                                    <img src={icon5} alt="Answered and Marked for Review" /> The question(s) "Answered and Marked for Review" will be considered for evalution.
                                </li>
                            </ol> */}
                        </li>

                        {/* <li className="olItem">
                            You can click on the "&gt;" arrow which apperes to the left of question palette to collapse the question palette thereby maximizing the question window. To view the question palette again, you can click on "&lt;" which appears on the right side of question window.
                        </li> */}

                        {/* <li className="olItem">
                            You can click on your "Profile" image on top right corner of your screen to change the language during the exam for entire question paper. On clicking of Profile image you will get a drop-down to change the question content to the desired language.
                        </li> */}

                        {/* <li className="olItem">
                            You can click on <img src={iconDown} alt="Icon Down" className="inline-icon" /> to navigate to the bottom and <img src={iconUp} alt="Icon Up" className="inline-icon" /> to navigate to top of the question are, without scrolling.
                        </li> */}

                        <h3 className="list-title roboto-bold">
                            {t("navigatingToAQuestion")}:
                        </h3>

                        <li className="olItem">
                            {t("toAnswerAQuestionDoTheFollowing")}:
                            <ol className="olInstruction-child" type="a">
                                <li className="olItem">
                                    {t("clickOnTheQuestionNumberInTheQuestionPaletteAtTheRightOfYourScreenToGoToThatNumberedQuestionDirectly")} {t("noteThatUsingThisOptionDoesNotSaveYourAnswerToTheCurrentQuestion")}.
                                </li>
                                <li className="olItem">
                                    {t("clickOn")} <strong>{t("saveAndNext")}</strong> {t("toSaveYourAnswerForTheCurrentQuestionAndThenGoToTheNextQuestion")}.
                                </li>
                                <li className="olItem">
                                    {t("clickOn")} <strong>{t("markForReviewAndNext")}</strong> {t("toSaveYourAnswerForTheCurrentQuestionMarkItForReviewAndThenGoToTheNextQuestion")}.
                                </li>
                            </ol>
                        </li>

                        <h3 className="list-title roboto-bold">
                            {t("answeringAQuestion")}:
                        </h3>

                        <li className="olItem">
                            {t("procedureForAnsweringAMultipleChoiceTypeQuestion")}:
                            <ol className="olInstruction-child" type="a">
                                <li className="olItem">
                                    {t("toSelectYourAnswerClickOnTheButtonOfOneOfTheOptions")}.
                                </li>
                                <li className="olItem">
                                    {t("toDeselectYourChosenAnswerClickOnTheButtonOfTheChosenOptionAgainOrClickOnThe")}
                                    <strong>{t("clearResponse")}</strong>
                                    {t("button")}.
                                </li>
                                <li className="olItem">
                                    {t("toChangeYourChosenAnswerClickOnTheButtonOfAnotherOption")}.
                                </li>
                                <li className="olItem">
                                    {t("toSaveYourAnswerYouMustClickOnThe")}
                                    <strong>{t("saveAndNext")}</strong>
                                    {t("button")}.
                                </li>
                                <li className="olItem">
                                    {t("toMarkTheQuestionForReviewClickOnThe")}
                                    <strong>{t("markForReviewAndNext")}</strong>
                                    {t("button")}.
                                </li>
                            </ol>
                        </li>

                        <li className="olItem">
                            {t("toChangeYourAnswerToAQuestionThatHasBeenAlreadyAnsweredSelectThatQuestionForAnsweringAndThenFollowTheProcedureForAnsweringThatTypeOfQuestion")}.
                        </li>

                        <h3 className="list-title roboto-bold">
                            {t("navigatingThroughSections")}:
                        </h3>

                        <li className="olItem">
                            {t("sectionsInThisQuestionPaperAreDisplayedOnTheTopBarOfTheScreen")}. {t("questionsInASectionCanBeViewedByClickingOnTheSectionName")}. {t("theSectionYouAreCurrentlyViewingIsHighlighted")}.
                        </li>

                        <li className="olItem">
                            {t("afterClickTheSaveAndNextButtonOnTheLastQuestionForASectionYouWillAutomaticallyBeTakenToTheFirstQuestionOfTheNextSection")}.
                        </li>

                        <li className="olItem">
                            {t("youCanShuffleBetweenSectionsAndQuestionsAnythingDuringTheExaminationAsPerYourConvenienceOnlyDuringTheTimeStipulated")}.
                        </li>

                        <li className="olItem">
                            {t("candidateCanViewTheCorrespondingSectionSummaryAsPartOfTheLegendThatAppearsInEverySectionAboveTheQuestionPalette")}.
                        </li>

                    </ol>
                </div>

                <hr></hr>

                {i18n.language === 'en' && (
                    <div className="language flex">
                        Please note all questions will appear in English ONLY.
                    </div>)}
                {
                    !infoOnly ? (
                        <>
                            <hr></hr>

                            <div className="agree-input">
                                <input type="checkbox"
                                    id="agreeCheckbox"
                                    checked={isAgreed}
                                    onChange={handleCheckboxChange}
                                /> {t("iHaveReadAndUnderstoodTheInstructionsAllComputerHardwareAllottedToMeAreInProperWorkingCondition")} {t("iDeclareThatIMNotInPossessionOfAnyProhibitedGadgetLikeMobilePhoneBluetoothDevicesEtcAnyProhibitedMaterialWithMeIntoTheExaminationHall")} {t("iAgreeThatInCaseOfNotAdheringToTheInstructionsIShallBeLiableToBeDebarredFromThisTestAndOrToDisciplinaryActionWhichMayIncludeABanFromFutureTestsExaminations")}.
                            </div>

                            <button className={`NTA-btn ${isAgreed ? 'agreed' : 'not-agreed'}`} onClick={handleProceedtoNext}>
                                {t("iAmReadyToBegin")}
                            </button>

                            {showPopup &&
                                <div className='agree-popup flex'>
                                    <button className="NTA-popup-close flex">
                                        <div>
                                            {t("info")}
                                        </div>
                                        <div >
                                            {t('closeCapitalized')} <IoMdClose className="icon" onClick={() => setShowPopup(false)} />
                                        </div>
                                    </button>
                                    <h3 className="NTA-popup-msg flex"> {t("pleaseAcceptTermsAndConditionsBeforeProceeding")}.</h3>
                                    <button className='NTA-popup-OK flex' onClick={() => setShowPopup(false)}> {t('okCapital')} </button>
                                </div>
                            }
                        </>

                    ) : (
                        <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
                    )
                }
            </div >


        </Modal >
    );
}

export default NTAInstructions;
