import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";
import './NTA.css'

import { ADMIN_USER_TYPE, securedFetchUsers } from '../../../services/UserService';
import { BsCloudUpload } from 'react-icons/bs';
import { RiArrowGoBackFill } from 'react-icons/ri';

Modal.setAppElement('#root');

const NTAFullScreenWarning = ({ isOpen, onRequestClose, handleSubmitTest, handleReenterFullScreen, user = ADMIN_USER_TYPE }) => {
    const { t } = useTranslation();
    return (
        <Modal overlayClassName="customModal" className="testModal flex" id="NTA-popup" isOpen={isOpen} onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={false} >
            <h3 className="NTA-popup-msg">
                {t("exitingFullScreenModeWillAutomaticallySubmitYourTest")}
                <br />
                {t("whatActionWouldYouLikeToTake")}
            </h3>

            <div className="NTA-popup-btns flex">
                <button className="btn flex" onClick={handleSubmitTest}>
                    {t("submitTestCapital")} <BsCloudUpload className="icon" />
                </button>

                <button className="btn flex" onClick={handleReenterFullScreen}>
                    {t("reEnterTestCapital")} <RiArrowGoBackFill className="icon" />
                </button>
            </div>
        </Modal >
    );
}

export default NTAFullScreenWarning;
