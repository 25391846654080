import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Latex from 'react-latex';
import { useTranslation } from 'react-i18next';

import "../../Questions/QuestionView/QuestionSlider/QuestionSlider.css";

import QuestionEdit from '../../Questions/QuestionCreate/QuestionEdit';
import { canCheckQuestionResponse, hasQuestionEditAuthority, hasQuestionMarkFeature, hasQuestionResponses, prefetchAnswers, securedDeleteQuestionById, securedFetchQuestionById, securedFetchSolutionByQuestionId, waitForHints } from '../../../services/QuestionService';
import { UnauthorizedError } from '../../../services/Errors';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { checkResponseCorrectness, hasQuestionMetadata, securedCreateBulkStudentQuestionResponses, securedCreateStudentQuestionResponse, securedDeleteStudentResponsesByFilter, securedFetchQuestionMetadatasByFilter, securedFetchStudentQuestionResponsesByFilter, securedUpdateQuestionMetadatas } from '../../../services/UserQuestionService';

import { BiShow, BiHide } from 'react-icons/bi';
import { AiOutlineSave } from "react-icons/ai";
import TimerButton from '../../Common/TimerButton';
import { securedCreateTestQuestionAction, securedFetchTestStudentQuestionActionsByFilter } from '../../../services/TestStudentAction';
import LoadingPage from '../../Common/LoadingPage';
import Alert from '../../Common/Alert';
import NTASubmitWarning from './NTASubmitWarning';
import { useNTATest } from './NTATestContext';
import { getHmacUrl, replaceImagesInObject } from '../../../services/CommonService';
import { MdPlaylistAdd, MdPlaylistAddCheck } from 'react-icons/md';
import { IoMdStar, IoMdStarOutline } from 'react-icons/io';
import Spinner from '../../Common/Tailwind/Spinner';

const NTAQuestionSlide = ({ questionId, paragraphId = null, basicInfo = null, basicImages = null, inputQuestionData = null, onTestQuestionResponseChange = null,
    onQuestionNext = null, onQuestionPrevious, user = STUDENT_USER_TYPE }) => {

    const options = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const [questionData, setQuestionData] = useState(null);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [correctAnswers, setCorrectAnswers] = useState(null);
    const [answerText, setAnswerText] = useState("");
    const [solution, setSolution] = useState(null);
    const [solutionStepsShown, setSolutionStepsShown] = useState(0);
    const [showSolution, setShowSolution] = useState(false);
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
    const [isShowNextHintButtonVisible, setShowNextHintButtonVisible] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isOpenAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [parentImages, setParentImages] = useState([]);
    const scrollRef = useRef(null);

    const { t } = useTranslation();

    const navigate = useNavigate();


    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        testCreateType
    } = useNTATest();

    const questionsInSection = testQuestions.filter(testquestion => testquestion.test_section_id === activeSection.id).length;

    const handleSelectionChange = (answerId) => {
        if (questionData.question_subtype === 'single choice') {
            setSelectedAnswers([answerId]);
            return;
        }
        setSelectedAnswers(prevSelected => {
            if (prevSelected.includes(answerId)) {
                return prevSelected.filter(id => id !== answerId);
            } else {
                return [...prevSelected, answerId];
            }
        });
    };

    const fetchQuestion = async (questionId, filter) => {
        let question = await securedFetchQuestionById('standalone', questionId, filter, navigateCallbackOptions(navigate));
        return question;
    }

    const fetchQuestionMetadata = async (questionId) => {
        const questionMetadata = await securedFetchQuestionMetadatasByFilter(user, 'standalone', { question_ids: [questionId] }, navigateCallbackOptions(navigate));
        if (questionMetadata === null) {
            return;
        }
        let metadata = {};
        if (questionMetadata.data.length > 0) {
            metadata = questionMetadata.data[0];
        }
        return metadata;
    }

    const makeAnswerTextSubmit = (text) => {
        if (questionData.question_subtype === 'numerical') {
            return (Math.floor(parseFloat(text) * 100) / 100);
        }
        return text;
    }

    const makeAnswerData = () => {
        const answer = {
            question_id: questionData.id,
            test_id: testData.id
        }
        if (questionData.question_subtype === 'subjective') {
            if (answerText.length > 0) {
                answer['student_question_response_answers'] = [{ text_response: makeAnswerTextSubmit(answerText) }];
            } else {
                answer['student_question_response_answers'] = [];
            }
            return answer;
        };
        if (questionData.question_subtype === 'numerical') {
            if (answerText.length > 0) {
                answer['student_question_response_answers'] = [{ numerical_response: makeAnswerTextSubmit(answerText) }];
            } else {

                answer['student_question_response_answers'] = [];
            }
            return answer;
        };
        if (selectedAnswers.length > 0) {
            answer['student_question_response_answers'] = selectedAnswers.filter(selectedAnswer => questionData.answers.map(answer => answer.answer.id).includes(selectedAnswer)).map(selectedAnswer => ({ answer_id: selectedAnswer }));
        } else {
            answer['student_question_response_answers'] = []
        }
        return answer;
    }

    const doPrefetchAnswers = () => {
        return prefetchAnswers(user) || testStatus === 'FINISHED';
    }

    const enableSolution = () => {
        return testStatus === 'FINISHED';
    }

    const getAnswerText = (studentResponse) => {
        if (!studentResponse || !studentResponse.response_answers || studentResponse.response_answers.length === 0) {
            return '';
        }
        if (studentResponse.response_answers[0].text_response != null) {
            return studentResponse.response_answers[0].text_response;
        }
        if (studentResponse.response_answers[0].numerical_response != null) {
            return studentResponse.response_answers[0].numerical_response.toString();
        }
    }

    const fetchAndSetResponses = async () => {
        if (prefetchAnswers(user)) {
            return null;
        }
        let lastAction = null;
        if (testStatus === 'FINISHED' && questionStyleById.find(questionStyle => questionStyle.question_id === questionId) != null) {
            lastAction = questionStyleById.find(questionStyle => questionStyle.question_id === questionId).last_action;
        } else {
            const filter = {};
            if (testData.id) {
                filter['test_ids'] = [testData.id];
            }
            filter['question_ids'] = [questionId];
            filter['latest_action_only'] = true;
            filter['is_visited'] = false;
            const fetchedResponses = await securedFetchTestStudentQuestionActionsByFilter(testCreateType, filter, navigateCallbackOptions(navigate));
            if (fetchedResponses === null) {
                return null;
            }
            lastAction = fetchedResponses.data.length > 0 ? fetchedResponses.data[0] : null;
        }
        if (lastAction == null || !lastAction.student_question_response) {
            setSelectedAnswers([]);
            setAnswerText('');
            return null;
        }
        const initSelectedAnswers = lastAction.student_question_response.response_answers.filter(responseAnswer => (responseAnswer.answer_id !== null)).map(responseAnswer => (responseAnswer.answer_id));

        const initAnswerText = getAnswerText(lastAction.student_question_response);
        setSelectedAnswers(initSelectedAnswers);
        setAnswerText(initAnswerText);
        return lastAction;
    }

    const needToRefreshQuestion = (inputQuestion = null) => {
        if (inputQuestion != null && (inputQuestion.answers[0].is_correct != null)) {
            return false;
        }
        if (questionData && (questionData.answers[0].is_correct != null)) {
            return false;
        }
        return doPrefetchAnswers();
    }

    const needToRefreshQuestionMetadata = () => {
        return hasQuestionMetadata(user) && testStatus === 'FINISHED';
    }

    useEffect(() => {
        (async () => {
            let question = inputQuestionData;
            if (needToRefreshQuestion(question)) {
                question = await fetchQuestion(questionId, { show_correct_answer: doPrefetchAnswers() });
            } else {
                question = { ...question, images: await replaceImagesInObject(question.images, navigate) };
            }
            if (needToRefreshQuestionMetadata()) {
                const metadata = await fetchQuestionMetadata(questionId);
                question = { ...question, metadata: metadata };
            }
            const fetchedResponse = await fetchAndSetResponses();

            if (fetchedResponse && testStatus === 'STARTED') {
                if (fetchedResponse.is_review_marked && fetchedResponse.is_saved) {
                    onTestQuestionResponseChange(questionId, 'REVIEW_AND_SAVE');
                }
                else if (fetchedResponse.is_saved) {
                    onTestQuestionResponseChange(questionId, 'SAVE');
                }
                else if (fetchedResponse.is_review_marked) {
                    onTestQuestionResponseChange(questionId, 'REVIEW');
                }
            }
            setQuestionData(question);
            setParentImages(basicImages ? (await replaceImagesInObject(basicImages, navigate)) : null);
            if (doPrefetchAnswers()) {
                const fetchedCorrectAnswerIndices = question.answers
                    .map((answer, index) => (answer.is_correct ? index : -1))
                    .filter(index => index !== -1);
                setCorrectAnswers(fetchedCorrectAnswerIndices.map(index => question.answers[index].answer.id));
            }
            setShowSolution(false);
            setSolutionStepsShown(0);
            if (testStatus === 'STARTED') {
                handleVisitQuestion(question.id);
            }
            if (scrollRef.current) {
                scrollRef.current.scrollTop = 0;
            }
        })();
    }, [questionId, inputQuestionData, testStatus]);



    const isRealNumber = (str) => {
        const allowedStarts = ["-", "+", ".", "-.", "+."];
        if (allowedStarts.includes(str)) {
            return true;
        }
        return !isNaN(str) && !isNaN(parseFloat(str));
    }

    const handleAnswerTextChange = (value, isNumeric = false) => {
        if (value === '') {
            setAnswerText('');
        }
        if (isNumeric && !isRealNumber(value)) {
            return;
        }
        setAnswerText(value);
    }

    const renderAnswer = (currentQuestionData, answer, index) => {
        let isCorrectAnswer = false;

        if (['single choice', 'multi choice'].includes(currentQuestionData.question_subtype)) {
            isCorrectAnswer = correctAnswers ? correctAnswers.includes(answer.answer.id) : false;
            return (
                <div className="option flex" id={`${!prefetchAnswers(user) && isCorrectAnswer && (isAnswerCorrect || testStatus === "FINISHED") ? "correct-answer" : ""}`}>
                    <input
                        className="answer-check"
                        type={questionData.question_subtype === 'single choice' ? 'radio' : 'checkbox'}
                        value={answer.answer.id}
                        checked={prefetchAnswers(user) ? isCorrectAnswer : selectedAnswers.includes(answer.answer.id)}
                        onChange={(event) => {
                            event.stopPropagation();
                            handleSelectionChange(answer.answer.id)
                        }}
                        disabled={prefetchAnswers(user) || (testData.id && testStatus !== 'STARTED')}
                    />
                    <label className="answer-label">({options[index]}) </label>

                    <Latex>{answer.answer.answer_text}</Latex>

                </div >
            );
        }
        if (currentQuestionData.question_subtype === 'subjective' && (!answer.answer.answer_text || answer.answer.answer_text.length === 0)) {
            return;
        }

        const inputType = currentQuestionData.question_subtype === 'numerical' ? 'number' : currentQuestionData.question_subtype === 'subjective' ? null : 'text';
        const isNumeric = (inputType === 'number');
        return (
            <div className='subjective'>
                {prefetchAnswers(user) ? (
                    <Latex>{isNumeric ? (answer.answer.min_value === answer.answer.max_value ? (`${answer.answer.min_value}` || '') : `Between ${answer.answer.min_value} to ${answer.answer.max_value}`) : (answer.answer.answer_text || '')}</Latex>
                ) : (
                    inputType !== null && (
                        <input
                            type='text'
                            value={answerText}
                            inputMode={isNumeric ? 'numeric' : 'text'}
                            onChange={(e) => handleAnswerTextChange(e.target.value, isNumeric)}
                            disabled={prefetchAnswers(user) || (testData.id && testStatus !== 'STARTED')}
                            placeholder="0.00"
                            aria-describedby="float-number"
                            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />)
                    // <input
                    //     className="subjective-input"
                    //     type='text'
                    //     value={answerText}
                    //     inputMode={isNumeric ? 'numeric' : 'text'}
                    //     onChange={(e) => handleAnswerTextChange(e.target.value, isNumeric)}
                    //     disabled={prefetchAnswers(user) || (testData.id && testStatus !== 'STARTED')}
                    // />)
                )}
                {!prefetchAnswers(user) && correctAnswers && questionData.answers[0].answer.answer_text != null ? (<div className="subjective-given-answer"><Latex>{`The answer is ${questionData.answers[0].answer.answer_text || ''}`}</Latex></div>) : <></>}
                {!prefetchAnswers(user) && correctAnswers && questionData.answers[0].answer.min_value != null && questionData.answers[0].answer.min_value !== questionData.answers[0].answer.max_value ? (<div className="subjective-given-answer"><Latex>{`The answer is between ${questionData.answers[0].answer.min_value || ''} and ${questionData.answers[0].answer.max_value || ''}`}</Latex></div>) : <></>}
                {!prefetchAnswers(user) && correctAnswers && questionData.answers[0].answer.min_value != null && questionData.answers[0].answer.min_value === questionData.answers[0].answer.max_value ? (<div className="subjective-given-answer"><Latex>{`The answer is ${questionData.answers[0].answer.min_value || ''}`}</Latex></div>) : <></>}
            </div>
        )
    }

    const renderSolutionButton = () => {
        if (!showSolution) {
            return (
                <button className="solution-btn flex" onClick={handleCheckSolution}>
                    <BiShow className="icon" />
                    {waitForHints(user) ? 'Show Hint' : 'Show Solution'}
                </button>
            );
        } else {
            return (
                <button className="solution-btn flex" onClick={handleHideSolution}>
                    <BiHide className="icon" />
                    Hide
                </button>
            );
        }
    }

    const handleCheckNextHint = () => {
        setSolutionStepsShown(solutionStepsShown + 1);
        setShowNextHintButtonVisible(false);
    }

    const handleTimerEnd = () => {
        setShowNextHintButtonVisible(true);
    }

    const renderSolutionSteps = () => {
        if (!solution.solution_steps) {
            return;
        }
        return (
            <>
                {solution.solution_steps && (solution.solution_steps.map((text, index) =>
                    renderSolutionStep(index)
                ))}
                {solution.solution_steps.length > solutionStepsShown && (
                    <button className="timer-btn" onClick={handleCheckNextHint}>
                        {t('showNextHint')}
                    </button>)}
            </>);
    }

    const renderSolution = () => {

        if (!showSolution) {
            return;
        }
        if (!solution || (solution.solution_text.length === 0 && solution.images.length === 0)) {
            return (
                <div className="solution-text flex" >
                    {t('solutionNotProvidedForThisQuestion')}
                </div>
            );
        }
        return (
            <>
                {renderSolutionSteps()}
                {(!solution.solution_steps || solution.solution_steps.length === solutionStepsShown) && (
                    <>
                        <div className="solution-step">SOLUTION</div>
                        <div className="solution-text flex">
                            <Latex>{solution.solution_text}</Latex>
                        </div>

                        {solution.images && (
                            <div className="question-image solution-image flex">
                                {solution.images.map((img, index) => (
                                    <img key={index} src={img.image.url} alt={t('solutionRelated')} draggable={false} />
                                ))}
                            </div>)}
                    </>
                )}
            </>
        )
    }

    const renderSolutionStep = (index) => {

        if (index >= solutionStepsShown) {
            return;
        }

        return (
            <>
                <div className="solution-step">STEP {index + 1}</div>
                <div className="solution-text">
                    <Latex>{solution.solution_steps[index].step_text}</Latex>
                </div>
            </>
        );
    }

    const handleVisitQuestion = async (currentQuestionId) => {
        const data = {
            test_id: testData.id,
            question_id: currentQuestionId,
            is_visited: true
        }
        const markVisited = await securedCreateTestQuestionAction(testCreateType, testData.id, data, navigateCallbackOptions(navigate));
        if (markVisited === null) {
            return;
        }
        if (onTestQuestionResponseChange) {
            onTestQuestionResponseChange(questionId, 'VISIT');
        }
    }

    const handleSaveResponse = async (mark_for_review = false, save = false) => {

        setIsSaving(true);
        let responseAnswer = makeAnswerData();
        if (!responseAnswer['student_question_response_answers']) {
            setIsSaving(false);
            return;
        }
        if (responseAnswer['student_question_response_answers'].length === 0) {
            if (save === true) {
                setAlertMessage('Answer not provided for question!');
                setOpenAlert(true);
                setIsSaving(false);
                return;
            }
            else {
                responseAnswer = null;
            }
        }
        else {
            if (mark_for_review === true) {
                save = true;
            }
        }

        const studentQuestionResponseData = renderAnswer === null ? {} : { student_question_response: responseAnswer }
        const testActionData = { is_review_marked: mark_for_review, is_saved: save, test_id: testData.id, question_id: questionData.id, ...studentQuestionResponseData };
        const response = await securedCreateTestQuestionAction(testCreateType, testData.id, testActionData, navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSaving(false);
            return;
        }
        if (onTestQuestionResponseChange) {
            if (mark_for_review && save) {
                onTestQuestionResponseChange(questionId, 'REVIEW_AND_SAVE');
            }
            else if (save) {
                onTestQuestionResponseChange(questionId, 'SAVE');
            }
            else if (mark_for_review) {
                onTestQuestionResponseChange(questionId, 'REVIEW');
            }
        }
        setIsSaving(false);
        if (onQuestionNext) {
            onQuestionNext();
        }
    }

    const handleUncheckResponse = async () => {
        setIsSaving(true);
        const data = {
            test_id: testData.id,
            question_id: questionData.id,
            is_cleared: true
        }
        const response = await securedCreateTestQuestionAction(testCreateType, testData.id, data, navigateCallbackOptions(navigate));
        if (response === null) {
            setIsSaving(false);
            return;
        }
        setSelectedAnswers([]);
        setAnswerText('');
        if (onTestQuestionResponseChange) {
            onTestQuestionResponseChange(questionId, 'CLEAR');
        }
        setIsSaving(false);
    }

    const handleCheckSolution = async () => {
        const loadedSolution = await loadSolutionIfNotLoaded();
        if (solutionStepsShown === 0) {
            if (waitForHints(user)) {
                setSolutionStepsShown(Math.min(solutionStepsShown + 1, loadedSolution.solution_steps.length));
            }
            else {
                setSolutionStepsShown(loadedSolution.solution_steps.length);
            }
        }
        setShowSolution(true);
    }


    const loadSolutionIfNotLoaded = async (force = false) => {
        if (!solution || force || solution.question_id !== questionId) {
            const response = await securedFetchSolutionByQuestionId(questionData.id, navigateCallbackOptions(navigate));
            if (response === null) {
                return;
            }
            setSolution(response);
            setSolutionStepsShown(0);
            return response;
        }
        return solution;
    }

    const handleHideSolution = async () => {
        setShowSolution(false);
    }

    const showId = () => {
        if (questionData.topics.length === 0) return '';

        return `${questionData.question_type === 'paragraph' ? 'Q-' : ''}${questionData.topics[0].topic.subject.subject_name[0]}${questionData.id}`;
    };

    const showParagraphId = () => {
        if (questionData.topics.length === 0) return '';

        return `${questionData.topics[0].topic.subject.subject_name[0]}${paragraphId}`;
    };

    const handleMarkStar = async (event) => {
        event.stopPropagation();
        const newIsStarMarked = questionData.metadata.is_star_marked ? false : true;
        const updateMarkResponse = await securedUpdateQuestionMetadatas(user, 'standalone', { is_star_marked: newIsStarMarked, question_id: questionData.id }, navigateCallbackOptions(navigate));
        if (updateMarkResponse === null) {
            return;
        }
        const newMetadata = questionData.metadata ? { ...questionData.metadata, is_star_marked: newIsStarMarked } : null;
        setQuestionData({ ...questionData, metadata: newMetadata });
    }

    const handleMarkForReview = async (event) => {
        event.stopPropagation();
        const newIsMarkedForReview = questionData.metadata.is_review_marked ? false : true;
        const updateMarkResponse = await securedUpdateQuestionMetadatas(user, 'standalone', { is_review_marked: newIsMarkedForReview, question_id: questionData.id }, navigateCallbackOptions(navigate));
        if (updateMarkResponse === null) {
            return;
        }
        const newMetadata = questionData.metadata ? { ...questionData.metadata, is_review_marked: newIsMarkedForReview } : null;
        setQuestionData({ ...questionData, metadata: newMetadata });
    }


    const renderQuestionMetadata = () => {
        if (testStatus !== 'FINISHED' || !hasQuestionMarkFeature(user) || !questionData.metadata) {
            return;
        }
        return (
            <div className="question-saved" >
                <>
                    <button className="saved-btn" id="star-btn" onClick={handleMarkStar}>
                        {questionData.metadata && questionData.metadata.is_star_marked ? (<IoMdStar className="icon" />) : (<IoMdStarOutline className="icon" />)}
                    </button>

                    <button className="saved-btn" id="review-btn" onClick={handleMarkForReview}>
                        {questionData.metadata && questionData.metadata.is_review_marked ? (<MdPlaylistAddCheck className="icon" />) : (<MdPlaylistAdd className="icon" />)}
                    </button>
                </>
            </div>);
    }

    if (!questionData) {
        return <Spinner />;  // Or some other loading indicator
    }

    if (isSaving) {
        return <LoadingPage type='save' />
    }

    return (
        <>
            <div ref={scrollRef} className="NTAQuestionSlide flex">
                {paragraphId != null &&
                    (<div className="question-info flex">
                        {questionData.topics.length > 0 && testStatus === 'FINISHED' && paragraphId != null && (
                            <span className="question-id" id={questionData.topics[0].topic.subject.subject_name}> {showParagraphId()} </span>
                        )}
                        {renderQuestionMetadata()}
                    </div>)}
                {basicInfo && (
                    <div className="question-text">
                        <Latex>{basicInfo}</Latex>
                    </div>
                )}

                {questionData.topics.length > 0 && testStatus === 'FINISHED' && (
                    <div className="question-info flex">
                        <span className="question-id" id={questionData.topics[0].topic.subject.subject_name}> {showId()} </span>
                        {paragraphId === null && (renderQuestionMetadata())}
                    </div>
                )}

                {parentImages && (
                    <div className="question-image">
                        {parentImages.map((img, index) => (
                            <img key={index} src={img.image.url} alt={t('questionRelated')} />
                        ))}
                    </div>
                )}

                {/* Displaying the Question Text */}
                {questionData.question_text && (
                    <div className="question-text">
                        <Latex>{questionData.question_text}</Latex>
                    </div >
                )}

                {/* Displaying the Question Images */}
                {(questionData.images.length > 0) && (
                    <div className={questionData.question_text.length === 0 ? 'question-image-only' : 'question-image'}>
                        {questionData.images.map((img, index) => (
                            <img key={index} src={img.image.url} alt={t('questionRelated')} />
                        ))}
                    </div>
                )}

                {/* Displaying the Answers */}
                {(
                    <div className="NTAanswers flex">
                        {questionData.answers.map((answer, index) => (
                            <span key={answer.answer.id} className="answer-container">
                                {renderAnswer(questionData, answer, index)}
                            </span>
                        ))}
                    </div>
                )}

                {/* During Test */}
                {
                    !prefetchAnswers(user) && testStatus === 'STARTED' && (
                        <div className="NTAAnswerResponse flex">

                            <div className="NTAbtns flex">
                                <button className="NTAbtn roboto-bold flex" onClick={async () => await handleSaveResponse(true, false)}>
                                    Mark for Review & Next
                                </button>

                                <button className="NTAbtn roboto-bold flex" onClick={handleUncheckResponse}>
                                    Clear Responses
                                </button>
                            </div>

                            <div className="NTAbtns flex">


                                <button className="NTAbtn roboto-bold flex" onClick={onQuestionPrevious}>
                                    Previous
                                </button>



                                <button className="NTAbtn roboto-bold flexx" onClick={async () => await handleSaveResponse(false, true)}>
                                    Save & Next
                                </button>


                            </div>
                        </div>
                    )
                }

                {/* Show Solution Only When The Answer is Correct  */}
                {
                    enableSolution()
                    && (
                        <div className="solution flex">
                            {renderSolutionButton()}
                            {renderSolution()}
                        </div>
                    )
                }

            </div >
            <Alert isOpen={isOpenAlert} onRequestClose={() => setOpenAlert(false)} className={'testModal'} message={alertMessage} user={user} parentId={'NTA-tests-glass'} />
        </>
    )
};

export default NTAQuestionSlide;
