import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

import '../Test.css'

import bkgImg from '../../../Assets/Test.png'

import SelectionDropdown from '../../Common/SelectionDropdown';
import TestHeader from '../TestHeader';
import TeacherTestView from './TeacherTestView';
import { TEST_CREATE_TYPE_BASIC, securedFetchAttemptedTests, securedFetchTestStudents, securedFetchTests } from '../../../services/TestService';
import { TEACHER_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import Spinner from '../../Common/Tailwind/Spinner';
import PageHeader from '../../Common/Tailwind/Page/PageHeader';

const TeacherTestPage = ({ user = TEACHER_USER_TYPE }) => {
    const [finishedTests, setFinishedTests] = useState([]);
    const [upcomingTests, setUpcomingTests] = useState([]);
    const [currentTests, setCurrentTests] = useState([]);
    const [selectedFinishedTest, setSelectedFinishedTest] = useState(null);
    const [selectedUpcomingTest, setSelectedUpcomingTest] = useState(null);
    const [selectedCurrentTest, setSelectedCurrentTest] = useState(null);
    const [testStatus, setTestStatus] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const fetchAllTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, { test_status: 'not_started', test_types: ["full", "phase", "topic"] }, navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        return allTestsResponse.data;
    };

    const fetchFinishedTests = async () => {
        const finishedTestsResponse = await securedFetchAttemptedTests({ test_status: 'finished' });
        if (finishedTestsResponse === null) {
            return null;
        }
        return finishedTestsResponse.data;
    }

    const fetchCurrentTests = async () => {
        const allTestsResponse = await securedFetchTests(TEST_CREATE_TYPE_BASIC, { test_status: 'in_progress', test_types: ["full", "phase", "topic"] }, navigateCallbackOptions(navigate));
        if (allTestsResponse === null) {
            return null;
        }
        return allTestsResponse.data;
    }

    const onRefreshTest = () => {
        setRefreshKey(prevKey => prevKey + 1);
    }

    useEffect(() => {
        (async () => {
            const fetchedUpcomingTests = await fetchAllTests();
            if (fetchedUpcomingTests === null) {
                return;
            }
            setUpcomingTests(fetchedUpcomingTests);
            const fetchedFinishedTests = await fetchFinishedTests();
            if (fetchedFinishedTests === null) {
                return;
            }
            setFinishedTests(fetchedFinishedTests);
            const fetchedCurrentTests = await fetchCurrentTests();
            if (fetchCurrentTests === null) {
                return;
            }
            setCurrentTests(fetchedCurrentTests);

        })();
    }, []);

    const onUpcomingTestSelectionChange = (selection) => {
        setLoading(true);
        const testId = selection ? selection.value : null;
        if (testId) {
            setSelectedFinishedTest(null);
            setSelectedCurrentTest(null);
            setSelectedUpcomingTest({ id: selection.value, test_name: selection.label });
            setLoading(false);
        }
    };

    const onFinishedTestSelectionChange = (selection) => {
        setLoading(true);
        const testId = selection ? selection.value : null;
        if (testId) {
            setSelectedCurrentTest(null);
            setSelectedUpcomingTest(null);
            setSelectedFinishedTest({ id: selection.value, test_name: selection.label });
            setLoading(false);
        }
    };

    const onCurrentTestSelectionChange = (selection) => {
        setLoading(true);
        const testId = selection ? selection.value : null;
        if (testId) {
            setSelectedFinishedTest(null);
            setSelectedUpcomingTest(null);
            setSelectedCurrentTest({ id: selection.value, test_name: selection.label });
            setLoading(false);
        }
    };

    const renderTest = () => {

        const test = selectedFinishedTest || selectedUpcomingTest || selectedCurrentTest;

        if (test && test.id) {
            return (
                <div className="tests-glass flex">
                    {loading ? (
                        <Spinner />
                    ) : (
                        <>
                            <TestHeader testData={test} testStatus={testStatus} setTestStatus={setTestStatus} user={user} refreshKey={refreshKey} onRefreshClick={onRefreshTest} />
                            {testStatus !== null && testStatus !== 'NOT STARTED' &&
                                (<TeacherTestView id={test.id} user={user} onTestEnd={onTestEnd} testStatus={testStatus} refreshKey={refreshKey} />)
                            }
                        </>
                    )}
                </div>
            );
        } else {
            return (
                <div className="tests-glass flex" >
                    <h3 className="title">Please select the type, the series and the name of test.</h3>
                    <img src={bkgImg} alt="" className="bkgImg" />
                </div>
            );
        }
    }

    const onTestEnd = () => {
        setSelectedCurrentTest(null);
    }

    return (

        <div className="relative w-full h-full flex flex-col items-center p-4 gap-2 sm:gap-3 md:gap-4  text-[var(--textColor)] animate-fadeUp">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t("perfectYourTechniquesByPracticingWithJEETA")} /> */}

            <div className="tests flex">

                <div className="tests-filter flex">
                    <div className="filters">
                        <SelectionDropdown className="testSelectionDropdown" name="Finished Tests" itemList={finishedTests} selectedItem={selectedFinishedTest} onSelectionChange={onFinishedTestSelectionChange} nameField='test_name' valueField='id' />
                        <SelectionDropdown className="testSelectionDropdown" name="Upcoming Tests" itemList={upcomingTests} selectedItem={selectedUpcomingTest} onSelectionChange={onUpcomingTestSelectionChange} nameField='test_name' valueField='id' />
                        <SelectionDropdown className="testSelectionDropdown" name="Started Tests" itemList={currentTests} selectedItem={selectedCurrentTest} onSelectionChange={onCurrentTestSelectionChange} nameField='test_name' valueField='id' />
                    </div>
                </div>

                {renderTest()}

            </div>

        </div>
    );
}

export default TeacherTestPage;
