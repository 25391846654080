import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import './slotview.css';
import Doubts from '../../Assets/Doubts.png'
import { securedFetchLectureVideoById, securedGetTimetableSlotById, securedUpdateSlotByIdEndTime, securedUpdateSlotByIdStartTime } from '../../services/TimetableService';
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';
import { hasSlotEndAuthority, hasSlotStartAuthority } from '../../services/TestService';
import { BsRecordCircle } from 'react-icons/bs';
import { hasLiveDoubtCreateAuthority, securedCreateDoubt, securedFetchDoubtsByFilter } from '../../services/DoubtService';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'

import MessagePage from '../Common/Tailwind/Page/MessagePage';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';

const SlotLiveDoubts = ({ lectureData, user = ADMIN_USER_TYPE }) => {

    const [currentDoubts, setCurrentDoubts] = useState([]);
    const [canAddDoubt, setCanAddDoubt] = useState(lectureData.status === 'in_progress');
    const [newDoubt, setNewDoubt] = useState('');
    const navigate = useNavigate();

    const fetchAndSetDoubts = async () => {
        const doubtsResponse = await securedFetchDoubtsByFilter({ timetable_slot_ids: [lectureData.id] }, navigateCallbackOptions(navigate));
        setCurrentDoubts(doubtsResponse.data);
    }

    useEffect(() => {
        (async () => {
            await fetchAndSetDoubts();
        })();
    }, []);

    const handleSubmitDoubt = async (event) => {
        event.preventDefault();
        const addDoubtResponse = await securedCreateDoubt({ timetable_slot_id: lectureData.id, doubt_text: newDoubt }, navigateCallbackOptions(navigate));
        if (addDoubtResponse === null) {
            setCanAddDoubt(false);
        }
        await fetchAndSetDoubts();
        setNewDoubt('');
    };

    const renderDoubt = (doubtData) => {
        return (
            <div key={doubtData.doubt_text} className="pt-6">
                <div>
                    <div className="group flex w-full items-start justify-between text-left text-gray-900">
                        <span className="max-w- text-base font-semibold leading-7">{doubtData.doubt_text}</span>
                        <span className="ml-6 flex h-7 items-center">
                            {doubtData.doubt_timestamp.split('T')[1].split('.')[0]}
                        </span>
                    </div>
                </div>
            </div>
        );

    }

    return (
        <>
            <div className="slotLiveDoubts flex flex-col justify-center">

                {currentDoubts.length > 0 ? (
                    <div className="w-full max-w-7xl px-2 py-6 sm:py-8 lg:px-4 lg:py-10">
                        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">

                            <h2 className="text-base md:text-lg lg:text-xl font-semibold leading-10 tracking-tight text-[var(--SecondaryColor)]">
                                {currentDoubts.length} Doubt{currentDoubts.length > 1 ? "s" : ""}
                            </h2>
                            <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">

                                {currentDoubts.map((doubt, index) => (
                                    renderDoubt(doubt, index)
                                ))}
                            </dl>

                        </div>
                    </div>
                ) : (
                    <MessagePage imgsrc={Doubts} title={"NO doubts"} message1={"There is"} message2={""} message3={""} />
                    // <p className="no-live-doubts-msg">There is no doubts.</p>
                )}
                {/* 
                <div className="w-full max-w-7xl px-2 py-6 sm:py-8 lg:px-4 lg:py-10">
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                        <h2 className="text-base md:text-lg lg:text-xl font-semibold leading-10 tracking-tight text-[var(--SecondaryColor)]">JEETA created {doubtClusters.length} doubt cluster{doubtClusters.length > 1 ? "s" : ""}.</h2>
                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                            {doubtClusters.map((doubtCluster, index) => (
                                <DoubtClusterCard doubtClusterData={doubtCluster} user={user} />
                            ))}
                        </dl>
                    </div>
                </div> */}

                {(hasLiveDoubtCreateAuthority(user) && canAddDoubt) ? (
                    <form className="flex flex-col gap-4 items-center w-full max-w-5xl px-2 py-3 sm:py-4 lg:px-4 lg:py-5" onSubmit={handleSubmitDoubt}>

                        <textarea className="block mx-auto w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            rows="6"
                            value={newDoubt}
                            onChange={(e) => setNewDoubt(e.target.value)}
                            placeholder="Remember, there are no ' silly' questions in this classroom - your curiosity is valued and asking questions is the key to deepening your understanding. Don't hesitate to speak up and ask any doubts you may have; your voice matters and we're all here to learn together." />

                        <button
                            type="button"
                            onClick={handleSubmitDoubt}
                            className="ml-4 inline-flex justify-center gap-1.5 items-center rounded-md bg-[var(--PrimaryColor)] px-2 py-1 sm:px-3 sm:py-1.5 md:px-4 md:py-2 text-sm sm:text-md md:text-base lg:text-lg font-semibold text-white shadow-sm hover:text-white hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer transition-all duration-200"
                        >
                            Submit
                            <CloudArrowUpIcon aria-hidden="true" className="h-6 w-6" />
                        </button>

                    </form>
                ) : (
                    <>
                        {lectureData.status === 'not_started' ?
                            <MessagePage imgsrc={Doubts} title={"NOT started"} message1={"Class has"} message2={"yet"} message3={""} />
                            :
                            <MessagePage imgsrc={Doubts} title={"already finished"} message1={"Class has"} message2={""} message3={""} />
                        }
                    </>
                )}
                {/* <button
                    type="button"
                    onClick={handleFetchDoubtClusters}
                    className="ml-4 inline-flex justify-center gap-1.5 items-center rounded-md bg-transparent px-3 py-2 tracking-tight text-lg md:text-xl lg:text-2xl font-bold text-[var(--PrimaryColor)] shadow-sm hover:text-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                        cursor-pointer">
                    Doubt Cluster
                    <ArrowPathIcon aria-hidden="true" className="h-6 w-6" />
                </button>

                <div className="w-full max-w-7xl px-2 py-6 sm:py-8 lg:px-4 lg:py-10">
                    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                        <h2 className="text-base md:text-lg lg:text-xl font-semibold leading-10 tracking-tight text-[var(--SecondaryColor)]">JEETA created {doubtClusters.length} doubt cluster{doubtClusters.length > 1 ? "s" : ""}.</h2>
                        <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                            {doubtClusters.map((doubtCluster, index) => (
                                <DoubtClusterCard doubtClusterData={doubtCluster} user={user} />
                            ))}
                        </dl>
                    </div>
                </div> */}

            </div>
        </>
    );
}

export default SlotLiveDoubts;
