import React from 'react'

import bkgImage from '../../Assets/Analytics.png'

import Navbar from '../../Components/Navbar/Navbar';
import Hero from '../../Components/Hero/Hero';
import Footer from '../../Components/Footer/Footer';
import Analytics from '../../Components/Analysis/Analytics';

import { TeacherNavItems } from './TeacherNavItems';
import { TEACHER_USER_TYPE } from '../../services/UserService';

const TeacherAnalytics = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={TeacherNavItems} user={TEACHER_USER_TYPE} />
            {/* <Hero
                cName="hero"
                img={bkgImage}
                title="Analytics"
                description="Provide Personalized Guidance for Your Stuents!"
            /> */}
            <Analytics user={TEACHER_USER_TYPE} />
            <Footer user={TEACHER_USER_TYPE} />
        </div>
    )
};

export default TeacherAnalytics;