import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import './profile.css'

import profileImage from '../../Assets/Profile_photo.png';

import ChangePasswordForm from "./ChangePasswordForm";
import { ADMIN_USER_TYPE, hasAdminRoleInfo, hasBatchInfo, hasSubjectInfo, securedFetchUserById, securedFetchUsers } from "../../services/UserService";
import { navigateCallbackOptions, securedLogoutUser } from "../../services/AuthenticationService";

import { AiOutlineEdit } from 'react-icons/ai';
import { RiLockPasswordLine } from 'react-icons/ri';
import { SlLogout } from 'react-icons/sl';
import { securedFetchBatchesByFilter } from "../../services/BatchService";

const UserProfile = ({ user = ADMIN_USER_TYPE }) => {

    const [userInfo, setUserInfo] = useState(null);
    const [isChangePasswordOpen, setChangePasswordOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const fetchedUserInfo = await securedFetchUserById(user, navigateCallbackOptions(navigate));
            setUserInfo(fetchedUserInfo);
        })();
    }, []);

    const onLogout = async () => {
        const response = await securedLogoutUser(navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        navigate('/');
    }

    const makeBatchString = (student) => {
        return student.batches.filter(batch => batch.end_time === null).map(batch => (batch.batch.batch_name)).join(', ');
    }

    return (

        <div className="relative w-full h-full flex flex-col items-center p-4 gap-4 text-[var(--textColor)] animate-fadeUp">

            <div className="profile flex">

                <img className="userPhoto" src={profileImage} alt="user-photo" />

                <div className="userProfile flex">
                    {userInfo && userInfo.user_data && (
                        <>
                            <div className="userInfo flex">
                                <label className="infoLabel">Full Name </label>
                                <span className="infoContent flex">
                                    <span><strong>{userInfo.user_data.first_name + ' ' + userInfo.user_data.last_name}</strong></span>
                                    {/* <AiOutlineEdit className="icon" /> 
                                */}
                                </span>
                            </div>

                            <div className="userInfo flex">
                                <label className="infoLabel">Email </label>
                                <div className="infoContent flex">
                                    <span>{userInfo.user_data.email}</span>
                                    {/* <AiOutlineEdit className="icon" /> */}
                                </div>

                            </div>

                            <div className="userInfo flex">
                                <label className="infoLabel">Phone Number </label>
                                <div className="infoContent flex">
                                    <span>{userInfo.user_data.phone_number}</span>
                                    {/* <AiOutlineEdit className="icon" /> */}
                                </div>
                            </div>
                        </>
                    )}
                    {userInfo && hasSubjectInfo(user) &&
                        <div className="userInfo flex">
                            <label className="infoLabel">Subjects </label>
                            <div className="infoContent flex">
                                <span>{userInfo.subjects.map(subject => subject.subject.subject_name).join(', ')}</span>
                                {/* <AiOutlineEdit className="icon" /> */}
                            </div>
                        </div>
                    }
                    {userInfo && hasBatchInfo(user) &&
                        <div className="userInfo flex">
                            <label className="infoLabel">Batches </label>
                            <div className="infoContent flex">
                                <span>{makeBatchString(userInfo)}</span>
                                {/* <AiOutlineEdit className="icon" /> */}
                            </div>
                        </div>
                    }
                    {userInfo && hasAdminRoleInfo(user) &&
                        <div className="userInfo flex">
                            <label className="infoLabel">Role </label>
                            <div className="infoContent flex">
                                <span>{userInfo.admin_role}</span>
                                {/* <AiOutlineEdit className="icon" /> */}
                            </div>
                        </div>
                    }

                    <div className="profileBtns flex">
                        <button className="btn flex" onClick={() => setChangePasswordOpen(true)}>
                            Change Password <RiLockPasswordLine className="icon" />
                        </button>
                        <ChangePasswordForm isOpen={isChangePasswordOpen} onRequestClose={() => setChangePasswordOpen(false)} user={user} />
                    </div>

                </div>

            </div>

        </div>
    )
};

export default UserProfile;
