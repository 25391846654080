import React from 'react'
import { useState, useEffect } from "react";

import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer';
import { ADMIN_USER_TYPE, TEACHER_USER_TYPE } from '../../services/UserService';
import Syllabus from '../../Components/Syllabus/Syllabus';
import { AdminNavItems } from './AdminNavItems';

const AdminSyllabus = () => {
    return (
        <div className="flex flex-col min-h-screen">

            <Navbar navItems={AdminNavItems} user={ADMIN_USER_TYPE} />

            <div className="flex flex-1 justify-center items-center">
                <Syllabus user={ADMIN_USER_TYPE} />
            </div>

            <footer className="sticky bottom-0 w-full">
                <Footer />
            </footer>
        </div>
    )
}

export default AdminSyllabus;