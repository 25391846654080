import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import './RecordedVideoCard.css'

import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';

import { BiFilterAlt, BiMessageSquareAdd } from 'react-icons/bi'
import { securedFetchTagsByFilter } from '../../../services/TagService';
import RecordedVideoCreate from '../VideoCreate/RecordedVideoCreate';
import { RECORDED_VIDEO_UPLOAD_TYPES, hasRecordedVideoCreateAuthority } from '../../../services/VideoService';
import TailwindButton from '../../Common/Tailwind/TailwindButton';
import { TbLockAccess } from 'react-icons/tb';
import VideoRequests from '../../Requests/VideoRequests';
import VideoRequestsPopup from '../../Requests/VideoRequestsPopup';

const RecordedVideoCardListFilter = ({ syllabusFilter = null, onSubmitFilter = null, hiddenFilters = null, user = ADMIN_USER_TYPE }) => {

    const [isRecordedVideoCreateModalOpen, setRecordedVideoCreateModalOpen] = useState(false);
    const [isAccessRequestsOpen, setAccessRequestsOpen] = useState(false);
    const [nameSubstring, setNameSubstring] = useState('');
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const onFilterClick = async () => {
        const filter = {
            ...syllabusFilter,
            selectedTags,
            selectedTypes,
            nameSubstring
        };
        if (onSubmitFilter) {
            await onSubmitFilter(filter);
        }
    }

    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 780);

    useEffect(() => {
        const handleResize = () => {
            setIsNarrowScreen(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!hiddenFilters || hiddenFilters.tags !== 'hide') {
                const tagFilter = user === ADMIN_USER_TYPE ? null : { is_hidden: false }
                const tagsResponse = await securedFetchTagsByFilter(tagFilter, navigateCallbackOptions(navigate));
                if (tagsResponse === null) {
                    return;
                }
                setTags(tagsResponse.data);
            }
        })();
    }, []);

    const onSelectedTagToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(selectedTagsFromSelect);
    };

    const onSelectedTypeToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTypesFromSelect = RECORDED_VIDEO_UPLOAD_TYPES.filter(type => selectedIds.includes(type.id));
        setSelectedTypes(selectedTypesFromSelect);
    };

    return (
        <div className="recordedVideoCardListFilter flex">

            {/* <div className="filterTitle flex"> */}

            {/* <h3 className="title"> Dive Deep into Learning: </h3> */}

            {/* {user === ADMIN_USER_TYPE ?
                    <span>{t('exploreAndMaster')} {isNarrowScreen ? <br /> : ""}{t('yourVideoDatabase')}</span>
                    :
                    <span>{t('everyVideoRevision')} {isNarrowScreen ? <br /> : ""}{t('strengthensYourFoundation')}</span>
                } */}
            {/* </div> */}
            <div className="filters">
                {(!hiddenFilters || hiddenFilters.tags !== 'hide') && (<SelectionDropdownMultiple className='filterSelect' name={t('tags')} itemList={tags} selectedItems={selectedTags} onSelectionToggle={onSelectedTagToggle} nameField='tag_name' valueField='id' isSearchable={true} />)}
                {(!hiddenFilters || hiddenFilters.types !== 'hide') && (<SelectionDropdownMultiple className='filterSelect' name={t('types')} itemList={RECORDED_VIDEO_UPLOAD_TYPES} onSelectionToggle={onSelectedTypeToggle} selectedItems={selectedTypes} nameField='name' valueField='id' />)}
                {(!hiddenFilters || hiddenFilters.nameSubstring !== 'hide') && (<div className="searchTextInput flex">
                    <label className="title">{t('name')}</label>
                    <input type="text" value={nameSubstring} onChange={e => setNameSubstring(e.target.value)} placeholder={t('nameOfVideo')} />
                </div>)}
            </div>

            <div className="btns flex">
                <button className="btn flex" id="filter" onClick={onFilterClick}>{t('filter')} <BiFilterAlt className="icon" /> </button>
                {!hasRecordedVideoCreateAuthority(user) ? (<></>) : (
                    <>
                        <span> {t('or')}</span>
                        <button className="btn flex" id="create" onClick={() => setRecordedVideoCreateModalOpen(true)} >
                            {t('createCapitalized')} <BiMessageSquareAdd className="icon" />
                        </button>
                        <span> {t('or')}</span>
                        <TailwindButton
                            text={t('checkRequests')}
                            iconComponent={TbLockAccess}
                            onClick={() => setAccessRequestsOpen(true)} />
                    </>
                )}
            </div>

            <RecordedVideoCreate
                isOpen={isRecordedVideoCreateModalOpen}
                onRequestClose={() => setRecordedVideoCreateModalOpen(false)}
                user={user}
                initialSubjects={syllabusFilter.subjects}
                initialSelectedSubject={syllabusFilter.selectedSubjects.length > 0 ? syllabusFilter.selectedSubjects[0] : {}}
                initialGrades={syllabusFilter.grades}
                initialSelectedGrade={syllabusFilter.selectedGrades.length > 0 ? syllabusFilter.selectedGrades[0] : {}}
                initialSelectedTopics={syllabusFilter.selectedTopics}
            />

            <VideoRequestsPopup isOpen={isAccessRequestsOpen} onRequestClose={() => setAccessRequestsOpen(false)} zIndex={9} types={['recorded_lecture']} refresh={isAccessRequestsOpen} user={user} />

        </div>
    );
};

export default RecordedVideoCardListFilter;