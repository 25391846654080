import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./studentTest.css";

import StudentTestPage from "./StudentTestPage";
import StudentPracticeTestPage from "./StudentPracticeTestPage";
import StudentVirtualTestPage from "./StudentVirtualTestPage";
import StudentSelectTestPageSidebar from "./Sidebar/StudentSelectTestPageSidebar";
import { STUDENT_USER_TYPE } from "../../../services/UserService";
import PageHeader from "../../Common/Tailwind/Page/PageHeader";

const StudentSelectTestPage = ({ pathTab = null, user = STUDENT_USER_TYPE }) => {

    const [selectedTab, setSelectedTab] = useState(pathTab === null ? "Common" : pathTab);
    const [isTabletView, setIsTabletView] = useState(window.innerWidth > 760);

    const { t } = useTranslation();
    const renderTestPageContent = () => {
        return (
            <div className="studentSelectTestPage-main flex">

                {isTabletView ? (
                    <>
                        {(selectedTab === 'Common') ? (
                            <StudentTestPage />
                        ) : ((selectedTab === 'Virtual') ? (
                            <StudentVirtualTestPage />
                        ) : (
                            <StudentPracticeTestPage />
                        ))}
                    </>
                ) : (
                    <h3>{t('pleaseOpenTestInPCOrTablet')}</h3>
                )}

            </div>
        )
    }

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 760) {
                setIsTabletView(true);
            } else {
                setIsTabletView(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <div className="relative w-full h-full flex flex-col items-center p-4 gap-2 sm:gap-3 md:gap-4  text-[var(--textColor)] animate-fadeUp">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t("perfectYourTechniquesByPracticingWithJEETA")} /> */}

            <div className="tests flex">
                {/* Sidebar */}

                <div className="studentSelectTestPage">
                    <StudentSelectTestPageSidebar
                        user={user}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                    />
                    {renderTestPageContent()}
                </div>


            </div>

        </div>
    )
};

export default StudentSelectTestPage;