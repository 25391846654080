import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import './User.css'

import SelectionDropdown from '../Common/SelectionDropdown';
import StudentList from "./StudentList";
import TeacherList from "./TeacherList";
import { ADMIN_USER_TYPE, MEMBER_TYPES } from "../../services/UserService";
import PageHeader from "../Common/Tailwind/Page/PageHeader";

const MemberPage = ({ user = ADMIN_USER_TYPE }) => {

    const { t } = useTranslation();
    const [type, setType] = useState({ id: MEMBER_TYPES[0]['id'], name: t(MEMBER_TYPES[0]['name']) });


    const onTypeChange = (selection) => {
        setType({ id: selection.value, name: selection.label });
    };

    const renderList = () => {

        switch (type.id) {
            case 'student':
                return <StudentList />;
            default:
                return <TeacherList />
        }
    }

    return (
        <div className="relative w-full h-full flex flex-col items-center p-4 gap-2 sm:gap-3 md:gap-4  text-[var(--textColor)] animate-fadeUp">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t('upgradeMemberManagement')} /> */}

            {/* Page Main */}
            <div className="flex flex-col md:flex-row w-full bg-white rounded-2xl overflow-hidden shadow-md">

                {/* Type Selection */}
                <div className="members-glass flex">

                    <div className="title">
                        <SelectionDropdown className="memberTypeSelectionDropdown" name="" itemList={MEMBER_TYPES.map(memberType => ({ id: memberType['id'], name: t(memberType['name']) }))} selectedItem={type} onSelectionChange={onTypeChange} nameField='name' valueField='id' />
                        <h3> {t('inCoaching')} </h3>
                    </div>

                    {renderList()}

                </div>
            </div>

        </div>

    )
};

export default MemberPage;
