import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
    TransitionChild,
} from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'

const PageSidebar = ({ sidebarLogo, sidebarTitle, sidebarItems = [], selectedTab, setSelectedTab, sidebarOpen, setSidebarOpen }) => {

    const { t } = useTranslation();

    useEffect(() => {
        if (!selectedTab) {
            setSelectedTab(sidebarItems[0].heading);
        }
    }, [sidebarItems, selectedTab, setSelectedTab]);

    return (
        <div className="flex flex-row items-center md:items-start md:flex-col w-full h-12 md:w-48 md:h-screen md:py-5 border-b md:border-b-0 md:border-r">

            <div className="flex flex-col md:w-full justify-center items-center md:min-h-40">
                <img className="hidden md:block w-auto h-40" src={sidebarLogo} alt="Logo" />
                <label className="font-semibold text-[var(--SecondaryColor)] text-xl uppercase px-4 md:px-0 whitespace-nowrap">{sidebarTitle}</label>
            </div>

            <div className="flex flex-1 w-full md:mt-6">
                <nav className="w-full flex flex-row md:flex-col space-y-0 md:space-y-6 space-x-2 md:space-x-0">
                    {/* menu section */}
                    <div className="w-full flex flex-row md:flex-col space-y-0 md:space-y-3 space-x-2 md:space-x-0">

                        {sidebarItems.map((item, index) => {
                            return (
                                <div
                                    className={`flex items-center gap-2.5 h-10 p-3 md:p-4 relative transition-all duration-300 ease-in-out cursor-pointer text-base ${selectedTab === item.heading ? 'bg-[var(--SecondaryBkg)] ml-0 font-semibold' : ''}`}
                                    // {selectedTab === item.heading ? "menuItem active" : "menuItem"}
                                    key={index}
                                    onClick={() => setSelectedTab(item.heading)}
                                >
                                    <item.icon className="w-5 h-5" />
                                    <span className="hidden md:inline whitespace-nowrap">{t(item.heading)}</span>
                                </div>
                            );
                        })}

                    </div>
                </nav>
            </div>
        </div>
    );
};

export default PageSidebar;
