import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './NTA.css'

import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { useNTATest } from './NTATestContext';

import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import NTASubmitWarning from './NTASubmitWarning';
import Spinner from '../../Common/Tailwind/Spinner';


const NTAQuestionBox = ({ handleSubmitTest, user }) => {

    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById,
        questionIndex,
        setQuestionIndex,
        getSectionName
    } = useNTATest();

    const [isSubmitWarningOpen, setSubmitWarningOpen] = useState(false);

    const questions = testQuestions.filter(testQuestion => testQuestion.test_section_id === activeSection.id);
    const { t } = useTranslation();

    const countByStatus = () => {
        const questions = testQuestions.filter(testQuestion => testQuestion.test_section_id === activeSection.id);
        const questionCounts = {};
        const defaultStatus = testStatus === 'STARTED' ? 'NOT_VISIT' : 'NO_RESPONSE';
        questions.forEach((testQuestion) => {
            const questionStyle = questionStyleById.find(questionStyle => testQuestion.question_id === questionStyle.question_id);
            const questionStyleValue = questionStyle ? questionStyle.status : defaultStatus;
            if (questionCounts[questionStyleValue] == null) {
                questionCounts[questionStyleValue] = 0;
            }
            questionCounts[questionStyleValue] += 1;
        });
        if (testStatus === 'STARTED') {
            return {
                answered_count: questionCounts['SAVE'] ? questionCounts['SAVE'] : 0,
                not_answered_count: (questionCounts['VISIT'] ? questionCounts['VISIT'] : 0) + (questionCounts['CLEAR'] ? questionCounts['CLEAR'] : 0),
                not_visited_count: questionCounts['NOT_VISIT'] ? questionCounts['NOT_VISIT'] : 0,
                review_count: questionCounts['REVIEW'] ? questionCounts['REVIEW'] : 0,
                review_answered_count: questionCounts['REVIEW_AND_SAVE'] ? questionCounts['REVIEW_AND_SAVE'] : 0
            }
        }
        return {
            correct_count: questionCounts['CORRECT'] ? questionCounts['CORRECT'] : 0,
            incorrect_count: questionCounts['INCORRECT'] ? questionCounts['INCORRECT'] : 0,
            no_response_count: questionCounts['NO_RESPONSE'] ? questionCounts['NO_RESPONSE'] : 0,
        }
    }

    const data = countByStatus();

    const handleClickId = async (index) => {
        setQuestionIndex(index);
    };

    const getQuestionStyle = (question) => {
        const questionAction = questionStyleById.find(questionStyle => question.question_id === questionStyle.question_id);
        if (!questionAction) {
            return 'not_visited';
        }

        const questionStatus = questionAction.status;
        if (testStatus === 'STARTED') {
            if (questionStatus === "VISIT") {
                return 'not_answered';
            }
            if (questionStatus === "CLEAR") {
                return 'not_answered';
            }
            if (questionStatus === "SAVE") {
                return 'answered';
            }
            if (questionStatus === "REVIEW") {
                return 'review';
            }
            if (questionStatus === "REVIEW_AND_SAVE") {
                return 'review_answered';
            }
            return 'not_visited';
        }
        if (testStatus === 'FINISHED') {
            if (questionStatus === "CORRECT") {
                return 'correct';
            }
            if (questionStatus === "INCORRECT") {
                return 'incorrect';
            }
            return 'no_response';
        }

    }

    return (
        <div className="NTAQuestionBox roboto-regular flex">

            <div className="NTANotationTypeDescription roboto-medium grid">
                {testStatus === 'STARTED' ? (<>
                    <div className="NTANotationType flex">
                        <span className="NTAAnswerNotation answered">{data.answered_count}</span>
                        <span className="label roboto-regular">{t('answered')}</span>
                    </div>

                    <div className="NTANotationType flex">
                        <span className="NTAAnswerNotation not_answered">{data.not_answered_count}</span>
                        <span className="label roboto-regular">{t('notAnswered')}</span>
                    </div>

                    <div className="NTANotationType flex">
                        <span className="NTAAnswerNotation not_visited">{data.not_visited_count}</span>
                        <span className="label roboto-regular">{t('notVisited')}</span>
                    </div>

                    <div className="NTANotationType flex">
                        <span className="NTAAnswerNotation review">{data.review_count}</span>
                        <span className="label roboto-regular">{t('markedForReview')}</span>
                    </div>

                    <div className="NTANotationType review_answered flex">
                        <span className="NTAAnswerNotation review_answered">{data.review_answered_count}</span>
                        <span className="label roboto-regular">{t('answeredAndMarkedForReviewWillAlsoBeEvaluated')}</span>
                    </div>
                </>
                ) : (
                    <>
                        <div className="NTANotationType flex">
                            <span className="NTAAnswerNotation answered">{data.correct_count}</span>
                            <span className="label roboto-regular">{t('correct')}</span>
                        </div>

                        <div className="NTANotationType flex">
                            <span className="NTAAnswerNotation not_answered">{data.incorrect_count}</span>
                            <span className="label roboto-regular">{t('incorrect')}</span>
                        </div>

                        <div className="NTANotationType flex">
                            <span className="NTAAnswerNotation not_visited">{data.no_response_count}</span>
                            <span className="label roboto-regular">{t('noResponse')}</span>
                        </div>
                    </>
                )}
            </div>

            <div className="NTAChooseAQuestion flex">
                <div className="NTATestSectionTitle roboto-bold">{getSectionName(activeSection)}</div>

                <div className="NTAQuestionBtns grid">
                    {questions === null ? (
                        <Spinner />
                    ) : (
                        questions.map((question, index) => (
                            <span className={`NTAQuestionBtn ${getQuestionStyle(question)}`}
                                id={(index === questionIndex) ? 'clicked-btn' : 'default-btn'}
                                draggable
                                key={index}
                                onClick={() => handleClickId(index)}
                            >
                                {index + 1}
                            </span>))
                    )}
                </div>

                {testStatus === "STARTED" && (
                    <>
                        <button className="submit-btn roboto-bold flex" onClick={() => setSubmitWarningOpen(true)}>
                            {t('submitCapitalStart')}
                        </button>
                        <NTASubmitWarning isOpen={isSubmitWarningOpen} onRequestClose={() => setSubmitWarningOpen(false)} handleSubmitTest={handleSubmitTest} parentId={'NTA-tests-glass'} />
                    </>
                )}

            </div>

        </div>
    );
}

export default NTAQuestionBox;
