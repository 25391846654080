import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Tooltip } from 'react-tooltip'

import './batches.css'
import bkgImg from '../../Assets/LiveClass.png'

import GradeBatchFilter from './GradeBatchFilter';
import GradeBatchCreate from './GradeBatchCreate';
import BatchTimetableFullCalendar from './BatchTimetableFullCalendar';

import { secureAdminFetchSlotsByBatchBetween, securedFetchBatchesByFilter, securedFetchSlots } from '../../services/BatchService';
import { ServerError, UnauthorizedError } from '../../services/Errors';
import { getDateString, getEndDateOfNextMonth, getStartDateOfLastMonth } from '../../services/DateService';
import { getEventsListFromTimetable } from '../../services/TimetableService';
import { authErrorCallbackNavigate, navigateCallbackOptions } from '../../services/AuthenticationService';
import { ADMIN_USER_TYPE } from '../../services/UserService';

import { MdAddCircleOutline } from "react-icons/md";
import PageHeader from "../Common/Tailwind/Page/PageHeader";
import Spinner from "../Common/Tailwind/Spinner";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import MessagePage from "../Common/Tailwind/Page/MessagePage";

const BatchManage = ({ user = ADMIN_USER_TYPE, replaceSearchParams = true }) => {

    const [isLoading, setIsLoading] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialGradeId, setInitialGradeId] = useState(searchParams.get('gradeId') ? parseInt(searchParams.get('gradeId')) : null);
    const [initialBatchId, setInitialBatchId] = useState(searchParams.get('batchId') ? parseInt(searchParams.get('batchId')) : null);
    const [grades, setGrades] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState({});
    const [batches, setBatches] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState({});
    const [isGradeBatchCreateOpen, setGradeBatchCreateOpen] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: searchParams.get('startDate') || null,
        endDate: searchParams.get('endDate') || null
    });
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const resetState = () => {
        setGrades([]);
        setSelectedGrade({});
        setBatches([]);
        setSelectedBatch({});
        setEventsList([]);
    }

    useEffect(() => {
        (async () => {

            if (!searchParams.get('batchId')) {
                setEventsList([]);
                setSelectedGrade({});
                setSelectedBatch({});
                return;
            }

            setIsLoading(true);
            const gradeId = parseInt(searchParams.get('gradeId'));
            const batchId = parseInt(searchParams.get('batchId'));
            const searchStartDate = searchParams.get('startDate');
            const searchEndDate = searchParams.get('endDate');
            if (selectedGrade.id === gradeId && selectedBatch.id === batchId && dateRange.startDate === searchStartDate && dateRange.endDate === searchEndDate) {
                return;
            }
            if (selectedGrade.id !== gradeId) {
                setInitialGradeId(gradeId);
            }
            if (selectedBatch.id !== batchId) {
                setInitialBatchId(batchId);
            }
            if (searchStartDate !== dateRange.startDate || searchEndDate !== dateRange.endDate) {
                setDateRange({
                    startDate: searchStartDate,
                    endDate: searchEndDate
                });
            }

            setIsLoading(false);
        })();
    }, [location]);

    useEffect(() => {
        (async () => {

            if (!selectedBatch || !selectedBatch.id || !dateRange.startDate || !dateRange.endDate) {
                return;
            }

            setIsLoading(true);

            const batchId = selectedBatch.id || parseInt(searchParams.get('batchId'));
            const response = await securedFetchSlots(makeFilter(dateRange.startDate, dateRange.endDate, batchId), { authErrorCallback: authErrorCallbackNavigate(navigate), serverErrorCallback: resetState });
            if (response !== null) {
                setEventsList(getEventsListFromTimetable(response.data));
            }

            setIsLoading(false);
        })();
    }, [selectedBatch, dateRange]);

    const makeFilter = (inputStartDate = null, inputEndDate = null, batchId) => {
        const startTime = inputStartDate ? `${inputStartDate} 00:00:00` : getDateString(getStartDateOfLastMonth(new Date()));
        const endTime = inputEndDate ? `${inputEndDate} 23:59:59` : getDateString(getEndDateOfNextMonth(new Date()));
        const filter = {
            start_time: startTime,
            end_time: endTime,
            batch_ids: [batchId]
        }
        return filter;
    }

    const toggleIsClicked = async () => {
        setIsClicked(!isClicked);
        const response = await securedFetchSlots(makeFilter(dateRange.startDate, dateRange.endDate, selectedBatch.id), { authErrorCallback: authErrorCallbackNavigate(navigate), serverErrorCallback: resetState });
        if (response !== null) {
            setEventsList(getEventsListFromTimetable(response.data));
        }
    }

    const onSelectedBatchChange = async (selection) => {
        const batchId = selection ? selection.value : null;
        setSelectedBatch({ id: batchId, batch_name: selection.label });
    }

    const onScheduleChange = async (scheduleId) => {
        await onSelectedBatchChange({ value: selectedBatch.id, label: selectedBatch.batch_name });
    }

    const onSlotDelete = async (slotId) => {
        setEventsList(eventsList.filter(event => event.id !== slotId));
        const response = await securedFetchSlots(makeFilter(dateRange.startDate, dateRange.endDate, selectedBatch.id), { authErrorCallback: authErrorCallbackNavigate(navigate), serverErrorCallback: resetState });
        if (response !== null) {
            setEventsList(getEventsListFromTimetable(response.data));
        }
    }

    const onViewTimetable = async () => {
        const response = await securedFetchSlots(makeFilter(dateRange.startDate, dateRange.endDate, selectedBatch.id), { authErrorCallback: authErrorCallbackNavigate(navigate), serverErrorCallback: resetState });
        if (response !== null) {
            setEventsList(getEventsListFromTimetable(response.data));
        }
    }

    const onBatchCreate = async (batchData) => {
        setSelectedGrade(grades.find(grade => grade.id === batchData.batch_grade) || {});
        const fetchedBatches = await securedFetchBatchesByFilter({ grades: [batchData.batch_grade] }, navigateCallbackOptions(navigate));
        if (fetchedBatches === null) {
            return;
        }
        setBatches(fetchedBatches.data);
        setSelectedBatch(fetchedBatches.data.find(batch => (batch.id === batchData.id)) || {});
        setSearchParams({ batchId: batchData.id, gradeId: batchData.batch_grade });
    }

    const renderBatchManageMain = () => {
        if (isLoading) {
            return <Spinner />;
        }
        return (
            <main className="w-full h-full md:py-6">

                <div class="flex flex-col mb-4 overflow-x-auto overflow-y-auto md:justify-center dark:border-gray-700">
                    {(Object.keys(selectedGrade).length && Object.keys(selectedBatch).length) ?
                        <BatchTimetableFullCalendar eventsList={eventsList} batch={batches.find(batch => batch.id === selectedBatch.id) || null} onScheduleCreate={onScheduleChange} onViewTimetable={onViewTimetable} onSlotDelete={onSlotDelete} user={user} dateRange={dateRange} setDateRange={setDateRange} replaceSearchParams={replaceSearchParams} />
                        :
                        <MessagePage imgsrc={bkgImg} title={""} message1={""} message2={""} message3={""} />
                    }
                </div>

            </main>
        )
    }

    return (
        <div className="relative w-full h-full flex flex-col items-center p-4 gap-4 text-[var(--textColor)] animate-fadeUp">

            {/* Page Header */}
            {/* <PageHeader pageHeaderText={t('revolutionizeBatchManagement')} /> */}

            {/* Page Main */}
            <div className="flex flex-col w-full bg-white rounded-2xl overflow-hidden shadow-md">

                {/* Filter */}
                <div className="w-full flex flex-col justify-center items-center p-2 my-2 gap-2 md:gap-4 z-3">

                    <GradeBatchFilter
                        grades={grades} selectedGrade={selectedGrade}
                        batches={batches} selectedBatch={selectedBatch}
                        setGrades={setGrades} setSelectedGrade={setSelectedGrade}
                        setBatches={setBatches} setSelectedBatch={setSelectedBatch}
                        onSelectedBatchChange={onSelectedBatchChange} user={user}
                        initialGradeId={initialGradeId} initialBatchId={initialBatchId}
                        useSearchParamter={true}
                        setGradeBatchCreateOpen={setGradeBatchCreateOpen}
                        replaceSearchParams={replaceSearchParams}

                    />

                    <GradeBatchCreate isOpen={isGradeBatchCreateOpen} onRequestClose={() => setGradeBatchCreateOpen(false)} gradeInput={selectedGrade} gradesInput={grades} setBatch={setSelectedBatch} user={user} onBatchCreate={onBatchCreate} />

                    <Tooltip id="tooltip-create-new-batch" />
                </div>

                {/* Main */}
                {renderBatchManageMain()}

            </div>

        </div>
    )
}

export default BatchManage;