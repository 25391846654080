import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import './NTA.css'

import { ADMIN_USER_TYPE, TEASCHER_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { useNTATest } from './NTATestContext';

const NTAAnswersCountsTooltip = ({ sectionId = null, sectionName = null, user = STUDENT_USER_TYPE, style }) => {

    const {
        testData,
        activeSection,
        testStatus,
        setTestStatus,
        testSections,
        testQuestions,
        testParagraphs,
        questionStyleById,
        setQuestionStyleById
    } = useNTATest();

    const [data, setData] = useState({});
    const { t } = useTranslation();



    const countByStatus = () => {
        const questions = sectionId != null ? testQuestions.filter(testQuestion => testQuestion.test_section_id === sectionId) : testQuestions;
        const questionCounts = {};
        const defaultStatus = testStatus === 'STARTED' ? 'NOT_VISIT' : 'NO_RESPONSE';
        questions.forEach((testQuestion) => {
            const questionStyle = questionStyleById.find(questionStyle => testQuestion.question_id === questionStyle.question_id);
            const questionStyleValue = questionStyle ? questionStyle.status : defaultStatus;
            if (questionCounts[questionStyleValue] == null) {
                questionCounts[questionStyleValue] = 0;
            }
            questionCounts[questionStyleValue] += 1;
        });

        return {
            answered_count: questionCounts['SAVE'] ? questionCounts['SAVE'] : 0,
            not_answered_count: questionCounts['VISIT'] ? questionCounts['VISIT'] : 0,
            not_visited_count: questionCounts['NOT_VISIT'] ? questionCounts['NOT_VISIT'] : 0,
            review_count: questionCounts['REVIEW'] ? questionCounts['REVIEW'] : 0,
            review_answered_count: questionCounts['REVIEW_AND_SAVE'] ? questionCounts['REVIEW_AND_SAVE'] : 0,
            incorrect_count: questionCounts['INCORRECT'] ? questionCounts['INCORRECT'] : 0,
            correct_count: questionCounts['CORRECT'] ? questionCounts['CORRECT'] : 0,
            no_response_count: questionCounts['NO_RESPONSE'] ? questionCounts['NO_RESPONSE'] : 0,
        }
    }

    useEffect(() => {
        setData(countByStatus());
    }, [testStatus]);


    return (

        <div className={`${sectionId !== null ? 'NTAAnswersCountsTooltipSection' : 'NTAAnswersCountsTooltipOverall'} roboto-regular flex`} style={style}>

            {sectionId !== null && (<span className="sectionName"><strong>{sectionName}</strong></span>)}
            {testStatus === 'STARTED' ? (
                <>
                    <div className="NTANotationType roboto-regular flex" title="Answered">
                        <span className="NTAAnswerNotation answered">{data.answered_count}</span>
                        <span className="label">{t("answered")}</span>
                    </div>
                    <div className="NTANotationType roboto-regular flex" title="Not Answered">
                        <span className="NTAAnswerNotation not_answered">{data.not_answered_count}</span>
                        <span className="label">{t("notAnswered")}</span>
                    </div>
                    <div className="NTANotationType roboto-regular flex" title="Not Visited">
                        <span className="NTAAnswerNotation not_visited">{data.not_visited_count}</span>
                        <span className="label">{t("notVisited")}</span>
                    </div>
                    <div className="NTANotationType roboto-regular flex" title="Marked for Review">
                        <span className="NTAAnswerNotation review">{data.review_count}</span>
                        <span className="label">{t("markedForReview")}</span>
                    </div>
                    <div className="NTANotationType roboto-regular flex" title="Answered & Marked for Review (will also be evaluated)">
                        <span className="NTAAnswerNotation review_answered">{data.review_answered_count}</span>
                        <span className="label">{t("answeredAndMarkedForReviewWillAlsoBeEvaluated")}</span>
                    </div>
                </>
            ) : (
                <>
                    <div className="NTANotationType roboto-regular flex" title="Answered">
                        <span className="NTAAnswerNotation answered">{data.correct_count}</span>
                        <span className="label">{t("correct")}</span>
                    </div>
                    <div className="NTANotationType roboto-regular flex" title="Not Answered">
                        <span className="NTAAnswerNotation not_answered">{data.incorrect_count}</span>
                        <span className="label">{t("incorrect")}</span>
                    </div>
                    <div className="NTANotationType roboto-regular flex" title="Not Visited">
                        <span className="NTAAnswerNotation not_visited">{data.no_response_count}</span>
                        <span className="label">{t("noResponse")}</span>
                    </div>
                </>
            )}
        </div>
    )
}

export default NTAAnswersCountsTooltip;
