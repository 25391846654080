const PageHeader = ({ pageProfile, pageHeaderText = "Header comes here..." }) => {
    return (
        // <div className="overflow-hidden rounded-lg bg-white shadow">
        <div class="relative w-full p-2 flex flex-col items-center gap-4 bg-gradient-to-r from-[var(--SecondaryBkg)] to-[var(--PrimaryBkg)] rounded-2xl shadow-md sm:hidden md:block">

            <div className="m-auto max-w-5xl px-4 py-1 sm:px-6 lg:px-8">
                <div className="min-w-0 flex-1 text-center">
                    <h1 className="text-white text-lg md:text-xl lg:text-2xl font-semibold tracking-tight">
                        {pageHeaderText}
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default PageHeader;