import React from 'react'
import { useState, useEffect } from "react";

import bkgImage from '../../Assets/Teacher.png'
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import { TeacherNavItems } from './TeacherNavItems';
import { TEACHER_USER_TYPE } from '../../services/UserService';
import Hero from '../../Components/Hero/Hero';
import UserProfile from '../../Components/Profile/UserProfile';

const TeacherProfile = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Navbar navItems={TeacherNavItems} user={TEACHER_USER_TYPE} />
            {/* <Hero
                cName="hero"
                img={bkgImage}
                title="Teacher"
                description="Engage, Interact and Support Your Students with JEETA!"
            /> */}
            <UserProfile user={TEACHER_USER_TYPE} />
            <Footer user={TEACHER_USER_TYPE} />
        </div>
    )
};

export default TeacherProfile;