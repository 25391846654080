import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Chart } from "react-google-charts";
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";

import './testReport.css';

import GeneralStatistics from './Statistics/GeneralStatistics';
import ResponseStatistics from './Statistics/ResponseStatistics';
import QuestionwiseStatistics from './Statistics/QuestionwiseStatistics';
import StudentwiseStatistics from './Statistics/StudentwiseStatistics';
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { TEST_CREATE_TYPE_BASIC, securedCreateStudentTestReport, securedFetchStudentTestReport } from '../../../services/TestService';

import { AiFillCloseCircle } from "react-icons/ai";
import DetailResponses from './Statistics/DetailResponses';


const formatPercentage = (percentage) => percentage !== null ? `${Math.floor(Number(percentage) * 100) / 100}%` : '0%';

const ShowGeneralStatistics = ({ data }) => {
    return (
        <div className="statistics-section total flex">
            <h3>Number of Questions: {data.total_questions_in_test}</h3>
            <h3>Number of Students: {data.total_students_appeared_in_test}</h3>
        </div>
    );
};

const StudentTestReport = ({ testId, studentId = null, testName, testCreateType = TEST_CREATE_TYPE_BASIC, isOpen, onRequestClose, setEvaluatedNow = null, user = ADMIN_USER_TYPE }) => {

    const [testReportData, setTestReportData] = useState(null);
    const [rank, setRank] = useState(null);
    const [selected, setSelected] = useState("QUESTIONS");
    const [selectedMenu, setSelectedMenu] = useState("DETAIL");
    const [reportStudentId, setReportStudentId] = useState(studentId);

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (!isOpen || (testReportData !== null && testReportData.test_id === testId)) {
                return;
            }
            let response = null;
            if (testCreateType === TEST_CREATE_TYPE_BASIC) {
                response = await securedFetchStudentTestReport(testCreateType, testId, navigateCallbackOptions(navigate));
                if (response === null) {
                    onRequestClose();
                    return;
                }
                if (response.status === 404) {
                    alert('Test Report has not been generated yet!');
                    onRequestClose();
                    return;
                }
            } else {
                const firstResponse = await securedFetchStudentTestReport(testCreateType, testId, navigateCallbackOptions(navigate));
                if (firstResponse !== null && firstResponse.status !== 404) {
                    response = firstResponse;
                } else {
                    const createResponse = await securedCreateStudentTestReport(testCreateType, testId, navigateCallbackOptions(navigate));
                    if (createResponse === null) {
                        onRequestClose();
                        return;
                    }
                    const secondResponse = await securedFetchStudentTestReport(testCreateType, testId, navigateCallbackOptions(navigate));
                    if (secondResponse === null) {
                        onRequestClose();
                        return;
                    }
                    response = secondResponse;
                }
            }
            setRank(response.rank);
            setTestReportData(response);
            if (reportStudentId === null) {
                setReportStudentId(response.student_id);
            }
        })();
    }, [testId, isOpen]);

    useEffect(() => {
        if (testReportData === null) {
            return;
        }
        setEvaluatedNow(true);
    }, [testReportData])

    if (testReportData === null) {
        return;
    }

    return (
        <Modal overlayClassName="customModal" className="testReportModal" isOpen={isOpen} onRequestClose={onRequestClose}>
            <div className="testReport flex">

                <h2 className="testReportTitle">{t('testReport')}</h2>

                {testReportData && (
                    <div className="report-container flex">

                        <div className="report-title flex">
                            {rank != null &&
                                <h3>{t('rank')}: {rank}</h3>
                            }
                            <h3>{t('totalNumberOfQuestions')}: {testReportData.report_data.general_statistics.total_questions_in_test}</h3>
                            <h3>{t('totalMarks')}: {testReportData.report_data.general_statistics.total_test_marks}</h3>
                            <h3>{t('totalMarksObtained')}: {testReportData.report_data.student_summary_report.total_score}</h3>

                        </div>

                        <div className="report-table-wrapper">
                            <DetailResponses
                                testId={testId}
                                testName={testName}
                                data={testReportData.report_data.student_question_wise_report}
                                student_wise_data={reportStudentId ? { [reportStudentId]: testReportData.report_data.student_summary_report } : {}}
                                num_questions={testReportData.report_data.general_statistics.total_questions_in_test}
                                num_students={1}
                                user={user}
                            />
                        </div>

                    </div>

                )}

                <AiFillCloseCircle className="icon" id='close' onClick={onRequestClose} />
            </div>
        </Modal >
    );
}

export default StudentTestReport;
