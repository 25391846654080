import { LuFileClock } from "react-icons/lu";
import { SiSpeedtest } from "react-icons/si";
import { FaArrowRotateLeft } from "react-icons/fa6";

// Sidebar Items
export const StudentSelectTestPageSidebarItems = [
    {

        icon: LuFileClock,
        name: 'Common',
        heading: 'official',
        url: '/student/tests'
    },
    {
        icon: FaArrowRotateLeft,
        name: 'Virtual',
        heading: 'retake',
        url: '/student/virtual-tests'
    },
    {
        icon: SiSpeedtest,
        name: 'Practice',
        heading: 'practice',
        url: '/student/practice-tests'
    },
];