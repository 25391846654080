import * as tus from 'tus-js-client';

import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL, BUNNY_TUS_UPLOAD_URL } from "./BackendConfig";
import { STUDENT_USER_TYPE } from "./UserService";
import { createParams } from './CommonService';


export const getVideoCredentials = async (data) => {
    const response = await fetch(`${BACKEND_URL}/videos/lecture/credentials`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
    return response;
}

export const uploadVideoFile = async (file, data, tusCallbacks) => {
    try {
        const response = await getVideoCredentials(data);
        await checkAuthorized(response);
        const uploadCredentials = await returnJsonResponse(response);
        const endpoint = BUNNY_TUS_UPLOAD_URL;
        const upload = new tus.Upload(file, {
            endpoint: endpoint,
            retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
            headers: {
                "AuthorizationSignature": uploadCredentials.authorization_signature,
                "AuthorizationExpire": uploadCredentials.authorization_expire,
                "VideoId": uploadCredentials.video_id,
                "LibraryId": uploadCredentials.library_id,
            },
            metadata: {
                filetype: file.type,
                title: data.title
            },
            onError: ((error) => {
                if (tusCallbacks.onError) {
                    alert(`Error occured while uploading video: ${error.message}`)
                    tusCallbacks.onError();
                }

            }),
            onProgress: (async (bytesUploaded, bytesTotal) => {
                if (tusCallbacks.onProgress) {
                    const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                    await tusCallbacks.onProgress(percentage);
                }
            }),
            onSuccess: (async () => {
                if (tusCallbacks.onSuccess) {
                    await tusCallbacks.onSuccess(uploadCredentials.video_id);
                }
            })
        });

        const previousUploads = await upload.findPreviousUploads();
        if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
        }
        upload.start();

    } catch (error) {
        alert(`Error occured while uploading video: ${error.message}`)
    }
};

const createVideo = async (data) => {
    const response = await fetch(`${BACKEND_URL}/videos`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

const createRecordedVideo = async (data) => {
    const response = await fetch(`${BACKEND_URL}/recorded-lecture-videos`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

const createClassLectureVideo = async (data) => {
    const response = await fetch(`${BACKEND_URL}/class-lecture-videos`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

const fetchRecordedVideosByFilter = async (filter) => {
    let urlString = `${BACKEND_URL}/recorded-lecture-videos${createParams(filter)}`
    const response = await fetch(urlString, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

const fetchClassLectureVideoById = async (id) => {
    let urlString = `${BACKEND_URL}/class-lecture-videos/${id}`
    const response = await fetch(urlString, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

const fetchRecordedVideoById = async (id) => {
    let urlString = `${BACKEND_URL}/recorded-lecture-videos/${id}`
    const response = await fetch(urlString, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

const createVideoAccessRequestBulk = async (data) => {
    const response = await fetch(`${BACKEND_URL}/videos/access-requests/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

const fetchVideoAccessRequestsByFilter = async (filter) => {
    const response = await fetch(`${BACKEND_URL}/videos/access-requests${createParams(filter)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include"
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

const updateVideoAccessRequestDecision = async (id, data) => {
    const response = await fetch(`${BACKEND_URL}/videos/access-requests/${id}/decision`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(data)
    });
    await checkAuthorized(response);
    return response;
}

export const securedUploadVideoFile = withAuth(uploadVideoFile);
export const securedCreateVideo = withAuth(createVideo);
export const securedCreateRecordedVideo = withAuth(createRecordedVideo);
export const securedCreateClassLectureVideo = withAuth(createClassLectureVideo);
export const securedFetchRecordedVideoByFilter = withAuth(fetchRecordedVideosByFilter);
export const securedFetchRecordedVideoById = withAuth(fetchRecordedVideoById);
export const securedFetchClassLectureVideoById = withAuth(fetchClassLectureVideoById);
export const securedFetchVideoAccessRequestsByFilter = withAuth(fetchVideoAccessRequestsByFilter);
export const securedCreateVideoAccessRequestBulk = withAuth(createVideoAccessRequestBulk);
export const securedUpdateVideoAccessRequestDecision = withAuth(updateVideoAccessRequestDecision);

export const hasRecordedVideoCreateAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const needsVideoRequestApproval = (user) => {
    return user === STUDENT_USER_TYPE;
}

export const RECORDED_VIDEO_UPLOAD_TYPES = [{ id: 'lecture', name: 'Lecture' }];
