import { useNavigate } from "react-router-dom";
import { checkAuthorized, returnJsonResponse, withAdminAuth, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { containsEmptyArray, createParams, getHmacUrl, urlToFile } from "./CommonService";
import { UnauthorizedError, ServerError } from "./Errors";
import { ADMIN_USER_TYPE, STUDENT_USER_TYPE } from "./UserService";


export const makeAnswerData = (answerList) => {
    return answerList.map(answer => ({ id: null, value: answer, question_answer_id: null }));
}

export const uploadImagesList = async (imagesList, hmacHash = false) => {

    let imagesListToUpload = imagesList;
    if (hmacHash) {
        imagesListToUpload = [];
        for (let i = 0; i < imagesList.length; i++) {
            const imageListToUpload = [];
            for (let j = 0; j < imagesList[i].length; j++) {
                imageListToUpload.push(await getHmacUrl(imagesList[i][j]));
            }
            imagesListToUpload.push(imageListToUpload);
        }
    }
    const data = new FormData();
    const flatImages = [].concat(...imagesListToUpload);
    if (flatImages.length === 0) {
        return Array(imagesListToUpload.length).fill().map(() => []);
    }
    for (const [i, image] of flatImages.entries()) {
        const file = await urlToFile(image, `image${i}`, 'image/jpeg');
        data.append('image_files', file);
    }

    const response = await fetch(`${BACKEND_URL}/images/question/bulk`, {
        method: 'POST',
        body: data,
    });

    await checkAuthorized(response);

    const { image_ids } = await response.json();
    let idsIndex = 0;

    // Convert the flat list of image_ids back into a list of lists
    const nestedImageIds = imagesListToUpload.map(imageList => {
        const newList = image_ids.slice(idsIndex, idsIndex + imageList.length);
        idsIndex += imageList.length;
        return newList;
    });

    return nestedImageIds;
};

export const makeAnswers = (answerInputs, selectedAnswers) => {
    return answerInputs.map((answer, index) => ({
        id: answer.question_answer_id,
        answer: answer.value != null ? { id: answer.id, answer_text: answer.value } : { id: answer.id, min_value: answer.min_value, max_value: answer.max_value != null ? answer.max_value : answer.min_value },
        is_correct: [selectedAnswers.includes(index)],  // assuming selectedAnswer is the index of the correct answer
    }));
}

export const securedUploadImagesList = withAuth(uploadImagesList);

export const mapToSingleChoiceQuestionCreateFormat = async (data, questionType, isImageResolved = false) => {
    let {
        id,
        question,
        questionImages,
        answerInputs,
        selectedAnswer,
        solution,
        solutionImages,
        topics,
        tags,
        difficulty
    } = data;

    // Convert the question and solution images to the correct format
    if (!isImageResolved && (questionImages.length > 0 || solutionImages.length > 0)) {
        const imagesList = [questionImages, solutionImages];
        const imageIdsList = await securedUploadImagesList(imagesList, id ? true : false);
        questionImages = imageIdsList[0].map((imageId) => ({ image_id: imageId }))
        solutionImages = imageIdsList[1].map((imageId) => ({ image_id: imageId }))
    }

    // Convert the answer inputs to the correct format
    const answers = makeAnswers(answerInputs, [selectedAnswer]);

    const topicsData = topics.map((topic) => ({ topic_id: topic.id }));
    const tagsData = tags.map((tag) => ({ tag_id: tag.id }));

    // Here, we assume there's a helper function `getTopicIds` which retrieves topic ids for the questions
    // and returns them as an array. You might need to adjust this according to your real data.
    // Return the mapped object
    return {
        id: id ? id : null,
        question_text: question,
        question_subtype: 'single choice',
        question_difficulty: difficulty,  // Set this according to your data
        topics: topicsData,
        tags: tagsData,
        question_type: questionType,
        solution: {
            solution_text: solution,
            solution_images: solutionImages,
        },
        answers,
        question_images: questionImages,
    };
};


export const mapToAnswerTextQuestionCreateFormat = (questionSubtype) => async (data, questionType, isImageResolved = false) => {
    let {
        id,
        question,
        questionImages,
        answer,
        solution,
        solutionImages,
        topics,
        tags,
        difficulty
    } = data;

    // Convert the question and solution images to the correct format
    if (!isImageResolved && (questionImages.length > 0 || solutionImages.length > 0)) {
        const imagesList = [questionImages, solutionImages];
        const imageIdsList = await securedUploadImagesList(imagesList, true);
        questionImages = imageIdsList[0].map((imageId) => ({ image_id: imageId }))
        solutionImages = imageIdsList[1].map((imageId) => ({ image_id: imageId }))
    }

    // Convert the answer inputs to the correct format
    const answers = makeAnswers([answer], [0]);

    const topicsData = topics.map((topic) => ({ topic_id: topic.id }));
    const tagsData = tags.map((tag) => ({ tag_id: tag.id }));

    // Here, we assume there's a helper function `getTopicIds` which retrieves topic ids for the questions
    // and returns them as an array. You might need to adjust this according to your real data.
    // Return the mapped object
    return {
        id: id ? id : null,
        question_text: question,
        question_subtype: questionSubtype,
        question_difficulty: difficulty,  // Set this according to your data
        topics: topicsData,
        tags: tagsData,
        question_type: questionType,
        solution: {
            solution_text: solution,
            solution_images: solutionImages,
        },
        answers,
        question_images: questionImages,
    };
};

export const mapToNumericalQuestionCreateFormat = mapToAnswerTextQuestionCreateFormat('numerical');

export const mapToSubjectiveQuestionCreateFormat = mapToAnswerTextQuestionCreateFormat('subjective');

export const mapToMultiChoiceQuestionCreateFormat = async (data, questionType, isImageResolved = false) => {
    let {
        id,
        question,
        questionImages,
        answerInputs,
        selectedAnswers,
        solution,
        solutionImages,
        topics,
        tags,
        difficulty
    } = data;
    // Convert the question and solution images to the correct format
    if (!isImageResolved && (questionImages.length > 0 || solutionImages.length > 0)) {
        const imagesList = [questionImages, solutionImages];
        const imageIdsList = await securedUploadImagesList(imagesList, true);
        questionImages = imageIdsList[0].map((imageId) => ({ image_id: imageId }))
        solutionImages = imageIdsList[1].map((imageId) => ({ image_id: imageId }))
    }

    const topicsData = topics.map((topic) => ({ topic_id: topic.id }));
    const tagsData = tags.map((tag) => ({ tag_id: tag.id }));


    // Convert the answer inputs to the correct format
    const answers = makeAnswers(answerInputs, selectedAnswers.map((selectedAnswer, index) => ({ answer: selectedAnswer, index: index })).filter(selectedAnswer => selectedAnswer.answer).map(selectedAnswer => selectedAnswer.index));
    return {
        id: id ? id : null,
        question_text: question,
        question_subtype: 'multi choice', // single choice
        question_difficulty: difficulty,  // Set this according to your data
        // question_type: ...,  // Set this according to your data
        topics: topicsData,
        tags: tagsData,
        question_type: questionType,
        solution: {
            solution_text: solution,
            solution_images: solutionImages,
        },
        answers,
        question_images: questionImages,
    };
};

const mapToParagraphQuestionCreate = async (question, index = null) => {
    let questionData;
    if (question.type === 'multi choice') {
        questionData = await mapToMultiChoiceQuestionCreateFormat(question.data, 'paragraph', true);
    }
    else if (question.type === 'single choice') {
        questionData = await mapToSingleChoiceQuestionCreateFormat(question.data, 'paragraph', true);
    } else {
        questionData = await mapToAnswerTextQuestionCreateFormat(question.type)(question.data, 'paragraph', true);
    }
    return { id: question.paragraph_question_id, question_order: index, question: questionData };
}

const mapToParagraphCreateFormat = async (data) => {
    let {
        paragraph,
        questions,
        topics,
        tags,
        paragraphImages,
        difficulty
    } = data;
    const imagesList = [paragraphImages];
    questions.forEach((question, i) => {
        imagesList.push(question.data.questionImages);
        imagesList.push(question.data.solutionImages);
    })
    const imageIdsList = await securedUploadImagesList(imagesList, true);
    const iterator = imageIdsList[Symbol.iterator]();
    paragraphImages = iterator.next().value.map((id) => ({ image_id: id }));
    const updatedQuestions = questions.map((question) => {
        question.data.questionImages = iterator.next().value.map((id) => ({ image_id: id }));
        question.data.solutionImages = iterator.next().value.map((id) => ({ image_id: id }));
        return question;
    });
    const questionsData = await Promise.all(updatedQuestions.map(async (q, index) => {
        const questionData = await mapToParagraphQuestionCreate(q, index);
        return questionData;
    }));
    const topicsData = topics.map((topic) => ({ topic_id: topic.id }));
    const tagsData = tags.map((tag) => ({ tag_id: tag.id }));
    return {
        paragraph_text: paragraph,
        questions: questionsData,
        topics: topicsData,
        tags: tagsData,
        paragraph_images: paragraphImages,
        paragraph_difficulty: difficulty
    };
};

export const createQuestion = async (data, questionType, mapToQuestionCreateFormat) => {
    const mappedData = await mapToQuestionCreateFormat(data, questionType);
    const response = await fetch(`${BACKEND_URL}/questions/standalone`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(mappedData),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};


export const updateQuestion = async (id, data, questionType, mapToQuestionCreateFormat) => {
    const mappedData = await mapToQuestionCreateFormat(data, questionType);
    const response = await fetch(`${BACKEND_URL}/questions/standalone/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(mappedData),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
};

export const createParagraph = async (data) => {
    const mappedData = await mapToParagraphCreateFormat(data);
    const response = await fetch(`${BACKEND_URL}/questions/paragraph`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(mappedData),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};


export const updateParagraph = async (id, data) => {
    const mappedData = await mapToParagraphCreateFormat(data);
    const response = await fetch(`${BACKEND_URL}/questions/paragraph/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(mappedData),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
};

export const fetchQuestions = async (type, filter) => {
    if (containsEmptyArray(filter)) {
        return [];
    }
    const params = createParams(filter);
    const response = await fetch(`${BACKEND_URL}/questions/${type}${params}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const fetchQuestionsByIds = async (type, ids, extraFilter = null) => {
    if (ids.length === 0) {
        return { data: [] };
    }
    let filter = { ids: ids };
    if (extraFilter) {
        filter = { ...filter, ...extraFilter };
    }
    const response = await fetch(`${BACKEND_URL}/questions/${type}/bulk${createParams(filter)}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
};

export const fetchQuestionById = async (type, id, filter) => {
    const response = await fetch(`${BACKEND_URL}/questions/${type}/${id}${createParams(filter)}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchSolutionByQuestionId = async (questionId) => {
    const response = await fetch(`${BACKEND_URL}/questions/${questionId}/solution`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const deleteQuestionById = async (type, id) => {
    const response = await fetch(`${BACKEND_URL}/questions/${type}/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}


export const securedPostQuestion = withAuth(createQuestion);
export const securedPostParagraph = withAuth(createParagraph);
export const securedUpdateQuestion = withAuth(updateQuestion);
export const securedUpdateParagraph = withAuth(updateParagraph);
export const securedFetchQuestions = withAuth(fetchQuestions);
export const securedFetchQuestionsByIds = withAuth(fetchQuestionsByIds);
export const securedFetchQuestionById = withAuth(fetchQuestionById);
export const securedFetchSolutionByQuestionId = withAuth(fetchSolutionByQuestionId);
export const securedDeleteQuestionById = withAuth(deleteQuestionById);


export const prefetchAnswers = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const hasQuestionCreateAuthority = (user) => {
    return user !== STUDENT_USER_TYPE;
}

export const hasQuestionDeleteAuthority = (user) => {
    return user === ADMIN_USER_TYPE;
}

export const hasQuestionResponses = (user) => {
    return user === STUDENT_USER_TYPE;
}

export const hasQuestionMarkFeature = (user) => {
    return user === STUDENT_USER_TYPE;
}

export const hasQuestionEditAuthority = (user) => {
    return user === ADMIN_USER_TYPE;
}

export const canCheckQuestionResponse = (questionSubtype) => {
    return questionSubtype !== 'subjective';
}

export const waitForHints = (user) => {
    return user === STUDENT_USER_TYPE;
}

export const QUESTION_DIFFICULTIES = [{ id: "easy", name: "Easy" }, { id: "medium", name: "Medium" }, { id: "hard", name: "Hard" }]
export const QUESTION_UPLOAD_TYPES = [{ id: "single choice", name: "singleChoice" }, { id: "multi choice", name: "multiChoice" }, { id: 'numerical', name: 'numerical' },
{ id: "paragraph", name: "paragraph" }, { id: 'subjective', name: "subjective" }]
export const QUESTION_TEXT_SEARCH_TYPES = [{ id: "standalone", name: "singleChoice" }, { id: "paragraph", name: "paragraph" }]
export const QUESTION_STANDALONE_TYPES = ['single choice', 'multi choice', 'numerical', 'subjective', 'standalone'];
export const QUESTION_FILTER_DROPDOWN_TYPES = [{ id: "standalone", name: "standaloneAllTypes", value: null }, { id: "paragraph", name: "paragraph", value: "paragraph" },
{ id: "single choice", name: "standaloneSingleChoice", value: "single choice" }, { id: "multi choice", name: "standaloneMultiChoice", value: "multi choice" },
{ id: 'numerical', name: 'standaloneNumerical', "value": "numerical" }, { id: 'subjective', name: "standaloneSubjective", value: "subjective" }];
export const QUESTION_FILTER_SUBTYPES = [{ id: "single choice", name: "singleChoice" }, { id: "multi choice", name: "multiChoice" }, { id: 'numerical', name: 'numerical' }, { id: 'subjective', name: "subjective" }]

// export const QUESTION_OPTION_ALPHABETS = ['a', 'b', 'c', 'd'];
export const QUESTION_OPTIONS = ['1', '2', '3', '4', '5'];

