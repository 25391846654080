import React, { useState, useEffect, useRef } from 'react';

import './Timer.css'

import { useInterval } from '../Hooks/useInteval';

import { MdOutlineTimer } from "react-icons/md";

const ForwardTimer = ({ timeVar, setTimeVar }) => {

    const [selfTimeVar, setSelfTimeVar] = useState(0);
    const [isVisible, setVisible] = useState(false);

    const timeToTrack = setTimeVar ? timeVar : selfTimeVar;
    const setTimeToTrack = setTimeVar ? setTimeVar : setSelfTimeVar;

    useInterval(() => {
        incrementTime();
    }, 1000);

    const incrementTime = () => {
        setTimeToTrack(prevTime => prevTime + 1);
    }


    return (
        <div className={`forward-timer ${isVisible ? "visible" : ""}`} onClick={() => setVisible(!isVisible)}>

            {!isVisible ? (<span>Show</span>) : (<span>Hide</span>)}

            <MdOutlineTimer className="icon" />

            {isVisible && (
                <div className="time-display flex">
                    <span>{("0" + Math.floor(timeToTrack / 3600)).slice(-2)}:</span>
                    {/* <span>:</span> */}
                    <span>{("0" + Math.floor((timeToTrack % 3600) / 60)).slice(-2)}:</span>
                    {/* <span>:</span> */}
                    <span>{("0" + (timeToTrack % 60)).slice(-2)}</span>
                </div >)}

        </div>
    );
};

export default ForwardTimer;