import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './RecordedVideoCard.css'

import { STUDENT_USER_TYPE } from "../../../services/UserService";
import { RiLockPasswordLine } from "react-icons/ri";

const RecordedVideoCard = ({ recordedVideoData, onCardClick, user = STUDENT_USER_TYPE }) => {

    const subjectName = recordedVideoData.topics.length > 0 ? recordedVideoData.topics[0].topic.subject.subject_name : '';
    const approvalStatus = recordedVideoData.approval_status === "approve" ? "APPROVED" : "DENIED";
    const { t } = useTranslation();


    return (

        <div className={`recorded-video-card ${subjectName}`} onClick={() => onCardClick(recordedVideoData)}
            style={recordedVideoData?.thumbnail_url
                ? { backgroundImage: `url(${recordedVideoData.thumbnail_url})` }
                : {}}
        >

            <div className="approval-status">
                {approvalStatus === "APPROVED" ? (
                    <></>
                ) : (
                    <RiLockPasswordLine className="icon" id="DENIED" />
                )}
            </div>

            <div className={`bkg-cover ${approvalStatus}`}>
            </div>

            <span className="material"> {recordedVideoData.title || ""}</span>
            <span className={`subject ${subjectName}`}>{t(subjectName)}</span>

        </div >
    )

}

export default RecordedVideoCard;