import React, { useEffect, useState } from "react";
import { Chart } from 'react-google-charts';
import { useTranslation } from "react-i18next";

import '../testReport.css';

import { FaLongArrowAltDown } from "react-icons/fa";
import QuestionChart from "./QuestionChart";

function createAxis(max_num) {
    return Array.from({ length: max_num + 2 }, (_, index) => index - 1);
}

const StudentwiseStatistics = ({ data = null, num_questions = null }) => {

    const [chartHeight, setChartHeight] = useState(200); // Default height
    const [isChartView, setChartview] = useState(true);
    const [sortCriterion, setSortCriterion] = useState("Correct");

    const studentIds = Object.keys(data);
    const values = Object.values(data);
    const { t } = useTranslation();

    const chartData = [
        ["Student", "Correct", "Incorrect", "Partially Correct", "No Response"],

        ...studentIds.map((studentId, index) => [
            studentId,
            values[index].student_name,
            values[index].number_of_questions_correct,
            values[index].number_of_questions_incorrect,
            values[index].number_of_questions_partial_response,
            values[index].number_of_questions_no_response,
        ]),
    ];

    const sortData = (data, criterion) => {
        return data.slice(1).sort((a, b) => {
            if (criterion === 'Correct') {
                return b[2] - a[2]; // Sort by correct responses
            } else if (criterion === 'Incorrect') {
                return b[3] - a[3]; // Sort by incorrect responses
            } else if (criterion === 'Noresponse') {
                return b[5] - a[5]; // Sort by incorrect responses
            } else {
                return 0; // No sorting
            }
        });
    };

    const sortedChartData = [
        chartData[0],
        ...sortData(chartData, sortCriterion),
    ];

    const hAxis = createAxis(num_questions);

    const questionData = sortedChartData.slice(1);

    // Calculate the desired chart height based on data
    useEffect(() => {
        const numberOfDataPoints = chartData.length; // Adjust this based on your data
        const minHeight = 200; // Minimum height for the chart
        const calculatedHeight = Math.max(minHeight, numberOfDataPoints * 15); // Adjust the multiplier as needed

        setChartHeight(calculatedHeight);
    }, [chartData]);

    const chartOptions = {
        // title: 'Student-wise Result',
        chartArea: {
            width: '85%',
            height: '65%',
        },
        intervals: { style: "bars" },
        hAxis: {
            title: "Number of Responses",
            // minValue: 0,
            ticks: hAxis,
        },
        vAxis: {
            title: "Questions",
        },
        legend: {
            position: 'top', // Set the position of the legend (top, bottom, left, right)
            maxLines: 2,     // Set the maximum number of legend entries per line
            textStyle: {
                fontSize: 12,  // Set the font size for legend text
            },
            legendToggle: true, // Enable legend item toggling
        },
    };

    return (
        <div className="statistics-section flex">
            <div className="statistics-buttons">
                <div className="statistics-sort-buttons">
                    <button className={`statistics-button ${sortCriterion === "Correct" ? "active" : ""}`}
                        onClick={() => setSortCriterion("Correct")}>
                        {t('correct')}<FaLongArrowAltDown />
                    </button>
                    <button className={`statistics-button ${sortCriterion === "Incorrect" ? "active" : ""}`}
                        onClick={() => setSortCriterion("Incorrect")}>
                        {t('incorrect')}<FaLongArrowAltDown />
                    </button>
                    <button className={`statistics-button ${sortCriterion === "Noresponse" ? "active" : ""}`}
                        onClick={() => setSortCriterion("Noresponse")}>
                        {t('noResponse')}<FaLongArrowAltDown />
                    </button>
                </div>

                <div className="statistics-view-buttons">
                    <button className={`statistics-button ${isChartView ? 'active' : ''}`} onClick={() => setChartview(true)}>{t('chart')}</button>
                    <button className={`statistics-button ${isChartView ? '' : 'active'}`} onClick={() => setChartview(false)}>{t('table')}</button>
                </div>
            </div>

            {isChartView ? (
                <>
                    <div className="student-wise-charts-legends">
                        <div className="student-wise-charts-legend"> <div className="circle-legend" id="correct"></div><p className="legend-text">{t('correct')}</p></div>
                        <div className="student-wise-charts-legend"> <div className="circle-legend" id="incorrect"></div><p className="legend-text">{t('incorrect')}</p></div>
                        <div className="student-wise-charts-legend"> <div className="circle-legend" id="partial-correct"></div><p className="legend-text">{t('partialCorrect')}</p></div>
                        <div className="student-wise-charts-legend"> <div className="circle-legend" id="no-response"></div><p className="legend-text">{t('noResponse')}</p></div>
                    </div>
                    <div className="student-wise-charts">
                        {questionData.map((data, index) => (
                            <div key={data[0]} className="question-chart">
                                <QuestionChart inputData={data} dataType={"student"} />
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className="student-wise-table">
                    <table className="report-table">
                        <thead>
                            <tr>
                                <th>{t('studentNameId')}</th>
                                <th>{t('correct')}</th>
                                <th>{t('incorrect')}</th>
                                <th>{t('partialCorrect')}</th>
                                <th>{t('noResponse')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {questionData.map((studentEntry, index) => (
                                <tr key={index}>
                                    <td>{studentEntry[1]} ({studentEntry[0]})</td>
                                    <td>{studentEntry[2]}</td>
                                    <td>{studentEntry[3]}</td>
                                    <td>{studentEntry[4]}</td>
                                    <td>{studentEntry[5]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

        </div>
    );
};

export default StudentwiseStatistics;