import { checkAuthorized, returnJsonResponse, withAuth } from "./AuthenticationService";
import { BACKEND_URL } from "./BackendConfig";
import { containsEmptyArray, createParams } from "./CommonService";


export const registerUser = async (type, data) => {
    const userData = { user_type: type, data: data }
    const response = await fetch(`${BACKEND_URL}/users/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(userData)
    });
    return response;
}

export const fetchUsers = async (type, filter) => {
    if (containsEmptyArray(filter)) {
        return { data: [] };
    }
    const response = await fetch(`${BACKEND_URL}/users/${type}${createParams(filter)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const fetchUserById = async () => {
    const response = await fetch(`${BACKEND_URL}/users/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const updateUserPassword = async (data) => {
    const response = await fetch(`${BACKEND_URL}/users/password`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const updateUserDataById = async (type, id, data) => {
    const userData = { user_type: type, data: data }
    const response = await fetch(`${BACKEND_URL}/users/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const deactivateUserById = async (type, id) => {
    const data = { user_type: type };
    const response = await fetch(`${BACKEND_URL}/users/${id}/deactivate`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return response;
}

export const userForgotPassword = async (email) => {
    const response = await fetch(`${BACKEND_URL}/users/forgot-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'email': email }),
        credentials: 'include'
    });
    await checkAuthorized(response);
    return await returnJsonResponse(response);
}

export const hasSubjectInfo = (user) => {
    return user === TEACHER_USER_TYPE;
}

export const hasBatchInfo = (user) => {
    return user === STUDENT_USER_TYPE;
}

export const hasAdminRoleInfo = (user) => {
    return user === ADMIN_USER_TYPE;
}

export const securedRegisterUser = withAuth(registerUser);
export const securedFetchUsers = withAuth(fetchUsers);
export const securedFetchUserById = withAuth(fetchUserById);
export const securedUpdateUserPassword = withAuth(updateUserPassword);
export const securedDeactivateUserById = withAuth(deactivateUserById);
export const securedUpdateUserDataById = withAuth(updateUserDataById);
export const securedUserForgotPassword = withAuth(userForgotPassword);

export const MEMBER_TYPES = [
    { id: "student", name: "student" },
    { id: "teacher", name: "teacher" }];

export const ADMIN_USER_TYPE = 'admin';
export const TEACHER_USER_TYPE = 'teacher';
export const STUDENT_USER_TYPE = 'student';


export const checkUserType = (userData) => {
    if (userData.batches) {
        return 'student';
    }
    if (userData.subjects) {
        return 'teacher';
    }
    return 'admin';
};

