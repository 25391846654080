import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import './questions.css'

import QuestionCardList from './QuestionView/QuestionCardList';
import QuestionSlider from './QuestionView/QuestionSlider/QuestionSlider';

import { RiArrowGoBackFill } from 'react-icons/ri'
import { ADMIN_USER_TYPE } from '../../services/UserService';
import { areTestQuestionsVisible } from '../../services/TestService';
import { useSearchParams } from 'react-router-dom';
import { emptySearchParams, updateSearchParams } from '../../services/CommonService';

const Questions = ({ user = ADMIN_USER_TYPE, replaceSearchParams = false, syllabusFilter = null, hiddenFilters = null }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [page, setPage] = useState(1);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [searchFilter, setSearchFilter] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedQuestion && !searchParams.get('viewType')) {
            setSelectedQuestion(null);
        }
        if (searchParams.get('viewType') && !selectedQuestion) {
            emptySearchParams(setSearchParams, true);
        }
    }, [searchParams]);

    const handleQuestionCardClick = async (questionData) => {
        setSelectedQuestion(questionData);
        updateSearchParams(setSearchParams, searchParams, { viewType: 'questionSlider' }, replaceSearchParams);
    }

    const handleBackClick = () => {
        setSelectedQuestion(null);
        emptySearchParams(setSearchParams, replaceSearchParams);
    };

    const renderQuestionSlider = () => {
        const filteredQuestionIds = filteredQuestions.map(question => (question.id));
        const selectedQuestionIndex = filteredQuestionIds.indexOf(selectedQuestion.id);
        let sliderType = 'paragraph';
        if (selectedQuestion.question_type === 'standalone') {
            sliderType = 'standalone';
        }
        return (
            <QuestionSlider questionId={selectedQuestion.id} showAnswers={true} showImages={true} type={sliderType} user={user} questionIds={filteredQuestionIds} questionIdIndex={selectedQuestionIndex} onQuestionDelete={handleBackClick} />
        );
    }

    return (
        <div className='questions flex'>

            {selectedQuestion ? (
                <>
                    <button className="btn flex" id="back-btn" onClick={handleBackClick}>
                        <RiArrowGoBackFill className="icon" />
                        {t("backToQuestionsList")}
                    </button>
                    {renderQuestionSlider()}
                </>
            ) : (
                <>
                    <QuestionCardList handleQuestionCardClick={handleQuestionCardClick} user={user} includeTestQuestions={areTestQuestionsVisible(user)}
                        syllabusFilter={syllabusFilter} searchFilter={searchFilter} setSearchFilter={setSearchFilter}
                        onQuestionsChange={(questions) => setFilteredQuestions(questions)} page={page} setPage={setPage} hiddenFilters={hiddenFilters} />
                </>
            )}

        </div>
    )
}

export default Questions;