import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import moment from "moment";

import './Analytics.css'

import AnalyticsRadarChart from "./AnalyticsCharts/AnalyticsRadarChart";
import AnalyticsTable from "./AnalyticsTable/AnalyticsTable";
import AnalyticsBarChart from "./AnalyticsCharts/AnalyticsBarChart";
import AnalyticsLineChart from "./AnalyticsCharts/AnalyticsLineChart";
import AnalyticsSidebar from "./Sidebar/AnalyticsSidebar";
import Cards from "./Cards/Cards";

import { ADMIN_USER_TYPE, TEACHER_USER_TYPE, STUDENT_USER_TYPE } from "../../services/UserService";
import { navigateCallbackOptions } from "../../services/AuthenticationService";
import { makeChartDatapoint, makeDateRange, makeLabelsAndDatasets, makeLabelsAndDatasetsBySubjects, securedFetchStudentAnalyticsByFilter } from "../../services/AnalyticsService";
import StudentAnalyticsbyTopics from "./StudentAnalytics/StudentAnalyticsbyTopics";

import KPICards from "./Card/KPICards";
import Spinner from "../Common/Tailwind/Spinner";
import PageHeader from "../Common/Tailwind/Page/PageHeader";

const Analytics = ({ user = ADMIN_USER_TYPE }) => {
    const [selectedTab, setSelectedTab] = useState(null);
    const [period, setPeriod] = useState("1Week");
    const [filterDateRange, setFilterDateRange] = useState(null);
    const [analyticsData, setAnalyticsData] = useState(null);
    const navigate = useNavigate();


    const studentName = "Anupam Khandelwal";

    const updateDateRange = (period) => {
        const endDate = moment().subtract(1, 'days').endOf('day');
        let startDate;

        switch (period) {
            case '1Week':
                startDate = moment(endDate).subtract(1, 'weeks').add(1, 'day').startOf('day');
                break;
            case '1Month':
                startDate = moment(endDate).subtract(1, 'months').add(1, 'day').startOf('day');
                break;
            case '3Months':
                startDate = moment(endDate).subtract(3, 'months').add(1, 'day').startOf('day');
                break;
            default:
                startDate = moment(endDate).subtract(1, 'weeks').add(1, 'day').startOf('day');
        }
        const newDateRange = { startDate: startDate, endDate: endDate };
        setFilterDateRange(newDateRange);
    };

    const calculateAccuracy = (data, statusKey, scoreKey) => {
        const filteredData = data.filter(item => item[statusKey] === "Correct" || item[statusKey] === "Completed" || item[statusKey] === "Watched");
        if (filteredData.length === 0) return 0;
        const total = filteredData.reduce((sum, item) => sum + (item[scoreKey] || 1), 0);
        return Number((total / filteredData.length).toFixed(2));
    };

    const isWithinRange = (date, startDate, endDate) => {
        const itemDate = moment(date, ["DD/MM/YYYY", "YYYY-MM-DD", "DD MMM YYYY"]);
        return itemDate.isBetween(moment(startDate, "DD/MM/YYYY"), moment(endDate, "DD/MM/YYYY"), null, '[]');
    };

    // Function for array-type data (questionSolvedData, lectureWatchedData, testTakenData)
    const filterArrayDataByDateRange = (data, filterDateRange) => {
        if (!filterDateRange) return data; // 'All' case

        return data.filter(item => {
            const dateToCheck = item.date || item.lectureDate || item.testDate || item.dateWatched;
            return isWithinRange(dateToCheck, filterDateRange);
        });
    };

    // Function for object-type data with labels and datasets (dailyQuestionData, dailyLectureData, dailyTestData)
    const filterDailyDataByDateRange = (data, filterDateRange) => {
        if (!filterDateRange) return data; // 'All' case

        const filteredLabels = data.labels.filter(date => isWithinRange(date, filterDateRange));
        const filteredDatasets = data.datasets.map(dataset => ({
            ...dataset,
            data: dataset.data.filter(item => filteredLabels.includes(item.date))
        }));

        return {
            labels: filteredLabels,
            datasets: filteredDatasets
        };
    };

    // Main function that decides which filter to apply based on data type
    const filterDataByDateRange = (data, filterDateRange) => {
        if (Array.isArray(data)) {
            return filterArrayDataByDateRange(data, filterDateRange);
        } else if (data.labels && data.datasets) {
            return filterDailyDataByDateRange(data, filterDateRange);
        } else {
            return data; // For other types of data, return as is
        }
    };

    const parseDate = (item) => {
        const date = item.date || item.lectureDate || item.testDate || item.dateWatched;
        return moment(date, ["DD/MM/YYYY", "YYYY-MM-DD", "DD MMM YYYY"]);
    };

    // Function for array-type data (questionSolvedData, lectureWatchedData, testTakenData)
    const sortArrayDataByDate = (data) => {
        return [...data].sort((a, b) => {
            const dateA = parseDate(a);
            const dateB = parseDate(b);
            return dateB.valueOf() - dateA.valueOf(); // Sort in descending order
        });
    };

    // Function for object-type data with labels and datasets (dailyQuestionData, dailyLectureData, dailyTestData)
    const sortDailyDataByDate = (data) => {
        const sortedLabels = [...data.labels].sort((a, b) => {
            return moment(b, ["DD/MM/YYYY", "YYYY-MM-DD"]).valueOf() - moment(a, ["DD/MM/YYYY", "YYYY-MM-DD"]).valueOf();
        });

        const sortedDatasets = data.datasets.map(dataset => ({
            ...dataset,
            data: [...dataset.data].sort((a, b) => {
                return moment(b.date, ["DD/MM/YYYY", "YYYY-MM-DD"]).valueOf() - moment(a.date, ["DD/MM/YYYY", "YYYY-MM-DD"]).valueOf();
            })
        }));

        return {
            labels: sortedLabels,
            datasets: sortedDatasets
        };
    };

    // Main function that decides which sort to apply based on data type
    const sortDataByDate = (data) => {
        if (Array.isArray(data)) {
            return sortArrayDataByDate(data);
        } else if (data.labels && data.datasets) {
            return sortDailyDataByDate(data);
        } else {
            return data; // For other types of data, return as is
        }
    };

    const mapAnalyticsData = (analyticsData) => {
        const questionSubjectData = analyticsData.question_subject_stats.map(data => makeChartDatapoint(data, ['subject_id', 'total_unique_attempts']));
        const lectureSubjectData = analyticsData.lecture_subject_stats.map(data => makeChartDatapoint(data, ['subject_id', 'total_batch_lectures', 'total_video_watches']));
        const testData = analyticsData.question_subject_stats.map(data => makeChartDatapoint(data, ['total_tests_attempted', 'total_questions_attempted']));

        const subjects = [{ id: 1, subject_name: 'Physics' }, { id: 2, subject_name: 'Mathematics' }, { id: 3, subject_name: 'Chemistry' }];
        const dateRange = makeDateRange(filterDateRange.startDate, filterDateRange.endDate);

        return {
            questionData: {
                dailyData: makeLabelsAndDatasetsBySubjects(dateRange, questionSubjectData, subjects, ['total_unique_attempts'])
            },
            lectureData: {
                dailyData: makeLabelsAndDatasetsBySubjects(dateRange, lectureSubjectData, subjects, ['total_batch_lectures', 'total_video_watches'])
            },
            testData: {
                dailyData: makeLabelsAndDatasets(dateRange, testData, ['total_tests_attempted', 'total_questions_attempted'])
            }
        };
    };

    useEffect(() => {
        updateDateRange(period);
    }, [period]);

    useEffect(() => {
        (async () => {
            if (filterDateRange == null) {
                return;
            }
            const numLastDays = filterDateRange.endDate.diff(filterDateRange.startDate, 'days') + 1;
            const analyticsDataResponse = await securedFetchStudentAnalyticsByFilter({
                include_lecture_subject_based: true, num_last_days: numLastDays,
                include_question_subject_based: true, include_lecture_based: false
            }, navigateCallbackOptions(navigate));
            if (analyticsDataResponse === null) {
                return;
            }
            const newAnalyticsData = mapAnalyticsData(analyticsDataResponse.data[0]);
            setAnalyticsData(newAnalyticsData);


        })();
    }, [filterDateRange]);

    const DateCalculations = () => {
        const formatDateRange = (startDate, endDate) => {
            // if (!startDate && !endDate) return 'All Time';
            // if (!startDate) return `Up to ${endDate.format('DD MMMM YYYY')}`;
            if (startDate.year() === endDate.year()) {
                return startDate.month() === endDate.month()
                    ? `${startDate.format('DD')} - ${endDate.format('DD MMMM YYYY')}`
                    : `${startDate.format('DD MMMM')} - ${endDate.format('DD MMMM YYYY')}`;
            }
            return `${startDate.format('DD MMMM YYYY')} - ${endDate.format('DD MMMM YYYY')}`;
        };

        return <span>{formatDateRange(filterDateRange.startDate, filterDateRange.endDate)}</span>;
    };

    if (!analyticsData) return null;

    const renderContent = () => {
        if (!analyticsData) return <Spinner />;

        switch (selectedTab) {
            case "Topics": return <StudentAnalyticsbyTopics user={user} />;
            case "Questions":
                return (
                    <>
                        <AnalyticsBarChart user={user} period={period} valueField="total_unique_attempts" data={analyticsData.questionData.dailyData} />
                        {/* <AnalyticsTable view="questions" data={analyticsData.questionData.questioned_questions} /> */}
                    </>
                );
            case "Lectures":
                return (
                    <>
                        <AnalyticsBarChart user={user} period={period} valueField="total_batch_lectures" data={analyticsData.lectureData.dailyData} />
                        {/* <AnalyticsTable view="lectures" data={analyticsData.lectureData.lectureed_lectures} /> */}
                    </>
                );
            case "Tests":
                return (
                    <>
                        <AnalyticsLineChart user={user} period={period} valueField="accuracy" data={analyticsData.testData.dailyData} />
                        {/* <AnalyticsTable view="tests" data={analyticsData.testData.tested_tests} /> */}
                    </>
                );
            default:
                return (
                    <>
                        <KPICards view="overview" data={analyticsData} setSelectedTab={setSelectedTab} />
                        {/* {user === STUDENT_USER_TYPE && <AnalyticsRadarChart data={summaryData} studentName={studentName} />} */}
                    </>
                );
        }
    };

    return (
        <div className="relative w-full h-full flex flex-col items-center p-4 gap-4 text-[var(--textColor)] animate-fadeUp">

            {/* Page Header */}
            {/* {user === ADMIN_USER_TYPE && (
                <PageHeader pageHeaderText={"Unlock the Power of Data for Personalized Coaching with JEETA!"} />
            )}

            {user === TEACHER_USER_TYPE && (
                <PageHeader pageHeaderText={"Provide Personalized Guidance for Your Students with JEETA!"} />
            )}

            {user === STUDENT_USER_TYPE && (
                <PageHeader pageHeaderText={"Discover Strengths, Overcome Weaknesses with JEETA!"} />
            )} */}

            <div className="analytics flex">
                <div className="analytics-title">
                    {/* {user === ADMIN_USER_TYPE && (
                        <HeaderSection header={"Unlock the Power of Data for Personalized Coaching with JEETA!"} />
                        // <h2>Boost Your Productivity and Success with JEETA!</h2>
                    )}
                    {user === TEACHER_USER_TYPE && (
                        <HeaderSection header={"Provide Personalized Guidance for Your Students with JEETA!"} />
                        // <h2>Engage, Interact and Support Your Students with JEETA!</h2>
                    )}
                    {user === STUDENT_USER_TYPE && (
                        <HeaderSection header={"Discover Strengths, Overcome Weaknesses with JEETA!"} />
                        // <h2>Make Your Dream Come True with JEETA!</h2>
                    )} */}

                    {/* <div className="title">
                        <h2>
                            {user === ADMIN_USER_TYPE && "Unlock the Power of Data for Personalized Coaching with JEETA!"}
                            {user === TEACHER_USER_TYPE && "Provide Personalized Guidance for Your Students with JEETA!"}
                            {user === STUDENT_USER_TYPE && "Discover Strengths, Overcome Weaknesses with JEETA!"}
                        </h2>
                    </div> */}
                </div>
                <div className="analysis-glass">
                    <AnalyticsSidebar user={user} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                    <div className="analysis-main flex">

                        <div className="subtitle">
                            <PageHeader pageHeaderText={(
                                <>
                                    Report of {period === '1Week' ? 'Last Week' :
                                        period === '1Month' ? 'Last Month' :
                                            period === '3Months' ? 'Last 3 Months' : 'All Time'}{' '}
                                    <DateCalculations />
                                </>
                            )} />
                            {/* <h2>
                                Report of {' '}
                                {period === '1Week' ? 'Last Week' : period === '1Month' ? 'Last Month' : period === '3Months' ? 'Last 3 Months' : 'All Time'}{' '}
                                <DateCalculations />
                            </h2> */}
                        </div>

                        <div className="period-buttons">
                            <div className={`period-button ${period === '1Week' ? 'selected' : ''}`} onClick={() => setPeriod('1Week')}>1 Week</div>
                            <div className={`period-button ${period === '1Month' ? 'selected' : ''}`} onClick={() => setPeriod('1Month')}>1 Month</div>
                            <div className={`period-button ${period === '3Months' ? 'selected' : ''}`} onClick={() => setPeriod('3Months')}>3 Months</div>
                        </div>

                        {renderContent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Analytics;