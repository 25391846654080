import React from 'react'
import { useTranslation } from 'react-i18next';
import './navbar.css';

import { MdOutlineQuiz } from "react-icons/md";
import { FaCalendarAlt, FaUsers, FaRegListAlt } from 'react-icons/fa'
import { LuFileClock } from "react-icons/lu";
import { SiGoogleclassroom } from "react-icons/si";
import { PiChartLineUpBold } from "react-icons/pi";

const NavbarIconGenerator = ({ title = null }) => {

    const { t } = useTranslation();

    if (title === null) return;

    return (
        <>
            {title === "syllabus" && (
                <div className="flex item-center gap-1">
                    <FaRegListAlt className="h-5 w-5" />
                    <span className="hidden md:block">{t(title)}</span>
                </div >

            )}
            {
                title === "questions" && (
                    <div className="flex item-center gap-1">
                        <MdOutlineQuiz className="h-5 w-5" />
                        <span className="hidden md:block">{t(title)}</span>
                    </div>

                )
            }
            {
                title === "batches" && (
                    <div className="flex item-center gap-1">
                        <FaCalendarAlt className="h-5 w-5" />
                        <span className="hidden md:block">{t(title)}</span>
                    </div>
                )
            }
            {
                title === "members" && (
                    <div className="flex item-center gap-1">
                        <FaUsers className="h-5 w-5" />
                        <span className="hidden md:block">{t(title)}</span>
                    </div>
                )
            }
            {
                title === "tests" && (
                    <div className="flex item-center gap-1">
                        <LuFileClock className="h-5 w-5" />
                        <span className="hidden md:block">{t(title)}</span>
                    </div>
                )
            }
            {
                title === "liveclass" && (
                    <div className="flex item-center gap-1">
                        <SiGoogleclassroom className="h-5 w-5" />
                        <span className="hidden md:block">{t(title)}</span>
                    </div>
                )
            }
            {
                title === "analytics" && (
                    <div className="flex item-center gap-1">
                        <PiChartLineUpBold className="h-5 w-5" />
                        <span className="hidden md:block">{t(title)}</span>
                    </div>
                )
            }
        </>

    );
}

export default NavbarIconGenerator