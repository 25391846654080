import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

import './questionModal.css'

import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';
import TopicCreate from '../../Syllabus/TopicCreate';
import QuestionCreateTypeFactory from './QuestionCreateTypeFactory';
import { QUESTION_UPLOAD_TYPES } from '../../../services/QuestionService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { securedFetchGrades } from '../../../services/BatchService';

import { AiFillCloseCircle } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import TagCreate from '../../Tags/TagCreate';
import { securedFetchTagsByFilter } from '../../../services/TagService';
import LoadingPage from '../../Common/LoadingPage';
import { min } from 'moment';
import Spinner from '../../Common/Tailwind/Spinner';
import { CheckIcon, XMarkIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import Dropdown from '../../Common/Tailwind/SelectMenus/Dropdown';
import SimpleToggle from '../../Common/Tailwind/Toggles/SimpleToggle';

const QuestionCreate = ({ isOpen = true, onRequestClose = null, onAddQuestion = null, user = ADMIN_USER_TYPE,
  initialSubjects = null, initialSelectedSubject = null, initialGrades = null, initialSelectedGrade = null, initialSelectedTopics = null, initialSelectedTags = null, initialSelectedType = null, allowedTypes = null, hiddenFilters = null }) => {

  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState({});
  const [types, setTypes] = useState(allowedTypes ? allowedTypes : QUESTION_UPLOAD_TYPES);
  const [selectedType, setSelectedType] = useState(allowedTypes ? allowedTypes[0] : QUESTION_UPLOAD_TYPES[0]);
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isImageBased, setImageBased] = useState(true);
  const [isTopicCreateModalOpen, setTopicCreateModalOpen] = useState(false);
  const [isTagCreateModalOpen, setTagCreateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const makeTopicFilter = (initialFilter) => {
    const filter = {};
    if (selectedSubject && selectedSubject.id) {
      filter['subject_ids'] = [selectedSubject.id];
    } else {
      filter['subject_ids'] = subjects.map(subject => subject.id);
    }
    if (selectedGrade && selectedGrade.id) {
      filter['grades'] = [selectedGrade.id];
    } else {
      filter['grades'] = grades.map(subject => subject.id);
    }
    return filter;
  }

  const fetchAndSetSubjects = async () => {
    const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
    if (subjectsResponse === null) {
      return null;
    }
    setSubjects(subjectsResponse.data);
    return subjectsResponse.data;
  };

  const fetchAndSetTopics = async (filter = null) => {
    let topicFilter = filter;
    if (!topicFilter) {
      topicFilter = makeTopicFilter();
    }
    const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate));
    if (topicsResponse === null) {
      return null;
    }
    setTopics(topicsResponse.data);
    return topicsResponse.data;
  };

  const onTopicCreate = async (newTopicId) => {
    const topicsResponse = await fetchAndSetTopics();
    const topicToAdd = topicsResponse.find(topic => (topic.id === newTopicId));
    if (topicToAdd) {
      setSelectedTopics([...selectedTopics, topicToAdd]);
    }
  }

  const onTagCreate = async (newTagId) => {
    const tagsResponse = await securedFetchTagsByFilter(null);
    const tagsResponseData = tagsResponse.data;
    setTags(tagsResponseData);
    const tagsToAdd = tagsResponseData.find(tag => (tag.id === newTagId));
    if (tagsToAdd) {
      setSelectedTags([...selectedTags, tagsToAdd]);
    }
  }

  const fetchAndSetGrades = async () => {
    const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
    if (gradesResponse === null) {
      return null;
    }
    setGrades(gradesResponse.data.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
    return gradesResponse.data;
  };

  useEffect(() => {
    (async () => {
      if (!isOpen) {
        return;
      }
      setIsLoading(true);
      let subjectsResponse = initialSubjects, gradesResponse = initialGrades;
      if (subjectsResponse && subjectsResponse.length > 0) {
        setSubjects(initialSubjects);
      } else {
        subjectsResponse = await fetchAndSetSubjects();
        if (subjectsResponse === null) {
          return;
        }
      }
      if (allowedTypes) {
        setTypes(allowedTypes);
        setSelectedType(allowedTypes[0]);
      }
      if (gradesResponse && gradesResponse.length > 0) {
        setGrades(gradesResponse.map(grade => ({ id: grade.id, grade: grade.grade.toString() })));
      } else {
        gradesResponse = await fetchAndSetGrades();
        if (gradesResponse === null) {
          return;
        }
      }

      const gradeIdsForTopics = initialSelectedGrade && initialSelectedGrade.id ? [initialSelectedGrade.id] : ((selectedGrade && selectedGrade.id) ? [selectedGrade.id] : gradesResponse.map(grade => grade.id));
      const subjectIdsForTopics = initialSelectedSubject && initialSelectedSubject.id ? [initialSelectedSubject.id] : ((selectedSubject && selectedSubject.id) ? [selectedSubject.id] : subjectsResponse.map(subject => subject.id));

      const topicsResponse = await fetchAndSetTopics({ subject_ids: subjectIdsForTopics, grades: gradeIdsForTopics });
      if (topicsResponse === null) {
        return;
      }
      setTopics(topicsResponse);
      const tagsResponse = await securedFetchTagsByFilter(null);
      if (tagsResponse === null) {
        return;
      }
      setTags(tagsResponse.data);
      if (initialSelectedSubject && initialSelectedSubject.id) {
        setSelectedSubject(initialSelectedSubject);
      }
      if (initialSelectedGrade && initialSelectedGrade.id) {
        setSelectedGrade(initialSelectedGrade);
      }
      const initialSelectedTopicIds = initialSelectedTopics && initialSelectedTopics.length > 0 ? initialSelectedTopics.map(topic => topic.id) : selectedTopics.map(topic => topic.id);
      setSelectedTopics(topicsResponse.filter(topic => initialSelectedTopicIds.includes(topic.id)));

      const initialSelectedTagIds = initialSelectedTags && initialSelectedTags.length > 0 ? initialSelectedTags.map(tag => tag.id) : selectedTags.map(tag => tag.id);
      setSelectedTags(tagsResponse.data.filter(tag => initialSelectedTagIds.includes(tag.id)));
      if (initialSelectedType && initialSelectedType.id) {
        setSelectedType(initialSelectedType);
      }
      setIsLoading(false);
    })();
  }, [isOpen]);

  const onSelectedSubjectChange = async (selection) => {
    const subjectId = selection ? selection.value : null;
    if (subjectId) {
      const topicFilter = makeTopicFilter();
      topicFilter["subject_ids"] = [subjectId]
      const topicsResponseData = await fetchAndSetTopics(topicFilter);
      if (topicsResponseData === null) {
        return;
      }
      setSelectedSubject({ id: subjectId, subject_name: selection.label });
    }
    setSelectedTopics([]);
  };

  const onSelectedGradeChange = async (selection) => {
    const gradeId = selection ? selection.value : null;
    if (gradeId) {
      const topicFilter = makeTopicFilter();
      topicFilter["grades"] = [gradeId]
      const topicsResponse = await fetchAndSetTopics(topicFilter);
      if (topicsResponse === null) {
        return;
      }
      setSelectedGrade({ id: gradeId, grade: gradeId.toString() });
    }
    setSelectedTopics([]);
  };

  const onSelectedTopicToggle = (selected) => {
    const selectedIds = selected.map(sel => sel.value);
    const selectedTopicsFromSelect = topics.filter(topic => selectedIds.includes(topic.id));
    setSelectedTopics(selectedTopicsFromSelect);
  };

  const onSelectedTagToggle = (selected) => {
    const selectedIds = selected.map(sel => sel.value);
    const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
    setSelectedTags(selectedTagsFromSelect);
  };

  const onTypeChange = (selection) => {
    setSelectedType({ id: selection.value, name: selection.label });
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const isQuestionDataValid = () => {
    return selectedTopics.length > 0;
  }

  const checkDisabled = (key) => {
    return hiddenFilters && hiddenFilters[key] === 'disable';
  }

  const renderQuestionCreate = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (isSaving) {
      return <LoadingPage type='save' />;
    }

    return (
      <DialogPanel
        transition
        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
      >

        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
          <button
            type="button"
            onClick={onRequestClose}
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
          >
            <span className="sr-only">Close</span>
            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>

        {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
          </div> */}
        <div className="mt-3 text-center sm:mt-5">
          <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
            Create New Question
          </DialogTitle>
          <div className="mt-5 sm:mx-auto w-full">
            <form className="space-y-4 overflow-y-auto" onSubmit={onSubmit} >
              <div className="flex items-center justify-center">
                <SimpleToggle enabled={isImageBased} setEnabled={setImageBased} title="Image Based Only" />
                {/* <label htmlFor="image-based-only-check" className="block text-sm font-medium leading-6 text-gray-900 mr-2">
                    Image Based Only
                  </label>
                  <div>
                    <input
                      id="image-based-only-check"
                      type="checkbox"
                      checked={isImageBased}
                      onChange={() => setImageBased(!isImageBased)}
                      required
                    />
                  </div> */}
              </div>

              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start gap-4 md:gap-6 lg:gap-8 w-full">
                {(!hiddenFilters || (hiddenFilters.subjects !== 'hide' && hiddenFilters.topics !== 'hide')) && (
                  <Dropdown
                    list={subjects}
                    selected={selectedSubject}
                    onSelectionChange={onSelectedSubjectChange}
                    label={t('subject')}
                    nameField='subject_name'
                    valueField='id'
                    // isSearchable={true}
                    isDisabled={checkDisabled('subjects')}
                    required={true}
                    className='flex items-center justify-center gap-2'
                  />
                  // <SelectionDropdown className="questionSelectionDropdown"
                  //   name='Subject'
                  //   onSelectionChange={onSelectedSubjectChange}
                  //   itemList={subjects}
                  //   selectedItem={selectedSubject}
                  //   nameField='subject_name'
                  //   valueField='id'
                  //   isDisabled={checkDisabled('subjects')} />
                )}
                {(!hiddenFilters || (hiddenFilters.grades !== 'hide' && hiddenFilters.topics !== 'hide')) && (
                  <Dropdown
                    list={grades}
                    selected={selectedGrade}
                    onSelectionChange={onSelectedGradeChange}
                    label={t('grade')}
                    nameField='grade'
                    valueField='id'
                    // isSearchable={true}
                    isDisabled={checkDisabled('grades')}
                    required={true}
                    className='flex items-center justify-center gap-2'
                  />

                  // <SelectionDropdown className="questionSelectionDropdown"
                  //   name='Grade'
                  //   onSelectionChange={onSelectedGradeChange}
                  //   itemList={grades}
                  //   selectedItem={selectedGrade}
                  //   nameField='grade'
                  //   valueField='id'
                  //   isDisabled={checkDisabled('grades')} />

                )}
                {(!hiddenFilters || (hiddenFilters.types !== 'hide')) && (
                  <Dropdown
                    list={types}
                    selected={selectedType}
                    onSelectionChange={onTypeChange}
                    label='Type'
                    nameField='name'
                    valueField='id'
                    // isSearchable={true}
                    isDisabled={checkDisabled('types')}
                    required={true}
                    className='flex items-center justify-center gap-2'
                  />

                  // <SelectionDropdown className="questionSelectionDropdown"
                  // name="Type"
                  // itemList={types}
                  // selectedItem={selectedType}
                  // onSelectionChange={onTypeChange}
                  // nameField='name'
                  // valueField='id'
                  // isDisabled={checkDisabled('types')}/>
                )}
              </div>
              {(!hiddenFilters || (hiddenFilters.topics !== 'hide')) && (
                <div className="flex items-center justify-start gap-2 w-full">
                  <Dropdown
                    list={topics}
                    selected={selectedTopics}
                    onSelectionChange={onSelectedTopicToggle}
                    label='Topics'
                    nameField='topic_name'
                    valueField='id'
                    multiple={true}
                    isSearchable={true}
                    isDisabled={checkDisabled('topics')}
                    required={true}
                    className='flex items-center justify-start gap-2 w-full'
                  />
                  {/* <SelectionDropdownMultiple className="questionSelectionDropdownMultiple"
                      name='Topics'
                      itemList={topics}
                      selectedItems={selectedTopics}
                      onSelectionToggle={onSelectedTopicToggle}
                      nameField='topic_name'
                      valueField='id'
                      required isSearchable={true}
                      isDisabled={checkDisabled('topics')} /> */}
                  <button id="add-btn" onClick={() => setTopicCreateModalOpen(true)}>
                    <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                  </button>
                </div>)}

              {(!hiddenFilters || (hiddenFilters.tags !== 'hide')) && (
                <div className="flex items-center justify-start gap-2 w-full">
                  <Dropdown
                    list={tags}
                    selected={selectedTags}
                    onSelectionChange={onSelectedTagToggle}
                    label='Tags'
                    nameField='tag_name'
                    valueField='id'
                    multiple={true}
                    isSearchable={true}
                    isDisabled={checkDisabled('tags')}
                    required={true}
                    className='flex items-center justify-start gap-2 w-full'
                  />
                  {/* <SelectionDropdownMultiple className="questionSelectionDropdownMultiple"
                    name='Tags'
                    itemList={tags}
                    selectedItems={selectedTags}
                    onSelectionToggle={onSelectedTagToggle}
                    nameField='tag_name'
                    valueField='id'
                    isSearchable={true}
                    isDisabled={checkDisabled('tags')} /> */}
                  <button id="add-btn" onClick={() => setTagCreateModalOpen(true)}>
                    <PlusCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                  </button>
                </div>)}

              <QuestionCreateTypeFactory
                selectedType={selectedType}
                isImageBased={isImageBased}
                selectedTopics={selectedTopics}
                selectedTags={selectedTags}
                onRequestClose={onRequestClose}
                onAddQuestion={onAddQuestion}
                isQuestionDataValid={isQuestionDataValid}
                setIsSaving={setIsSaving} />
            </form>

            <TopicCreate
              isOpen={isTopicCreateModalOpen}
              onRequestClose={() => setTopicCreateModalOpen(false)}
              initialSubjects={subjects}
              initialGrades={grades}
              initialSubject={selectedSubject}
              initialGrade={selectedGrade}
              onTopicCreate={onTopicCreate}
              user={user} />
            <TagCreate
              isOpen={isTagCreateModalOpen}
              onRequestClose={() => setTagCreateModalOpen(false)}
              allTags={tags}
              onTagCreate={onTagCreate}
              user={user} />

          </div>
        </div>

      </DialogPanel>
    )
  }

  return (

    <Dialog open={isOpen} onClose={onRequestClose} className="relative z-10">

      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />


      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

          {/* <Modal overlayClassName="customModal" className="questionModal flex" isOpen={isOpen} onRequestClose={onRequestClose} > */}
          {renderQuestionCreate()}
          {/* </Modal> */}

        </div>
      </div>
    </Dialog>

  );
}



export default QuestionCreate