import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

import Modal from 'react-modal';

import './Tags.css'


import { ADMIN_USER_TYPE } from '../../services/UserService';
import { navigateCallbackOptions } from '../../services/AuthenticationService';

import { AiFillCloseCircle } from "react-icons/ai";
import { BsCloudUpload } from 'react-icons/bs'
import { securedCreateTag } from '../../services/TagService';
import SelectionDropdown from '../Common/SelectionDropdown';

import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Dropdown from '../Common/Tailwind/SelectMenus/Dropdown';
import SimpleToggle from '../Common/Tailwind/Toggles/SimpleToggle';

Modal.setAppElement('#root');

const TagCreate = ({ isOpen, onRequestClose, allTags = [], onTagCreate = null, user = ADMIN_USER_TYPE }) => {

    const [tagName, setTagName] = useState("");
    const [tagType, setTagType] = useState("");
    const [isHidden, setIsHidden] = useState(false);
    const [tagTypes, setTagTypes] = useState([]);
    const [selectedTag, setSelectedTag] = useState({});
    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (tagName.length === 0 || tagType.length === 0) {
            return;
        }
        const data = {
            tag_name: tagName,
            tag_type: tagType,
            is_hidden: isHidden
        }

        const response = await securedCreateTag(data, navigateCallbackOptions(navigate));
        if (response === null) {
            return;
        }
        if (onTagCreate) {
            await onTagCreate(response.tag_id);
        }
        onRequestClose();
    }

    const setUniqueTagTypes = () => {
        const uniqueTagTypes = [];
        allTags.map((item) => {
            var findItem = uniqueTagTypes.find((x) => x.name === item.tag_type);
            if (!findItem) uniqueTagTypes.push({ id: item.tag_type, name: item.tag_type });
        });
        setTagTypes(uniqueTagTypes);
    }

    const onTagTypeChange = (selection) => {
        setSelectedTag({ id: selection.value, name: selection.label });
        setTagType(selection.value);
    }


    useEffect(() => {
        if (isOpen) {
            setUniqueTagTypes();
        }
    }, [isOpen])

    return (

        <Dialog open={isOpen} onClose={onRequestClose} className="relative z-20">

            {/* <Modal overlayClassName="customModal" className="tagModal" isOpen={isOpen} onRequestClose={onRequestClose}> */}
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacitydata-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen overflow-y-auto">l
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">

                    <DialogPanel
                        className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6"
                    >

                        <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                            <button
                                type="button"
                                onClick={onRequestClose}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-[var(--PrimaryColor)] focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        <div className="mt-3 text-center sm:mt-5">
                            <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                Create New Tag                            </DialogTitle>

                            <div className="mt-5 flex flex-col items-center justify-center sm:mx-auto w-full">

                                {/* <h3 className="tagTitle"> Create New Tag </h3> */}

                                <form className="space-y-4">

                                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start gap-4 md:gap-6 lg:gap-8 w-full">

                                        <Dropdown
                                            list={tagTypes}
                                            selected={selectedTag}
                                            onSelectionChange={onTagTypeChange}
                                            label="Tag Type"
                                            nameField='name'
                                            valueField='id'
                                            isSearchable={true}
                                            isAddable={true}
                                            added={tagType}
                                            onSelectionAdd={setTagType}
                                            required={true}
                                            className='flex items-center justify-center gap-2'
                                        />

                                        {/* <SelectionDropdown className="tagTypeSelectionDropdown"
                                        name="Existing Types"
                                        itemList={tagTypes}
                                        selectedItem={selectedTag}
                                        onSelectionChange={onTagTypeChange} nameField='name' valueField='id' isSearchable={true} /> */}
                                        {/* <div className="textInput flex">
                                            <label className="title">Type</label>
                                            <input type="text" value={tagType} onChange={e => setTagType(e.target.value)} placeholder="Enter..." required />
                                        </div> */}

                                    </div>


                                    {/* <div className="textInput flex">
                                        <label className="title">Name</label>
                                        <input type="text" value={tagName} onChange={e => setTagName(e.target.value)} placeholder="Enter..." required />
                                    </div> */}

                                    <div className="flex items-center justify-start gap-2 w-full">
                                        <label htmlFor="tagname"
                                            className="block text-sm font-medium leading-6 text-gray-900">
                                            Tag Name
                                        </label>
                                        <div className="relative w-full flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                id="tagname"
                                                name="tagname"
                                                type="text"
                                                value={tagName}
                                                onChange={e => setTagName(e.target.value)}
                                                placeholder="Enter..."
                                                required
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                                            />
                                        </div>
                                    </div>


                                    {/* <div className="checkBoxInput flex">
                                        <label className="title">Keep Hidden</label>
                                        <input
                                            type="checkbox"
                                            className='checkBox'
                                            checked={isHidden}
                                            onChange={() => setIsHidden(prev => !prev)}
                                        />
                                    </div> */}

                                    <div className="flex items-center justify-center">
                                        <SimpleToggle enabled={isHidden} setEnabled={setIsHidden} title="Keep hidden" />
                                    </div>

                                </form>

                                <div>
                                    <button className="mt-4 flex justify-center items-center space-x-2 rounded-md bg-[var(--PrimaryColor)] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[var(--SecondaryColor)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[var(--PrimaryColor)]"
                                        id='submit' onClick={handleSubmit}>
                                        <span>SUBMIT</span>
                                        <ArrowUpTrayIcon aria-hidden="true" className="h-4 w-4 text-white" />
                                    </button>
                                </div>

                            </div>
                        </div>

                    </DialogPanel>
                </div>

            </div>
            {/* </Modal> */}

        </Dialog>

    );
}

export default TagCreate;
