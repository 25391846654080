import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";


import './QuestionCard.css'

import SelectionDropdown from '../../Common/SelectionDropdown';
import SelectionDropdownMultiple from '../../Common/SelectionDropdownMultiple';
import QuestionCreate from '../QuestionCreate/QuestionCreate';

import { QUESTION_DIFFICULTIES, QUESTION_FILTER_DROPDOWN_TYPES, QUESTION_UPLOAD_TYPES, hasQuestionCreateAuthority, hasQuestionResponses } from '../../../services/QuestionService';
import { ADMIN_USER_TYPE } from '../../../services/UserService';
import { navigateCallbackOptions } from '../../../services/AuthenticationService';
import { hasGradeFetchAuthority, securedFetchGrades } from '../../../services/BatchService';
import { securedFetchSubjects, securedFetchTopicsByFilter } from '../../../services/SyllabusService';

import { BiFilterAlt, BiMessageSquareAdd } from 'react-icons/bi'
import { securedFetchTagsByFilter } from '../../../services/TagService';
import { IoMdStar } from "react-icons/io";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { ImCancelCircle } from 'react-icons/im';

const QuestionCardListFilter = ({ syllabusFilter = null, searchFilter = null, testId = null, setSearchFilter, onSubmitFilter, allowedTypes = null, user = ADMIN_USER_TYPE, hiddenFilters = null, refreshKey = 0 }) => {

    const [isQuestionCreateModalOpen, setQuestionCreateModalOpen] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [grades, setGrades] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [types, setTypes] = useState(allowedTypes ? QUESTION_FILTER_DROPDOWN_TYPES.filter(qType => allowedTypes.map(aType => aType.id).includes(qType.id)) : QUESTION_FILTER_DROPDOWN_TYPES);
    const [selectedType, setSelectedType] = useState(allowedTypes ? QUESTION_FILTER_DROPDOWN_TYPES.find(qType => qType.id === allowedTypes[0].id) : QUESTION_FILTER_DROPDOWN_TYPES[0]);
    const [selectedDifficulties, setSelectedDifficulties] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [showUnattemptedOnly, setShowUnattemptedOnly] = useState(false);
    const [showIncorrectOnly, setShowIncorrectOnly] = useState(false);
    const [showStarredOnly, setShowStarredOnly] = useState(false);
    const [showMarkedForReviewOnly, setShowMarkedForReviewOnly] = useState(false);
    const [questionId, setQuestionId] = useState();
    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth < 780);
    const navigate = useNavigate();
    const { t } = useTranslation();


    const fetchSubjects = async () => {
        const subjectsResponse = await securedFetchSubjects(navigateCallbackOptions(navigate));
        if (subjectsResponse === null) {
            return null;
        }
        return subjectsResponse.data;
    };

    const fetchGrades = async () => {
        const gradesResponse = await securedFetchGrades(navigateCallbackOptions(navigate));
        if (gradesResponse === null) {
            return null;
        }
        return gradesResponse.data;
    };

    const onSelectedTopicToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTopicsFromSelect = topics.filter(topic => selectedIds.includes(topic.id));
        setSelectedTopics(selectedTopicsFromSelect);
    };

    const onSelectedTagToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedTagsFromSelect = tags.filter(tag => selectedIds.includes(tag.id));
        setSelectedTags(selectedTagsFromSelect);
    };

    const updateStatesFromFilter = async (filter) => {
        if (filter === null) {
            return;
        }
        if (filter.selectedSubjects.length > 0) {
            setSelectedSubjects(filter.selectedSubjects);
        }
        if (filter.selectedGrades.length > 0) {
            setSelectedGrades(filter.selectedGrades);
        }
        if (filter.topics.length > 0) {
            setTopics(filter.topics);
            if (filter.selectedTopics.length > 0) {
                setSelectedTopics(filter.selectedTopics);
            }
        }
        if (filter.selectedTags.length > 0) {
            setSelectedTags(filter.selectedTags);
        }
        if (filter.difficulties && filter.difficulties.length > 0) {
            setSelectedDifficulties(filter.selectedDifficulties);
        }
        if (filter.selectedType && filter.selectedType.id) {
            setSelectedType(filter.selectedType);
        }
        if (filter.queryString) {
            setQueryString(filter.queryString);
        }
        if (filter.showUnattemptedOnly) {
            setShowUnattemptedOnly(filter.showUnattemptedOnly);
        }
        if (filter.showStarredOnly) {
            setShowStarredOnly(filter.showStarredOnly);
        }
        if (filter.showMarkedForReviewOnly) {
            setShowMarkedForReviewOnly(filter.showMarkedForReviewOnly);
        }
        if (filter.showIncorrectOnly) {
            setShowIncorrectOnly(filter.showIncorrectOnly);
        }
        if (filter.questionId) {
            setQuestionId(filter.questionId);
        }
    }

    const updateFilterNullValues = async (lowerFilter, upperFilter) => {
        let filter = {};
        if (lowerFilter) {
            filter = { ...filter, ...lowerFilter };
        }
        if (upperFilter) {
            filter = { ...filter, ...upperFilter };
        }
        if (!filter.subjects) {
            filter["subjects"] = subjects;
        }
        if (!filter.selectedSubjects) {
            filter["selectedSubjects"] = selectedSubjects;
        }
        if (!filter.grades) {
            filter['grades'] = grades;
        }
        if (!filter.selectedGrades) {
            filter["selectedGrades"] = selectedGrades;
        }
        if (!filter.topics) {
            if (filter['selectedSubjects'].length > 0 || filter['selectedGrades'].length > 0) {
                const topicFilter = {};
                if (filter["selectedGrades"].length > 0) {
                    topicFilter["grades"] = filter["selectedGrades"].map(grade => grade.id);
                }
                if (filter["selectedSubjects"].length > 0) {
                    topicFilter["subject_ids"] = filter["selectedSubjects"].map(subject => subject.id);
                }
                const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate))
                if (topicsResponse === null) {
                    filter['topics'] = [];
                } else {
                    filter['topics'] = topicsResponse.data;
                }
            } else {
                filter["topics"] = topics;
            }
        }
        if (!filter.tags) {
            filter['tags'] = tags;
        }
        if (!filter.selectedTags) {
            filter['selectedTags'] = selectedTags;
        }
        if (!filter.selectedTopics) {
            filter["selectedTopics"] = selectedTopics;
        }
        if (!filter.difficulties) {
            filter["difficulties"] = QUESTION_DIFFICULTIES;
        }
        if (!filter.selectedDifficulties) {
            filter["selectedDifficulties"] = selectedDifficulties;
        }
        if (!filter.types) {
            filter["types"] = QUESTION_UPLOAD_TYPES;
        }
        if (!filter.selectedType) {
            filter['selectedType'] = selectedType;
        }
        if (!filter.queryString) {
            filter['queryString'] = queryString;
        }
        if (!filter.questionId >= 0) {
            filter['questionId'] = questionId;
        }
        if (!filter.showMarkedForReviewOnly) {
            filter['showMarkedForReviewOnly'] = showMarkedForReviewOnly;
        }
        if (!filter.showStarredOnly) {
            filter['showStarredOnly'] = showStarredOnly;
        }
        if (!filter.showUnattemptedOnly) {
            filter['showUnattemptedOnly'] = showUnattemptedOnly;
        }
        if (!filter.showIncorrectOnly) {
            filter['showIncorrect'] = showIncorrectOnly;
        }
        return filter;
    }

    const makeFilterFromStates = () => {
        return {
            subjects: subjects,
            selectedSubjects: selectedSubjects,
            grades: grades,
            selectedGrades: selectedGrades,
            topics: topics,
            selectedTopics: selectedTopics,
            tags: tags,
            selectedTags: selectedTags,
            difficulties: QUESTION_DIFFICULTIES,
            selectedDifficulties: selectedDifficulties,
            types: QUESTION_FILTER_DROPDOWN_TYPES,
            selectedType: selectedType,
            queryString: queryString,
            showUnattemptedOnly: showUnattemptedOnly,
            showIncorrectOnly: showIncorrectOnly,
            showStarredOnly: showStarredOnly,
            showMarkedForReviewOnly: showMarkedForReviewOnly,
            questionId: questionId,
        }
    }

    const makeTopicFilter = () => {
        const filter = {};
        if (selectedGrades.length > 0) {
            filter['grades'] = selectedGrades.map(grade => grade.id);
        } else {
            filter['grades'] = grades.map(grade => grade.id);
        }

        if (selectedSubjects.length > 0) {
            filter['subject_ids'] = selectedSubjects.map(subject => subject.id);
        } else {
            filter['subject_ids'] = subjects.map(subject => subject.id);
        }
        return filter;
    }

    const onSelectedDifficultyToggle = (selected) => {
        const selectedIds = selected.map(sel => sel.value);
        const selectedDifficultiesFromSelect = QUESTION_DIFFICULTIES.filter(difficulty => selectedIds.includes(difficulty.id));
        setSelectedDifficulties(selectedDifficultiesFromSelect);
    };

    const onSelectedTypeChange = (selection) => {
        const typeId = selection ? selection.value : null;
        if (typeId) {
            setSelectedType(QUESTION_FILTER_DROPDOWN_TYPES.find(qType => qType.id === typeId));
        }
    }

    const onSelectedSubjectChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedSubjectsFromSelect = subjects.filter(subject => selectedIds.includes(subject.id));
        setSelectedSubjects(selectedSubjectsFromSelect);
        const topicFilter = makeTopicFilter();
        topicFilter['subject_ids'] = selectedSubjectsFromSelect.map(subject => subject.id);
        const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate));
        if (topicsResponse === null) {
            return;
        }
        setTopics(topicsResponse.data);
        const updatedSelectedTopics = selectedTopics.filter(selectedTopic =>
            topicsResponse.data.some(responseTopic => responseTopic.id === selectedTopic.id));

        setSelectedTopics(updatedSelectedTopics);
    };

    const onSelectedGradeChange = async (selection) => {
        const selectedIds = selection.map(sel => sel.value);
        const selectedGradesFromSelect = grades.filter(grade => selectedIds.includes(grade.id));
        setSelectedGrades(selectedGradesFromSelect);
        const topicFilter = makeTopicFilter();
        topicFilter['grades'] = selectedGradesFromSelect.map(grade => grade.id);
        const topicsResponse = await securedFetchTopicsByFilter(topicFilter, navigateCallbackOptions(navigate));
        if (topicsResponse === null) {
            return;
        }
        setTopics(topicsResponse.data);
        const updatedSelectedTopics = selectedTopics.filter(selectedTopic =>
            topicsResponse.data.some(responseTopic => responseTopic.id === selectedTopic.id));
        setSelectedTopics(updatedSelectedTopics);
    };

    const handleSubmit = async () => {
        const externalFilter = makeFilterFromStates();
        setSearchFilter(externalFilter);
        await onSubmitFilter(false, externalFilter);
    }

    const handleShowAttemptedCheckChange = () => {
        if (!showUnattemptedOnly) {
            setShowIncorrectOnly(false);
        }
        setShowUnattemptedOnly(!showUnattemptedOnly);
    }

    const handleShowStarredCheckChange = () => {
        setShowStarredOnly(!showStarredOnly);
    }

    const handleShowMarkedForReviewCheckChange = () => {
        setShowMarkedForReviewOnly(!showMarkedForReviewOnly);
    }

    const handleShowIncorrectCheckChange = () => {
        if (!showIncorrectOnly) {
            setShowUnattemptedOnly(false);
        }
        setShowIncorrectOnly(prev => !prev);

    }

    const checkDisabled = (key) => {
        return hiddenFilters && hiddenFilters[key] === 'disable';
    }

    const updateBySyllabusFilter = () => {
        if (syllabusFilter === null) {
            return;
        }
        if (syllabusFilter.subjects && syllabusFilter.subjects.length > 0) {
            setSubjects(syllabusFilter.subjects);
            setSelectedSubjects(syllabusFilter.selectedSubjects);
        }
        if (syllabusFilter.grades && syllabusFilter.grades.length > 0) {
            setGrades(syllabusFilter.grades);
            setSelectedGrades(syllabusFilter.selectedGrades);
        }
        if (syllabusFilter.topics && syllabusFilter.topics.length > 0) {
            setTopics(syllabusFilter.topics);
            setSelectedTopics(syllabusFilter.selectedTopics);
        }

    }

    useEffect(() => {
        (async () => {
            if (!hiddenFilters || hiddenFilters.topics !== 'hide') {
                const subjectsResponse = await fetchSubjects();
                if (subjectsResponse === null) {
                    return;
                }
                setSubjects(subjectsResponse);

                const gradesResponse = await fetchGrades();
                if (gradesResponse === null) {
                    return;
                }
                setGrades(gradesResponse);
                const topicsResponse = await securedFetchTopicsByFilter({}, navigateCallbackOptions(navigate));
                if (topicsResponse === null) {
                    return;
                }
                setTopics(topicsResponse.data);

            }
            const tagFilter = user === ADMIN_USER_TYPE ? null : { is_hidden: false };
            const tagsResponse = await securedFetchTagsByFilter(tagFilter, navigateCallbackOptions(navigate));
            if (tagsResponse === null) {
                return;
            }
            setTags(tagsResponse.data);
            const currentSearchFilter = makeFilterFromStates();
            if (searchFilter === null && syllabusFilter === null) {
                const success = await onSubmitFilter(true, currentSearchFilter);
                if (!success) {
                    return;
                }
                setSearchFilter(currentSearchFilter);
            }
            else {
                const updatedSearchFilter = await updateFilterNullValues(searchFilter, syllabusFilter);
                await updateStatesFromFilter(updatedSearchFilter);
                const success = await onSubmitFilter(true, updatedSearchFilter);
                if (!success) {
                    return;
                }
                setSearchFilter(updatedSearchFilter);
            }
        })();
    }, []);


    useEffect(() => {
        updateBySyllabusFilter();
    }, [syllabusFilter]);

    useEffect(() => {
        const handleResize = () => {
            setIsNarrowScreen(window.innerWidth < 780);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    return (
        <div className="questionCardListFilter flex"
            initial={{ opacity: 0, y: 500 }}  // Initial state of the element
            animate={{ opacity: 1, y: 0 }}   // End state of the element
            transition={{ duration: 1.5, ease: "easeOut" }}  // Animation settings
        >

            {/* <div className="filterTitle flex"> */}

            {/* <h3 className="title"> Dive Deep into Learning: </h3> */}

            {/* {user === ADMIN_USER_TYPE ?
                    <span>{t('exploreAndMaster')} {isNarrowScreen ? <br /> : ""}{t('yourQuestionDatabase')}</span>
                    :
                    <span>{t('everyQuestionIs')} {isNarrowScreen ? <br /> : ""}{t('aStepTowardsExcellence')}</span>
                } */}
            {/* </div> */}

            <div className="filters">
                {(!hiddenFilters || hiddenFilters.id !== 'hide') && (<div className="questionIDInput flex">
                    <label className="title">{t('id')}</label>
                    <input type="number" value={questionId} onChange={e => setQuestionId(e.target.value)} placeholder={`${t('questionId')}...`} disabled={checkDisabled('id')} />
                </div>)}
                {(!hiddenFilters || (hiddenFilters.subjects !== 'hide' && hiddenFilters.topics !== 'hide')) && (<SelectionDropdownMultiple className='filterSelect' name={t('subjects')} onSelectionToggle={onSelectedSubjectChange} itemList={subjects} selectedItems={selectedSubjects} nameField='subject_name' valueField='id' isDisabled={checkDisabled('subjects')} />)}
                {(!hiddenFilters || (hiddenFilters.grades !== 'hide' && hiddenFilters.topics !== 'hide')) && (<SelectionDropdownMultiple className='filterSelect' name={t('grades')} onSelectionToggle={onSelectedGradeChange} itemList={grades} selectedItems={selectedGrades} nameField='grade' valueField='id' isDisabled={checkDisabled('grades')} />)}
                {(!hiddenFilters || hiddenFilters.topics !== 'hide') && (<SelectionDropdownMultiple className='filterSelect' name={t('topics')} itemList={topics} selectedItems={selectedTopics} onSelectionToggle={onSelectedTopicToggle} nameField='topic_name' valueField='id' isSearchable={true} isDisabled={checkDisabled('topics')} />)}
                {(!hiddenFilters || hiddenFilters.tags !== 'hide') && (<SelectionDropdownMultiple className='filterSelect' name={t('tags')} itemList={tags} selectedItems={selectedTags} onSelectionToggle={onSelectedTagToggle} nameField='tag_name' valueField='id' isSearchable={true} isDisabled={checkDisabled('tags')} />)}
                {(!hiddenFilters || hiddenFilters.types !== 'hide') && (<SelectionDropdown className='filterSelect' name={t('types')} itemList={types} onSelectionChange={onSelectedTypeChange} selectedItem={selectedType} nameField='name' valueField='id' isDisabled={checkDisabled('types')} />)}
                {(!hiddenFilters || hiddenFilters.difficulties !== 'hide') && (<SelectionDropdownMultiple className='filterSelect' name={t('difficulties')} itemList={QUESTION_DIFFICULTIES} selectedItems={selectedDifficulties} onSelectionToggle={onSelectedDifficultyToggle} nameField='name' valueField='id' isDisabled={checkDisabled('difficulties')} />)}
                {(!hiddenFilters || hiddenFilters.text !== 'hide') && (<div className="searchTextInput flex">
                    <label className="title">{t('text')}</label>
                    <input type="text" value={queryString} onChange={e => setQueryString(e.target.value)} placeholder="Question Text..." disabled={checkDisabled('text')} />
                </div>)}
            </div>

            {hasQuestionResponses(user) && (
                <div className="checkInput flex">
                    {!showIncorrectOnly && (<div className="unattemptedInput flex">
                        {t('unattempted')} <AiOutlineEyeInvisible className="icon" />
                        <input
                            type="checkbox"
                            checked={showUnattemptedOnly}
                            onChange={handleShowAttemptedCheckChange}
                        />
                    </div>)}

                    <div className="starInput flex">
                        {t('difficult')} <IoMdStar className="icon" />
                        <input
                            type="checkbox"
                            checked={showStarredOnly}
                            onChange={handleShowStarredCheckChange}
                        />
                    </div>

                    <div className="reviewInput flex">
                        {t('review')} <MdOutlinePlaylistAddCheck className="icon" />
                        <input
                            type="checkbox"
                            checked={showMarkedForReviewOnly}
                            onChange={handleShowMarkedForReviewCheckChange}
                        />
                    </div>

                    {!showUnattemptedOnly && (<div className="incorrectInput flex">
                        {t('incorrect')} <ImCancelCircle className="icon" />
                        <input
                            type="checkbox"
                            checked={showIncorrectOnly}
                            onChange={handleShowIncorrectCheckChange}
                        />
                    </div>)}
                </div>
            )}

            <div className="btns flex">
                <button className="btn flex" id="filter" onClick={handleSubmit}>{t('filter')} <BiFilterAlt className="icon" /> </button>
                {!hasQuestionCreateAuthority(user) || testId !== null ? (<></>) : (
                    <>
                        <span> {t('or')}</span>
                        <button className="btn flex" id="create" onClick={() => setQuestionCreateModalOpen(true)} >
                            {t('createCapitalized')} <BiMessageSquareAdd className="icon" />
                        </button>
                    </>
                )}
            </div>
            {testId === null && (
                <QuestionCreate
                    isOpen={isQuestionCreateModalOpen}
                    onRequestClose={() => setQuestionCreateModalOpen(false)}
                    user={user}
                    initialSubjects={subjects}
                    initialSelectedSubject={selectedSubjects.length > 0 ? selectedSubjects[0] : {}}
                    initialGrades={grades}
                    initialSelectedGrade={selectedGrades.length > 0 ? selectedGrades[0] : {}}
                    initialSelectedTopics={selectedTopics}
                    initialSelectedType={QUESTION_UPLOAD_TYPES.find(uType => uType.id === selectedType.id) || QUESTION_UPLOAD_TYPES[0]}
                />)}

        </div>
    );
};

export default QuestionCardListFilter;